import React, { useState, useEffect } from 'react';
import {
  Flex,
  Box,
  Button,
  Icon,
  Text,
  WorkspaceCard,
  Loader,
} from 'components';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';
import { useReduxSelector, useReduxAction } from 'hooks';
import {
  getAllWorkspaces,
  getAllWorkspacesLoading,
  getCurrentWorkspace,
} from 'ducks/workspaces/selectors';
import { actions as workspacesActions } from 'ducks/workspaces/actions';
import { TimelineMax, Power2 } from 'gsap';
import { useTranslation, Trans } from 'react-i18next';

const StyledListWrap = styled(Flex)`
  margin: 30px auto;
  max-width: 1152px;
`;

const StyledItemsWrap = styled.div`
  overflow-x: scroll;
  width: 1000px;
`;

const PaginationButton = styled.button`
  margin: 0 40px;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.neutral.background};
  border-radius: 50%;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: default;

    svg path {
      fill: ${({ theme }) => theme.neutral.lighter} !important;
    }
  }
`;

const StyledLink = styled.a`
  text-align: center;
  text-decoration: none;
`;

const StyledAddButton = styled(Flex)`
  height: 100%;
  background-color: ${({ theme }) => theme.primary.lightest};
  border: 1px dashed ${({ theme }) => theme.primary.base};
  border-radius: 8px;
`;

const WorkspacesTitle = styled(Flex)`
  opacity: 0;
`;

const WorkspaceTextBox = styled(Box)`
  font-size: 28px;
  line-height: 36px;
  font-weight: 300;
  letter-spacing: 0.4;
  color: ${({ theme }) => theme.textColors.high};
`;

export const WorkspacesList = ({ children, onClick, ...props }: any) => {
  const theme = React.useContext(ThemeContext);
  const [selectedId, setSelectedId] = useState(undefined);
  const workspaces = useReduxSelector(getAllWorkspaces);
  const loading = useReduxSelector(getAllWorkspacesLoading);
  const currentWorkspace = useReduxSelector(getCurrentWorkspace);
  // revisit CURRENT strategy
  const setCurrentWorkspace = useReduxAction(workspacesActions.CURRENT.success);
  const tl = new TimelineMax();

  const { t } = useTranslation('workspaces');

  useEffect(() => {
    setSelectedId(currentWorkspace?.id);
  }, [currentWorkspace, workspaces]);

  useEffect(() => {
    tl.fromTo(
      '.workspaces-title',
      { opacity: 0 },
      { opacity: 1, duration: 0.4, delay: 1.2 },
    )
      .fromTo(
        ['.card:first-child', '.card:nth-child(2)', '.card:nth-child(3)'],
        { opacity: 0, y: -20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.4,
          stagger: { each: 0.2 },
          ease: Power2.easeInOut,
        },
      )
      .fromTo(
        '.pagination-button',
        { opacity: 0 },
        { opacity: 1, duration: 0.4 },
      )
      .fromTo('.switch-button', { opacity: 0 }, { opacity: 1, duration: 0.4 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces]);

  const handleLeave = (id: any) => {
    // believe that at least one workspace with admin role exist
    if (currentWorkspace?.id === id) {
      // TO-DO Warning hardcoded string role admin
      const { id } = workspaces?.find((w: any) => w.role === 'Admin');
      setCurrentWorkspace({ id });
      setSelectedId(id);
    }
  };
  return (
    <StyledListWrap {...props} flexDirection="column" alignItems="center">
      {loading ? (
        <Loader visible={loading} />
      ) : (
        <>
          <WorkspacesTitle
            flexDirection="column"
            alignItems="center"
            className="workspaces-title"
          >
            <Trans i18nKey="workspaces:workplace-breadcrumb">
              <Text
                fontSize={14}
                lineHeight={'21px'}
                fontWeight={'400'}
                letterSpacing={0.4}
                color={theme.textColors.med}
              >
                You are currently in
              </Text>

              <WorkspaceTextBox mb={15} mt={4}>
                {{ workplace: currentWorkspace.name }}
              </WorkspaceTextBox>
            </Trans>
          </WorkspacesTitle>
          <Box mb={40}>
            <Flex alignItems="center">
              <PaginationButton className="pagination-button">
                <Icon
                  icon="chevron-left"
                  size="25"
                  color={theme.neutral.base}
                />
              </PaginationButton>
              <StyledItemsWrap>
                <Flex width="fit-content">
                  {workspaces.map((workspace: any, i: number) => (
                    <WorkspaceCard
                      key={workspace.id}
                      image={workspace.image} //TODO: Rethinkin this here or the typing on SDK..
                      name={workspace.name}
                      role={workspace.role}
                      id={workspace.id}
                      printersNumber={workspace.printersNumber}
                      onSelect={() => {
                        if (selectedId !== workspace.id) {
                          setSelectedId(workspace.id);
                        }
                      }}
                      isCurrent={currentWorkspace?.id === workspace.id}
                      selected={selectedId === workspace.id}
                      onLeave={handleLeave}
                      className="card"
                    />
                  ))}
                  <Box width="320px" mt={5} mb={5} className="card">
                    <StyledLink href="/">
                      <StyledAddButton
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box mb={10}>
                          <Icon icon="add" size={30} />
                        </Box>
                        <Text
                          fontSize={16}
                          lineHeight={'24px'}
                          fontWeight={'500'}
                          letterSpacing={0.4}
                          color={theme.primary.base}
                        >
                          {t('workspaces:add-workspace')}
                        </Text>
                      </StyledAddButton>
                    </StyledLink>
                  </Box>
                </Flex>
              </StyledItemsWrap>
              <PaginationButton className="pagination-button" disabled>
                <Icon
                  icon="chevron-right"
                  size="25"
                  color={theme.neutral.base}
                />
              </PaginationButton>
            </Flex>
          </Box>
          <Box mr={5}>
            <Button
              className="switch-button"
              variant={'primary'}
              size={'xl'}
              onClick={() => setCurrentWorkspace({ id: selectedId })}
              disabled={
                selectedId === undefined || selectedId === currentWorkspace?.id
              }
            >
              {t('workspaces:switch-workspace')}
            </Button>
          </Box>
        </>
      )}
    </StyledListWrap>
  );
};
