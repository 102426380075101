import { Text, Flex } from 'components';
import styled from 'styled-components';
import { device } from 'utils/mediaQueries';

export const Container = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;

  @media ${device.desktop} {
    width: 448px;
    margin-right: 11px;
  }
  @media ${device.tablet} {
    width: 50%;
  }
  @media ${device.mobile} {
    width: 100%;
    margin-right: 0px;
  }
`;

export const PreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 93%;
  border: 1px solid #c9cdd4;
  border-radius: 8px;
  padding: 16px;
  margin-top: 8px;
`;

export const PreviewInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;

  @media ${device.desktop} {
    height: 384px;
    width: 257px;
  }
  @media ${device.tablet} {
    width: calc(50% - 5px);
  }
  @media ${device.mobile} {
    width: calc(50% - 5px);
  }
`;

export const Title = styled(Text).attrs(({ theme }) => ({
  color: theme.textColors.high,
}))`
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.4px;
  margin-top: 42px;
`;

export const ContentText = styled(Text).attrs(({ theme }) => ({
  color: theme.textColors.med,
}))`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.4px;
  margin-top: 16px;

  @media ${device.desktop} {
    width: 448px;
  }
  @media ${device.tablet} {
    width: calc(100% - 5px);
  }
  @media ${device.mobile} {
    width: 100%;
  }
`;
