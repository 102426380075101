import React, { forwardRef, useCallback, useEffect } from 'react';
import { Modal, Flex, Text, Box, Button, Form } from 'components';
import {
  getIsLoadingRemove,
  getIsRemoveSuccess,
} from 'ducks/templates/selectors';
import { useReduxAction, useReduxSelector } from 'hooks';
import { ThemeContext } from 'styled-components';

import { actions as templatesActions } from 'ducks/templates/actions';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DeleteTemplateModal = forwardRef(
  ({ template, handleModalClose, isOpen }: any, ref: any) => {
    const theme = React.useContext(ThemeContext);
    const location = useLocation();
    const { t } = useTranslation(['overview', 'common']);

    const removeTemplate = useReduxAction(templatesActions.REMOVE.request);
    const clearRemoveTemplateState = useReduxAction(
      templatesActions.REMOVE.clear,
    );

    const isLoadingRemove = useReduxSelector(getIsLoadingRemove);
    const isRemoveSuccess = useReduxSelector(getIsRemoveSuccess);

    useEffect(() => {
      if (isRemoveSuccess && isOpen) {
        clearRemoveTemplateState();
        handleModalClose();
      }
    }, [clearRemoveTemplateState, handleModalClose, isOpen, isRemoveSuccess]);

    const handleSubmit = useCallback(() => {
      removeTemplate({ template, location });
    }, [removeTemplate, template, location]);

    return (
      <Modal
        data-testid="delete-template-modal"
        ref={ref}
        clickOutside={!isLoadingRemove}
        onClose={handleModalClose}
        small
        isAnimatedBackdrop={false}
      >
        <Box mb={8}>
          <Text fontSize={24} fontWeight={300} color={theme.error.base}>
            {t('overview:designs.delete-design')}
          </Text>
        </Box>
        <Text fontSize={16} fontWeight={'normal'} lineHeight={'24px'}>
          {t('overview:designs.delete-design-description')}
        </Text>
        <Flex mt={40} justifyContent={'flex-end'}>
          <Button
            data-testid="template-cancel-button"
            onClick={handleModalClose}
            variant={'basic'}
          >
            {t('common:cancel')}
          </Button>
          <Form onSubmit={handleSubmit}>
            <Button
              data-testid="template-delete-button"
              variant={'destructive'}
              isLoading={isLoadingRemove}
              type={'submit'}
            >
              {isLoadingRemove ? t('common:deleting') : t('common:delete')}
            </Button>
          </Form>
        </Flex>
      </Modal>
    );
  },
);

export default DeleteTemplateModal;
