import React, { FC, Fragment, ReactElement } from 'react';
import styled from 'styled-components';
import IconContainer, { ClipArtData, GalleryIconData, IconData } from './IconContainer';

const CategoriesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
  height: 350px;
`;

interface Props {
  setClipArt: (data: ClipArtData) => void;
  searchPhrase: string;
  data?: GalleryIconData[];
}

const IconCategoryContainer: FC<Props> = ({ setClipArt, searchPhrase, data }): ReactElement => {
  const lowerCaseSearch = searchPhrase.toLowerCase();

  const iconCategories = data?.map((item: GalleryIconData) => {
    const iconCategoryMatchesSearch = item.category?.toLowerCase().includes(lowerCaseSearch);
    const anyIconMatchesSearch = item.icons.some((icon: IconData) =>
      icon.name.toLowerCase().includes(lowerCaseSearch),
    );

    if (searchPhrase === '' || iconCategoryMatchesSearch || anyIconMatchesSearch) {
      return (
        <IconContainer
          setClipArt={setClipArt}
          icons={item.icons}
          category={item.category}
          searchPhrase={searchPhrase}
          key={`category-${item.category}`}
        />
      );
    } else {
      return <Fragment key={`category-${item.category}`}></Fragment>;
    }
  });

  return <CategoriesWrapper>{iconCategories}</CategoriesWrapper>;
};

export default IconCategoryContainer;
