import React, { forwardRef, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import {
  Modal,
  Flex,
  Text,
  Input,
  Box,
  Button,
  Slider,
  Form,
} from 'components';
import { getIsLoadingEdit, getIsEditSuccess } from 'ducks/printers/selectors';
import { actions as printersActions } from 'ducks/printers/actions';
import { useReduxAction, useReduxSelector } from 'hooks';
import { useTranslation } from 'react-i18next';

// NOTE: this is removed by client request
// Temporary Comment, To be removed on the future on final confirmation
/*
    const Metric = styled(Text).attrs(({ theme }) => ({
      color: theme.primary.base,
    }))``;

    const ChangeBondButton = styled(Box)`
      cursor: pointer;
      font-size: 0;
      border: 0 none;
      background: transparent;
      outline: none;
    `;

    const LinkIcon: any = styled(Icon).attrs(({ theme, bonded }: any) => ({
      icon: 'link-vertical',
      color: bonded ? theme.primary.base : theme.neutral.base,
      size: 20,
    }))`
      padding-left: 23px;
      padding-right: 23px;
      padding-top: 23px;
    `;

    const LabelMarginWrap = styled(Box)`
      width: 120px;
    `;

    const MarginInput = ({ onMetricChange, metric, ...props }: any) => {
      return (
        <Box width={'120px'}>
          <Input
            type={'number'}
            onEndIconClick={onMetricChange}
            {...props}
            iconEnd={<Metric>{metric}</Metric>}
          />
        </Box>
      );
    };
*/

const Section = styled(Box)`
  margin-bottom: ${({ theme }) => theme.space[11]}px;
`;

const SectionTitle = styled(Text).attrs(({ theme }) => ({
  fontSize: '14x',
  lineHeight: '21px',
  fontWeight: '400',
  letterSpacing: '0.4px',
  color: theme.textColors.high,
  marginBottom: '5px',
}))``;

const PreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  width: 100%;
  border: 1px solid #c9cdd4;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const ExampleText = styled('h1')<{ darkness: number }>`
  opacity: ${(props) => props.darkness};
`;

const Footer = ({ handleSubmit }: any) => {
  const isLoading = useReduxSelector(getIsLoadingEdit);
  const { t } = useTranslation(['overview', 'common']);

  return (
    <Flex justifyContent={'flex-end'}>
      <Form onSubmit={handleSubmit}>
        <Button
          data-testid="done-button"
          disabled={isLoading}
          type={'submit'}
          variant={'primary'}
        >
          {isLoading ? t('common:saving') : t('common:done')}
        </Button>
      </Form>
    </Flex>
  );
};

const EditPrinterModal = forwardRef(
  ({ printerForEdit, testPrint }: any, ref: any) => {
    const [top, setTop] = useState<number | string>(0);
    const [bottom, setBottom] = useState<number | string>(0);
    const [right, setRight] = useState<number | string>(0);
    const [left, setLeft] = useState<number | string>(0);
    const { t } = useTranslation(['overview', 'common']);

    // const [bondX, setBondX] = useState(true); // NOTE: this is removed by client request
    // const [bondY, setBondY] = useState(true); // NOTE: this is removed by client request

    const [metric, setMetric] = useState('');
    const [printerName, setPrinterName] = useState('');
    const [darknessLevel, setDarknessLevel] = useState<number>(0);

    const isEditSuccess = useReduxSelector(getIsEditSuccess);

    const {
      name,
      // labelSize, // NOTE: this is removed by client request
      darkness = 0,
      margins = {},
      metric: printerMetric,
    } = printerForEdit;

    useEffect(() => {
      if (isEditSuccess && ref) {
        ref.current.close();
      }
    }, [isEditSuccess, ref]);

    const editPrinter = useReduxAction(printersActions.UPDATE.request);

    useEffect(() => {
      setPrinterName(name);
      setMetric(printerMetric);
      setTop(margins.top);
      setBottom(margins.bottom);
      setLeft(margins.left);
      setRight(margins.right);
      setDarknessLevel(darkness + 7);
    }, [
      darkness,
      margins.bottom,
      margins.left,
      margins.right,
      margins.top,
      name,
      printerMetric,
    ]);

    // NOTE: this is removed by client request
    // Temporary Comment, To be removed on the future on final confirmation
    /*
  const HandleXChange = useCallback(
    (v: any, origin: string) => {
      let value;
      v ? (value = Number(v)) : (value = '');
      if (bondX) {
        setLeft(value);
        setRight(value);
      } else {
        origin === 'right' ? setRight(value) : setLeft(value);
      }
    },
    [bondX],
  );

  const HandleYChange = useCallback(
    (v: string, origin: string) => {
      let value;
      v ? (value = Number(v)) : (value = '');
      if (bondY) {
        setTop(value);
        setBottom(value);
      } else {
        origin === 'top' ? setTop(value) : setBottom(value);
      }
    },
    [bondY],
  );

  const HandleBondYChange = useCallback(() => {
    setBondY(!bondY);
    if (bottom !== top) {
      setBottom(top);
    }
  }, [bondY, bottom, top]);

  const HandleBondXChange = useCallback(() => {
    setBondX(!bondX);
    if (right !== left) {
      setRight(left);
    }
  }, [bondX, left, right]);

  const handleMetricChange = useCallback(
    e => {
      setMetric(metric === 'in' ? 'mm' : 'in');
    },
    [metric],
  );
*/
    const handleSubmit = useCallback(() => {
      editPrinter({
        ...printerForEdit,
        name: printerName,
        margins: { top, bottom, left, right },
        darkness: darknessLevel - 7,
        metric,
      });
    }, [
      bottom,
      darknessLevel,
      editPrinter,
      left,
      metric,
      printerForEdit,
      printerName,
      right,
      top,
    ]);

    return (
      <Modal
        ref={ref}
        footer={<Footer handleSubmit={handleSubmit} />}
        title={t('overview:printer.settings')}
      >
        <Flex
          width={486}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Flex width={'100%'} flexDirection={'column'}>
            <Section>
              <SectionTitle>{t('overview:printer.name')}</SectionTitle>

              <Input
                data-testid="input-printer-name"
                value={printerName}
                onChange={({ target: { value: v } }: any) => setPrinterName(v)}
              />
            </Section>

            <SectionTitle>{t('overview:printer.darkness-level')}</SectionTitle>
            {/* <SectionSubtitle>{'Darkness Level'}</SectionSubtitle> */}

            <Flex alignItems={'flex-end'}>
              <Slider
                value={darknessLevel}
                min={0}
                max={14}
                onChange={(v) => setDarknessLevel(v)}
              />
            </Flex>
            <Flex
              width={486}
              mt={5}
              flexDirection={'column'}
              justifyContent={'space-between'}
            >
              <SectionTitle>{t('common:preview')}</SectionTitle>
              <PreviewWrapper>
                <ExampleText
                  darkness={
                    typeof darknessLevel !== 'number'
                      ? (parseInt(darknessLevel) + 1) * (1 / 16)
                      : (darknessLevel + 1) * (1 / 16)
                  }
                >
                  {t('overview:printer.a4e')}
                </ExampleText>
              </PreviewWrapper>

              <Button
                onClick={testPrint}
                style={{ flex: 1, justifyContent: 'center' }}
              >
                {t('overview:printer.print-test-label')}
              </Button>
            </Flex>
          </Flex>
          {/* 

        // NOTE: this is removed by client request
        // Temporary Comment, To be removed on the future on final confirmation 
        
        <Flex width={'45%'} flexDirection={'column'}>
          <Section>
            <SectionTitle>{'Label Margin'}</SectionTitle>
            <SectionSubtitle>
              {
                'Adjust the width of the label margin. This will apply to both ends of the label. You can select either inches or millimeters.'
              }
            </SectionSubtitle>
          </Section>
          <LabelMarginWrap />
          <Section>
            <Flex flexDirection={'row'} alignItems={'center'} mb={8}>
              <MarginInput
                data-testid="input-margin-top"
                value={top}
                onMetricChange={handleMetricChange}
                metric={metric}
                onChange={({ target: { value: v } }: any) =>
                  HandleYChange(v, 'top')
                }
                label={'Top'}
              />
              <ChangeBondButton as={'button'} onClick={HandleBondYChange}>
                <LinkIcon bonded={bondY} />
              </ChangeBondButton>
              <MarginInput
                data-testid="input-margin-bottom"
                value={bottom}
                onMetricChange={handleMetricChange}
                metric={metric}
                onChange={({ target: { value: v } }: any) =>
                  HandleYChange(v, 'bottom')
                }
                label={'Bottom'}
              />
            </Flex>
            <Flex alignItems={'center'} flexDirection={'row'}>
              <MarginInput
                data-testid="input-margin-left"
                value={left}
                onMetricChange={handleMetricChange}
                metric={metric}
                onChange={({ target: { value: v } }: any) =>
                  HandleXChange(v, 'left')
                }
                label={'Left'}
              />
              <ChangeBondButton as={'button'} onClick={HandleBondXChange}>
                <LinkIcon bonded={bondX} />
              </ChangeBondButton>
              <MarginInput
                data-testid="input-margin-right"
                value={right}
                onMetricChange={handleMetricChange}
                metric={metric}
                onChange={({ target: { value: v } }: any) =>
                  HandleXChange(v, 'right')
                }
                label={'Right'}
              />
            </Flex>
          </Section>
          <Preview
            labelWidth={labelSize ? labelSize.width : 0}
            labelHeight={labelSize ? labelSize.height : 0}
            top={top ? Number(top) : 0}
            bottom={bottom ? Number(bottom) : 0}
            right={right ? Number(right) : 0}
            left={left ? Number(left) : 0}
            metric={metric}
          />
        </Flex>*/}
        </Flex>
      </Modal>
    );
  },
);

export default EditPrinterModal;
