import React, { useState } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';

import { Box, Text, Icon, Flex, Button } from 'components';
import { notificationDuration, UnitOfTime } from 'utils/notifications';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

type Notification = {
  notification: any;
  isRead?: boolean;
  onDismiss?: () => void;
  onOpen?: () => void;
};

const NotificationWrap: any = styled(Box)`
  position: relative;
  border-radius: 8px;
  max-width: 800px;
  width: 100%;
  margin-bottom: ${({ theme }) => `${theme.space[11]}px`};
  box-shadow: ${({ theme }) => theme.elevations.one};
  background-color: ${({ theme }: any) => theme.white};
`;

const MainSection: any = styled(Flex).attrs({
  pt: 10,
  pr: 11,
  pl: 11,
  pb: 10,
})`
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const BottomSection: any = styled(Flex).attrs({
  pt: 8,
  pr: 12,
  pb: 10,
  pl: 11,
})`
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.neutral.lightest};
`;

const NewIndicator: any = styled(Box).attrs({
  mr: 5,
})`
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledImage = styled.img`
  height: 36px;
`;

const ExpandIcon: any = styled(Icon).attrs(({ theme }: any) => ({
  icon: 'chevron-down',
  color: theme.neutral.base,
  size: 16,
}))`
  margin-left: auto;
`;

const ContractIcon: any = styled(Icon).attrs(({ theme }: any) => ({
  icon: 'chevron-down',
  color: theme.neutral.base,
  size: 16,
}))`
  margin-left: auto;
  transform: rotate(180deg);
`;

const TextHolder = styled(Box).attrs({ ml: 12 })`
  width: 80%;
`;

const NamespaceText = styled(Text)`
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NotificationExpandable = ({
  notification,
  onDismiss,
  onOpen,
}: Notification) => {
  const { t } = useTranslation('components');

  const [isExpanded, setIsExpanded] = useState(false);
  const theme = React.useContext(ThemeContext);

  const getNotificationTime = (notificationTime: any) => {
    const timeInfo = notificationDuration(notificationTime);

    switch (timeInfo.unit) {
      case UnitOfTime.Seconds:
        return i18n.t('components:time-units.seconds', { time: timeInfo.time });
      case UnitOfTime.Hours:
        return i18n.t('components:time-units.hours', { time: timeInfo.time });
      case UnitOfTime.Days:
        return i18n.t('components:time-units.days', { time: timeInfo.time });
      default:
        return i18n.t('components:time-units.minutes', { time: timeInfo.time });
    }
  };

  return (
    <NotificationWrap data-testid={'expandable-notification'}>
      <MainSection
        onClick={() => {
          setIsExpanded(!isExpanded);
          onOpen && onOpen();
        }}
      >
        {!notification.isRead && (
          <NewIndicator data-testid={'expandable-notification-read-indicator'}>
            <Icon icon="circle" size={'12'} />
          </NewIndicator>
        )}
        <StyledImage src={notification.img} />
        <TextHolder>
          <Text
            data-testid="expandable-notification-title"
            fontSize={24}
            lineHeight={'32px'}
            fontWeight={'300'}
            letterSpacing={0.4}
            color={theme.textColors.high}
          >
            {notification.title}
          </Text>
          {/* TODO: */}
          <NamespaceText
            fontSize={14}
            lineHeight={'21px'}
            fontWeight={'normal'}
            letterSpacing={0.4}
            color={theme.neutral.base}
          >
            {`${getNotificationTime(notification.time)} | ${
              notification.footerText ?? ''
            }`}
          </NamespaceText>
        </TextHolder>
        {isExpanded ? (
          <ContractIcon data-testid="expandable-notification-contract-button" />
        ) : (
          <ExpandIcon data-testid="expandable-notification-expand-button" />
        )}
      </MainSection>
      {isExpanded && (
        <BottomSection>
          <Text
            fontSize={14}
            lineHeight={'21px'}
            fontWeight={'normal'}
            letterSpacing={0.4}
            color={theme.textColors.high}
          >
            {notification.detailedMessage || ''}
          </Text>

          <Flex mt={10} flexDirection={'row'} justifyContent="space-between">
            <Button
              data-testid="expandable-notification-dismiss-button"
              variant={'text-primary'}
              onClick={onDismiss}
            >
              {t('components:notifications.dismiss')}
            </Button>
          </Flex>
        </BottomSection>
      )}
    </NotificationWrap>
  );
};

export default NotificationExpandable;
