import React, { forwardRef, useCallback, useEffect } from 'react';
import { Modal, Flex, Text, Box, Button, Form } from 'components';
import { ThemeContext } from 'styled-components';
import {
  getIsLoadingRemove,
  getIsRemoveSuccess,
} from 'ducks/dataSources/selectors';
import { useReduxAction, useReduxSelector } from 'hooks';
import { actions as dataSourcesActions } from 'ducks/dataSources/actions';
import { useTranslation } from 'react-i18next';

const DeleteFileModal = forwardRef(({ file }: any, ref: any) => {
  const { t } = useTranslation(['dataSources', 'common']);

  const theme = React.useContext(ThemeContext);
  const removeFile = useReduxAction(dataSourcesActions.REMOVE.request);
  const isLoadingRemove = useReduxSelector(getIsLoadingRemove);
  const isRemoveSuccess = useReduxSelector(getIsRemoveSuccess);

  useEffect(() => {
    if (isRemoveSuccess && ref) {
      ref.current.close();
    }
  }, [isRemoveSuccess, ref]);

  const handleSubmit = useCallback(() => {
    removeFile(file);
  }, [removeFile, file]);

  return (
    <Modal
      data-testid="delete-local-file-modal"
      clickOutside={!isLoadingRemove}
      small
      ref={ref}
    >
      <Box mb={8}>
        <Text fontSize={24} fontWeight={300} color={theme.error.base}>
          {file?.provider?.startsWith('Local')
            ? t('dataSources:remove-local-file')
            : t('dataSources:remove-linked-file')}
        </Text>
      </Box>
      <Text
        fontSize={16}
        fontWeight={'normal'}
        lineHeight={'24px'}
        color={theme.neutral.darkest}
      >
        {file?.provider?.startsWith('Local')
          ? t('dataSources:remove-local-file-prompt')
          : t('dataSources:remove-linked-file-prompt')}
      </Text>
      <Flex mt={20} justifyContent={'flex-end'}>
        <Button
          data-testid="template-cancel-button"
          onClick={() => ref.current.close()}
          variant={'basic'}
        >
          {t('common:cancel')}
        </Button>
        <Form onSubmit={handleSubmit}>
          <Button
            data-testid="file-remove-button"
            variant={'destructive'}
            isLoading={isLoadingRemove}
            type={'submit'}
          >
            {isLoadingRemove
              ? t('common:removing')
              : file?.provider?.startsWith('Local')
              ? t('common:remove')
              : t('dataSources:remove-linked-file')}
          </Button>
        </Form>
      </Flex>
    </Modal>
  );
});

export default DeleteFileModal;
