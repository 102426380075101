import React, { Suspense, useEffect } from 'react';
import { Icon } from 'components';
import { useReduxAction, useReduxSelector } from 'hooks';
import { actions as authActions } from 'ducks/auth/actions';
import { getIsLoading } from 'ducks/auth/selectors';
import { ThemeContext } from 'styled-components';
import { PageWrap, Flex, Box } from 'components';
import { Trans, useTranslation } from 'react-i18next';
import { getAssetPath } from 'utils/files';
import {
  Wrapper,
  StyledButton,
  InfoWrapper,
  DeleteAccountInfo,
  StyledLogoImage,
  StyledLoginImage,
} from './index.styles';
import LoadingScreen from 'components/app/LoadingScreen';
import { getDeleteAccountStarted } from 'utils/deleteAccountStarted';

const LoginPage = () => {
  const loginRequest = useReduxAction(authActions.LOGIN.request); // TO-DO memoize this or research
  const isLoading = useReduxSelector(getIsLoading);
  const { t } = useTranslation(['common', 'login', 'menu']);
  const theme = React.useContext(ThemeContext);
  const deleteAccountStarted = getDeleteAccountStarted();

  useEffect(() => {
    if (
      !deleteAccountStarted &&
      !window.location.href.includes('://localhost') &&
      !window.location.href.includes('://127.0.0.1')
    )
      loginRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <PageWrap>
        <Flex
          width="100%"
          height="100vh"
          alignItems="center"
          alignContent="center"
          flexDirection="row"
          justifyContent="center"
        >
          <Box m="auto">
            {isLoading && !deleteAccountStarted ? (
              <LoadingScreen />
            ) : (
              <>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box>
                    <Flex
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {deleteAccountStarted ? (
                        <>
                          <StyledLogoImage
                            data-testid="login-logo-zebra"
                            src={getAssetPath('logo-zebra.svg')}
                            alt={t('menu:zebra-logo')}
                          />
                          <StyledLoginImage
                            data-testid="login-image-with-logo"
                            src={getAssetPath('login-image-with-logo.png')}
                            alt={t('login:image-with-logo')}
                          />
                        </>
                      ) : (
                        <Icon
                          data-testid="login-image"
                          icon="login"
                          size="279"
                          color="#5D7987"
                        />
                      )}
                      {deleteAccountStarted && (
                        <InfoWrapper>
                          <Trans i18nKey="login:delete-account-info">
                            <DeleteAccountInfo color={theme.error.base}>
                              Important:
                            </DeleteAccountInfo>{' '}
                            <DeleteAccountInfo>
                              For security purposes, please login one last time
                              to finalize the deletion of your account. Failure
                              to do so will result in your account still being
                              active.
                            </DeleteAccountInfo>
                          </Trans>
                        </InfoWrapper>
                      )}
                      <Box>
                        <StyledButton
                          data-testid="login-nav"
                          onClick={() => loginRequest({})}
                        >
                          {deleteAccountStarted
                            ? t('login:login-button')
                            : t('login:sign-in-register')}
                        </StyledButton>
                      </Box>
                    </Flex>
                  </Box>
                </Flex>
              </>
            )}
          </Box>
        </Flex>
      </PageWrap>
    </Wrapper>
  );
};

export const Login = () => (
  <Suspense fallback="">
    <LoginPage />
  </Suspense>
);
