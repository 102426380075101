import React, { useState } from 'react';
import { Flex, Button } from 'components';
import styled from 'styled-components';
import { useReduxAction } from 'hooks';
import { actions as authActions } from 'ducks/auth/actions';
import { useTranslation } from 'react-i18next';
import DeleteAccountModal from 'pages/overview/DeleteAccountModal';
import { device } from 'utils/mediaQueries';


const ButtonsContainer = styled(Flex).attrs(({ theme }) => ({
  py: 11,
  mr: 11,
}))`
  position: fixed;
  bottom: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.primary.background};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  border-top: 1px solid ${({ theme }) => theme.neutral.lighter};

  @media ${device.desktopMiddle} {
    width: 70%;
  }

  @media ${device.desktop} {
    width: 65%;
  }

  @media ${device.tablet} {
    width: calc(100% - 35px);
  }

  @media ${device.mobileToTablet} {
    width: calc(100% - 35px);
  }

  @media ${device.mobile} {
    width: calc(100% - 22px);
    left: 18px;
  }
`;

export const ActionButtonsFooter = () => {
  const { t } = useTranslation('settings');

  const logoutRequest = useReduxAction(authActions.LOGOUT);

  const [open, setOpen] = useState<boolean>(false);

  const handleOpen: () => void = () => {
    setOpen(true);
  };

  const handleClose: () => void = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logoutRequest();
  };

  return (
    <>
      <ButtonsContainer>
        <Button
          data-testid="delete-account"
          variant="text-destructive"
          onClick={handleOpen}
        >
          {t('settings:delete-account')}
        </Button>
        <Button
          data-testid="log-out"
          variant="primary"
          onClick={handleLogout}
        >
          {t('settings:log-out')}
        </Button>
      </ButtonsContainer>

      <DeleteAccountModal open={open} handleModalClose={handleClose} />
    </>
  );
};
