import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';

import { Flex, Box, Text } from 'components/index';
import { useReduxSelector } from 'hooks';
import { getCurrentWorkspace } from 'ducks/workspaces/selectors';

interface WorkgroupProps {
  href: string;
  imageUrl: string;
  onClick?: (event: SyntheticEvent) => void;
  children: any;
}

const StyledWorkgroup = styled.div<WorkgroupProps>`
  padding: ${({ theme }) => theme.space[8]}px;
  background-color: ${({ theme }) => theme.neutral.base};
  position: relative;
`;

const StyledImage = styled.img`
  height: 40px;
  width: 40px;
  border-radius: ${({ theme }) => theme.space[4]}px;
`;

const Workgroup = ({ children, href, imageUrl, onClick, ...props }: any) => {
  const currentWorkspace = useReduxSelector(getCurrentWorkspace);
  const theme = React.useContext(ThemeContext);
  return (
    <StyledWorkgroup {...props}>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Box mr={3}>
          <StyledImage src={currentWorkspace?.avatar} alt="" />
        </Box>
        <Box mr={10} mt={4} mb={2}>
          <Text
            fontSize={14}
            lineHeight={'24px'}
            fontWeight={'normal'}
            letterSpacing={0.4}
            color={theme.white}
          >
            {currentWorkspace?.name}
          </Text>
        </Box>
        <Box>
          <Flex>{children}</Flex>
        </Box>
      </Flex>
    </StyledWorkgroup>
  );
};

export default Workgroup;
