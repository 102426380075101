import React, { useState } from 'react';
import styled from 'styled-components';

const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
  margin-bottom: 0px;
  width: 32px;
`;
const TooltipTip = styled.div`
  position: fixed;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: white !important;
  background: black;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
  z-index: 100;
  margin-bottom: 0px;
  opacity: 0.8;

  &:before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 6px;
    margin-left: calc(6px * -1);
  }
`;

const TooltipTipRight = styled(TooltipTip)<any>`
  left: calc(100% + 12px);
  margin-top: -12px;
  // top: 50%; //calc top here ?
  ${(props) => props.mt && 'margin-top: ' + props.mt};

  transform: translateX(0) translateY(-50%);

  &:before {
    left: calc(6px * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: black;
  }
`;
const TooltipTipTop = styled(TooltipTip)`
  top: calc(40px * -1);

  &:before {
    top: 100%;
    border-top-color: black;
  }
`;
const TooltipTipLeft = styled(TooltipTip)`
  left: auto;
  right: calc(100% + 30px);
  top: 50%;
  transform: translateX(0) translateY(-50%);

  &:before {
    left: auto;
    right: calc(6px * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: black;
  }
`;
const TooltipTipBottom = styled(TooltipTip)`
  bottom: calc(40px * -1);

  &:before {
    bottom: 100%;
    border-bottom-color: black;
  }
`;

const Tooltip = ({ enabled, direction, mt, ...props }: any) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    if (enabled) {
      timeout = setTimeout(() => {
        setActive(true);
      }, props.delay || 300);
    }
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  const TooltipDirectonPointer =
    direction === 'left' ? (
      <TooltipTipLeft>{props.content}</TooltipTipLeft>
    ) : direction === 'bottom' ? (
      <TooltipTipBottom>{props.content}</TooltipTipBottom>
    ) : direction === 'right' ? (
      <TooltipTipRight mt={mt}>{props.content}</TooltipTipRight>
    ) : direction === 'top' ? (
      <TooltipTipTop>{props.content}</TooltipTipTop>
    ) : (
      <TooltipTipTop>{props.content}</TooltipTipTop>
    );

  return (
    <TooltipWrapper
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {props.children}
      {active && TooltipDirectonPointer}
    </TooltipWrapper>
  );
};

export default Tooltip;
