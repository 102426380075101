import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  COMMON_DESIGNS_PATH,
  HOME_PAGE_PATH,
  MY_DATA_SOURCES_PATH,
  MY_DESIGNS_PATH,
} from 'utils/config';
import Video from './video';
import Announcements from '../../lib/announcement/Announcements';
import { Header, HeaderWrap } from './Header.styles';

const PageHeader = ({
  children,
  variant,
  noBackground = false,
  isVideo = false,
  path,
  pathname,
  collapsed,
  ...props
}: any) => {
  const theme = useContext(ThemeContext);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const videoPath = React.useMemo(() => {
    if (pathname === HOME_PAGE_PATH) return `themes/${theme.name}/1.mp4`;
    if (pathname === COMMON_DESIGNS_PATH) return `themes/${theme.name}/2.mp4`;
    if (pathname === MY_DESIGNS_PATH) return `themes/${theme.name}/2.mp4`;
    if (pathname === MY_DATA_SOURCES_PATH) return `themes/${theme.name}/3.mp4`;
    return null;
  }, [pathname, theme]);

  React.useEffect(() => {
    if (videoRef.current) {
      if (videoPath) {
        videoRef.current.pause();
        videoRef.current.src = videoPath;
        videoRef.current.load();
      }
    }
  }, [videoPath]);

  return (
    <HeaderWrap {...props}>
      <Announcements show={pathname === HOME_PAGE_PATH} />

      {(pathname === HOME_PAGE_PATH ||
        pathname === COMMON_DESIGNS_PATH ||
        pathname === MY_DESIGNS_PATH ||
        pathname === MY_DATA_SOURCES_PATH) && (
        <Header noBackground={false} isVideo={true}>
          <Video path={videoPath ?? ''} ref={videoRef} />
        </Header>
      )}
      {pathname === '/help' && (
        <Header
          variant={'small'}
          noBackground={false}
          isVideo={false}
          path={'/header-small.png'}
        />
      )}
    </HeaderWrap>
  );
};

export default PageHeader;
