import styled from 'styled-components';
import { device } from '../../../../utils/mediaQueries';
import { Text } from '../../../../components';
import { MAX_FILETABLE_NAME_LENGTH } from './FilesTable';

export const TableWrap = styled.table`
  @media ${device.desktop} {
    margin-left: 0px;
    width: calc(100% + 24px);
    margin-left: -12px;
  }

  @media ${device.mobile} {
    width: 100%;
    max-width: 100%;
  }

  border-collapse: collapse;
  td {
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;
  }
  th {
    text-align: left;

    @media ${device.desktop} {
      &:nth-child(1) {
        width: 45px;
      }

      &:nth-child(2) {
        width: 420px;
      }
    }
  }
`;

export const Head = styled.thead``;

export const Body = styled.tbody``;

export const Row = styled('tr')<{ hover: boolean; selected: boolean }>`
  border-bottom: 1px solid ${({ theme }) => theme.neutral.lightest};
  background: ${(props) =>
    props.hover && props.selected ? props.theme.primary.light : ''};
  td:first-of-type {
    width: 1%;
    padding-right: 16px;
  }
  &:hover {
    transition: color 0.3s, background-color 0.3s;
    background: ${(props) => (props.hover ? props.theme.primary.lightest : '')};
  }
`;

export const ColumnName = styled(Text).attrs(({ theme }) => ({
  fontSize: 12,
  lineHeight: '16px',
  letterSpacing: 2,
  textTransform: 'uppercase',
  color: theme.textColors.med,
  pb: 6,
}))``;

export const CellText = styled(Text).attrs(({ theme }) => ({
  fontSize: 14,
  lineHeight: '21px',
  letterSpacing: 0.4,
  color: theme.textColors.med,
}))``;

export const Name = styled(Text).attrs(({ theme }) => ({
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: 0.4,
  color: theme.textColors.high,
}))``;

export const NameWithTooltip = styled(Name)<{ tooltip: string }>`
  &:hover::before {
    content: ${(props) =>
      props.tooltip.length > MAX_FILETABLE_NAME_LENGTH
        ? `"${props.tooltip}"`
        : undefined};
    background-color: ${(props) => props.theme.neutral.background};
    max-width: 100%;
    overflow-wrap: break-word;
    z-index: 1;
    position: absolute;
  }
`;
