import styled from 'styled-components';

import { Flex } from 'components';
import { Text } from 'v2/components/common';
import { device } from 'utils/mediaQueries';

export const PageWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  margin: 0;
`;

export const FormsContainer = styled(Flex)`
  @media ${device.mobile} {
    flex-direction: column;
    justify-content: flex-start;
  }

  flex-direction: row;
  justify-content: space-between;
  max-width: 780px;
`;

export const UploadInput = styled.input.attrs({
  type: 'file',
  hidden: true,
})``;

export const Subtitle = styled(Text).attrs(({ theme }) => ({
  variant: 'label',
}))`
  margin-left: ${({ theme }) => theme.px(8)};
  color: ${({ theme }) => theme.neutral.base};
`;
