import React from 'react';
import styled from 'styled-components';
import { Flex, MenuItem, Button } from 'components';
import { Switch, Route } from 'react-router';
import Details from './Details';
import Theme from './Theme';
import Members from './Members';
import { useTranslation } from 'react-i18next';

const SideNav = styled(Flex).attrs({ flexDirection: 'column', px: 40, py: 32 })`
  width: 320px;
  border-right: 1px solid ${({ theme }) => theme.neutral.lightest};
`;

const DeleteWorkspaceButton = styled(Button)`
  margin-top: auto;
`;

const EditContainer = styled(Flex)`
  flex: 1;
`;

const EditContent = styled(Flex).attrs({
  py: 32,
  pl: 40,
  pr: 192,
  flexDirection: 'column',
})`
  flex: 1;
`;

const WorkspaceEdit = ({ match }) => {
  const { t } = useTranslation('workspaces');

  return (
    <EditContainer>
      <SideNav>
        <MenuItem href={`/workspaces/${match.params.id}/edit/details`}>
          {t('workspaces:details')}
        </MenuItem>
        <MenuItem href={`/workspaces/${match.params.id}/edit/theme`}>
          {t('workspaces:theme')}
        </MenuItem>
        <MenuItem href={`/workspaces/${match.params.id}/edit/members`}>
          {t('workspaces:members')}
        </MenuItem>
        <DeleteWorkspaceButton variant={'text-destructive'}>
          {t('workspaces:delete-workspace')}
        </DeleteWorkspaceButton>
      </SideNav>
      <EditContent>
        <Switch>
          <Route path="/workspaces/:id/edit/details" component={Details} />
          <Route path="/workspaces/:id/edit/theme" component={Theme} />
          <Route path="/workspaces/:id/edit/members" component={Members} />
        </Switch>
      </EditContent>
    </EditContainer>
  );
};

export default WorkspaceEdit;
