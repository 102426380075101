import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Flex, TabControl } from 'components';
import { useTranslation } from 'react-i18next';

const HeaderWrap: any = styled(Flex).attrs({
  flexDirection: 'column',
})`
  position: sticky;
  top: -1px;
  z-index: 9; // 1 beneath SideNav too show shadow
  margin-top: -40px;
  background-color: ${({ theme }) => theme.primary.background};
  ${({ isSticky, theme }: any) =>
    isSticky &&
    `
    border-bottom: 1px solid ${theme.neutral.lighter};
  `}
`;

const InnerWrap: any = styled(Flex).attrs({ flexDirection: 'column' })`
  position: relative;
  padding: 30px;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
`;

export const Header = () => {
  const { t } = useTranslation('help');

  const [isSticky, setIsSticky] = useState(false);
  const InnerWrapRef = useRef(null);

  useEffect(() => {
    const cachedRef: any = InnerWrapRef.current,
      observer = new IntersectionObserver(
        ([e]) => {
          setIsSticky(e.intersectionRatio < 1);
        },
        { threshold: [1] },
      );
    observer.observe(cachedRef);
    // unmount
    return function () {
      observer.unobserve(cachedRef);
    };
  }, []);

  return (
    <>
      <HeaderWrap isSticky={isSticky}>
        <InnerWrap ref={InnerWrapRef}>
          <Flex flexDirection={'row'} alignItems={'center'} mb={'0px'}>
            {/* <Box mr={42}>
              <TabControl data-testid="help-tab" href={'/help'}>
                {'Contact'}
              </TabControl>
            </Box> */}
            <TabControl
              data-testid="chat-tab"
              href={'/help/chat'}
            >
              {t('Chat')}
            </TabControl>
          </Flex>
        </InnerWrap>
      </HeaderWrap>
    </>
  );
};
