import React, { useRef, SyntheticEvent } from 'react';
import { Text, Flex, Box, Button, Icon, Avatar } from 'components';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';
import { push } from 'connected-react-router';
import { actions as workspacesActions } from 'ducks/workspaces/actions';
import { useReduxAction } from 'hooks';
import members from 'pages/components/components/data/members.json'; //TODO remove this and add redux layer
import { useTranslation } from 'react-i18next';

const StyledWrap = styled.div`
  position: relative;
  overflow: hidden;
  width: 230px;
`;

const StyledNumberWrap = styled(Box)`
  position: absolute;
  top: 3px;
  right: -5px;
  height: 100%;
  background-color: ${({ theme }) => theme.white};
`;

const MembersList = (): any => {
  const theme = React.useContext(ThemeContext);

  return (
    <StyledWrap>
      <Flex>
        {members.map((m: any, i) => (
          <Box key={i} mr={4}>
            <Avatar img={m.imageUrl} variant="small" firstName={m.name} />
          </Box>
        ))}
        {members.length > 5 && (
          <StyledNumberWrap mt={1} mr={8} p={4} pl={0}>
            <Text
              fontSize={12}
              lineHeight={'16px'}
              fontWeight={'400'}
              letterSpacing={0.4}
              color={theme.textColors.med}
            >
              {`+ ${members.length - 5}`}
            </Text>
          </StyledNumberWrap>
        )}
      </Flex>
    </StyledWrap>
  );
};

const StyledIcon = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.primary.base};
  border-radius: 0px 5px 0 8px;
`;

const StyledCard = styled(Flex)`
  position: relative;
  overflow: hidden;
  margin: 10px 16px 10px 0;
  max-width: 320px;
  border-radius: 8px;
  box-shadow: ${({ theme, selected }: any) =>
    selected
      ? `${theme.px(0, 0, 0, 1)} ${theme.primary.base};`
      : `0px 0px 0px 1px ${theme.textColors.low};`}
  transition: box-shadow .3s linear;
  cursor: pointer;

  img {
    transition: transform .3s;
  }

  &:hover {
    > div > img {
      transform: scale(1.1);
    }
  }

  &:first-child {
    margin-left: 4px;
  }

  &:nth-child(-n+3) {
    opacity: 0;
  }
`;

const StyledImageWrap = styled.div`
  overflow: hidden;
`;

const WorkspaceCard = ({
  name,
  role,
  image,
  id,
  printersNumber,
  selected,
  isCurrent,
  onSelect,
  onLeave,
  ...props
}: any) => {
  const { t } = useTranslation(['components', 'workspaces']);

  const theme = React.useContext(ThemeContext);

  const ref = useRef(null);
  const pushAction = useReduxAction(push);
  const leaveWorkspace = useReduxAction(workspacesActions.LEAVE.request);
  const handleActionClick = (e: SyntheticEvent) => {
    e.stopPropagation(); // handle specifically action button click
    if (role === 'Admin') {
      // TO-DO WARNING hardcoded roles here and in action button
      pushAction(`workspaces/${id}/edit/details`);
    } else if (role === 'Member') {
      leaveWorkspace({ id });
      onLeave(id);
    }
  };
  return (
    <StyledCard
      {...props}
      flexDirection="column"
      ref={ref}
      onClick={onSelect}
      selected={selected}
    >
      {isCurrent && (
        <StyledIcon justifyContent="center" alignItems="center">
          <Icon icon="checkmark" color={theme.white} size={30} />
        </StyledIcon>
      )}
      <StyledImageWrap>
        <img src={image} alt={image} />
      </StyledImageWrap>
      <Box p={11}>
        <Text
          fontSize={24}
          lineHeight={'32px'}
          fontWeight={'300'}
          letterSpacing={0.4}
          color={theme.textColors.high}
        >
          {name}
        </Text>
      </Box>
      <Box p={11} pt={0}>
        <Box mb={4}>
          <Text
            display={'inline'}
            fontSize={14}
            lineHeight={'21px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.textColors.med}
          >
            {t('components:role')}
          </Text>
        </Box>
        <Text
          fontSize={24}
          lineHeight={'32px'}
          fontWeight={'300'}
          letterSpacing={0.4}
          color={theme.textColors.high}
        >
          {role}
        </Text>
      </Box>
      <Box p={11} pt={0}>
        <Box mb={4}>
          <Text
            display={'inline'}
            fontSize={14}
            lineHeight={'21px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.textColors.med}
          >
            {t('components:printers')}
          </Text>
        </Box>
        <Text
          fontSize={24}
          lineHeight={'32px'}
          fontWeight={'300'}
          letterSpacing={0.4}
          color={theme.textColors.high}
        >
          {printersNumber}
        </Text>
      </Box>
      <Box p={11} pt={0}>
        <Flex flexDirection="column">
          <Text
            display={'inline'}
            fontSize={14}
            lineHeight={'21px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.textColors.med}
          >
            {t('workspaces:members', { count: members.length })}
          </Text>
          <MembersList />
        </Flex>
      </Box>
      <Box pr={11} pb={11} pl={4}>
        <Button
          variant={role === 'Member' ? 'text-destructive' : 'text-primary'}
          size={'lg'}
          onClick={handleActionClick}
        >
          {role === 'Member'
            ? t('workspaces:leave-workspace')
            : t('workspaces:edit-workspace')}
        </Button>
      </Box>
    </StyledCard>
  );
};

export default WorkspaceCard;
