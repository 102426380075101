import React from 'react';
import { Flex, Text } from 'components';
import { DesignsButtons } from './DesignsButtons';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { TemplateType } from 'zsbpsdk/src/templates';

const StyledText = styled(Text)`
  text-align: center;
`;

type Props = {
  labels: TemplateType[];
  noDataText?: string;
  noDataSuggestion?: string;
  showSuggestion?: boolean;
};

export default function NoLabels({
  labels,
  noDataText,
  noDataSuggestion,
  showSuggestion = true,
}: Props) {
  const { t } = useTranslation('templates');

  const theme = React.useContext(ThemeContext);
  return (
    <Flex
      data-testid="no-labels"
      flexDirection={'column'}
      width={'100%'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Text
        fontSize={24}
        lineHeight={'32px'}
        fontWeight={'300'}
        letterSpacing={0.4}
        color={theme.neutral.base}
        mb={8}
      >
        {labels && labels.length < 1
          ? noDataText || t('templates:no-data-designs')
          : t('templates:no-results-designs')}
      </Text>
      {labels && labels.length < 1 && showSuggestion ? (
        <>
          <StyledText
            fontSize={14}
            lineHeight={'21px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.neutral.base}
            mb={24}
          >
            {noDataSuggestion || t('templates:get-started')}
          </StyledText>

          <DesignsButtons />
        </>
      ) : (
        ''
      )}
    </Flex>
  );
}
