import { space, layout, typography, color } from 'styled-system';
import styled from 'styled-components';
import Box from 'components/common/box/Box';
import { ReactNode } from 'react';

type TextProps = {
  fontSize?: number;
  fontWeight?: number | string;
  fontFamily?: string;
  lineHeight?: number | string;
  letterSpacing?: number | string;
  textTransform?: string;
  textAlign?: string;
  color?: string;
  children: string | ReactNode;
  whiteSpace?: string;
  overflow?: string;
  textOverflow?: string;
};

const Text = styled(Box)<TextProps>`
  font-family: ${({ theme }) => theme.defaultFont};
  ${({ lineHeight }) => lineHeight && `text-transform: ${lineHeight};`}
  ${space}
	${layout}
	${typography}
	${color}
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
  ${({ letterSpacing }) => letterSpacing && `text-transform: ${letterSpacing};`}
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
  ${({ overflow }) => overflow && `overflow: ${overflow};`}
  ${({ textOverflow }) => textOverflow && `text-overflow: ${textOverflow};`}
`;

export default Text;
