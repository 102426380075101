import React, { useRef, useCallback } from 'react';
import { Button } from 'components';
import { actions as dataSourcesActions } from 'ducks/dataSources/actions';
import { useReduxAction } from 'hooks';
import { ThemeContext } from 'styled-components';
import { supportedDataTypes } from 'services/api';
import { useTranslation } from 'react-i18next';

export default function LocalFileUploadButton() {
  const { t } = useTranslation('common');

  const theme = React.useContext(ThemeContext);
  const fileRef = useRef<HTMLInputElement>(null);
  const addFileAction = useReduxAction(dataSourcesActions.ADD.request);

  const openFileInput = useCallback(() => {
    fileRef.current?.click();
  }, [fileRef]);

  const handleFileInput = useCallback(
    (e) => {
      if (fileRef?.current?.files) {
        addFileAction({
          file: fileRef?.current?.files?.[0],
          source: 'Local File',
        });
        e.target.value = '';
      }
    },
    [addFileAction],
  );
  return (
    <>
      <input
        name="uploadFile"
        type="file"
        hidden
        accept={supportedDataTypes.toString()}
        ref={fileRef}
        onChange={handleFileInput}
        data-testid="localFiles-uploadInput"
      />
      <Button
        icon={'left'}
        iconName={'upload'}
        iconColor={theme.white}
        variant={'primary'}
        onClick={openFileInput}
        iconSize={20}
        data-testid="localFiles-uploadButton"
      >
        {t('common:upload-file')}
      </Button>
    </>
  );
}
