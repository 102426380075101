import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Text, Flex, OptionsMenu, OptionsMenuItem } from 'components';
import { useTranslation } from 'react-i18next';

type TableProps = {
  columns: any;
  data: any;
  onRowActionClick?: (id: number, action: string) => void;
};

type CellProps = {
  content: any;
  header?: boolean;
};

const TableWrap = styled.table`
  width: 100%;
  min-width: 730px;
  border-collapse: collapse;

  td {
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;

    > div > div {
      position: static;
    }
  }

  th {
    text-align: left;
  }
`;

const Head = styled.thead``;

const Body = styled.tbody``;

const Row = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.neutral.lightest};
  td:first-of-type {
    width: 1%;
    white-space: nowrap;
    padding-right: 16px;
  }
`;

const ColumnName = styled(Text).attrs(({ theme }) => ({
  fontSize: 12,
  lineHeight: '16px',
  letterSpacing: 2,
  textTransform: 'uppercase',
  color: theme.textColors.med,
  pb: 6,
}))``;

const CellText = styled(Text).attrs(({ theme }) => ({
  fontSize: 14,
  lineHeight: '21px',
  letterSpacing: 0.4,
  color: theme.textColors.med,
}))``;

const Name = styled(Text).attrs(({ theme }) => ({
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: 0.4,
  color: theme.textColors.high,
}))``;

const Cell = ({ content, header }: CellProps) => {
  return header ? <th>{content}</th> : <td>{content}</td>;
};

const renderHeadingRow = (column: any, cellIndex: any) => (
  <Cell
    key={`heading-${cellIndex}`}
    content={<ColumnName textAlign={column.align}>{column.title}</ColumnName>}
    header={true}
  />
);

const MembersTable = ({ columns, data, onRowActionClick }: TableProps) => {
  const { t } = useTranslation('common');

  const handleAction = useCallback(
    (id, action) => {
      if (onRowActionClick) {
        onRowActionClick(id, action);
      }
    },
    [onRowActionClick],
  );
  return (
    <TableWrap>
      <Head>
        <Row key="heading">{columns.map(renderHeadingRow)}</Row>
      </Head>
      <Body>
        {data.map((row: any, rowIndex: number) => {
          return (
            <Row key={rowIndex}>
              {columns.map((column: any) =>
                column.key === 'avatar' ? (
                  <Cell
                    key={column.key}
                    content={
                      <img src={row[column.key]} alt={t('common:avatar')} />
                    }
                  />
                ) : column.key === 'name' ? (
                  <Cell
                    key={column.key}
                    content={
                      <Flex flexDirection={'column'}>
                        <Name>{row[column.key]}</Name>
                        <CellText>{row['email']}</CellText>
                      </Flex>
                    }
                  />
                ) : column.key === 'action' ? (
                  <Cell
                    key={column.key}
                    content={
                      <Flex justifyContent={'flex-end'}>
                        <OptionsMenu>
                          <OptionsMenuItem
                            onClick={() => handleAction(row['id'], 'edit')}
                          >
                            {'Edit'}
                          </OptionsMenuItem>
                        </OptionsMenu>
                      </Flex>
                    }
                  />
                ) : (
                  <Cell
                    key={column.key}
                    content={
                      <CellText textAlign={column.align}>
                        {column.formatter
                          ? column.formatter(row[column.key])
                          : row[column.key]}
                      </CellText>
                    }
                  />
                ),
              )}
            </Row>
          );
        })}
      </Body>
    </TableWrap>
  );
};

export default MembersTable;
