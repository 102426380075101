import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getQuery } from 'ducks/router/selectors';
import { selectMyDesignSizesLoaded } from 'ducks/templates/selectors';
import { Dropdown, Flex } from 'components';
import { DropdownWrapper } from 'pages/templates/components/Header.styles';
import { SORT_DEFAULT, PAGE_SIZE_DEFAULT } from 'utils/defaults';
import { useReduxSelector, useUrlParam } from 'hooks';
import Unit, { getEtch } from 'utils/unitFormatter';
import { getUnit } from 'ducks/preferences/selectors';
import styled from 'styled-components';
import { device } from 'utils/mediaQueries';

export const DropdownContainer = styled(Flex)`
  @media ${device.tablet} {
    margin-top: 20px;
    width: 100%;
  }
`;

type CustomFilterDropdownProps = {
  sizes: { width: number; height: number }[];
};

type OptionsSizes = { value: string; label: string };

const optionsSort: OptionsSizes[] = [
  { value: 'asc', label: 'Name (A to Z)' },
  { value: 'desc', label: 'Name (Z to A)' },
];

const getOptions = (
  sizes: { width: number; height: number }[],
  unit: Unit,
): OptionsSizes[] =>
  sizes
    .sort((a, b) =>
      a.width !== b.width ? a.width - b.width : a.height - b.height,
    )
    .map((size) => {
      return {
        value: `${size.width}x${size.height}`,
        label: getEtch(size, unit),
      };
    });

const buildPageSizeOption = (pageSize: string): OptionsSizes => {
  return {
    value: pageSize,
    label: `Show ${pageSize} item${pageSize === '1' ? '' : 's'}`,
  };
};

const getSize = (size: string, options: OptionsSizes[]): string =>
  options.find((option: OptionsSizes) => option.value === size)?.label ?? '-1';

const optionsPageSize = [
  buildPageSizeOption('15'),
  buildPageSizeOption(PAGE_SIZE_DEFAULT),
  buildPageSizeOption('90'),
];

const FilterDropdown = ({ sizes }: CustomFilterDropdownProps) => {
  const { t } = useTranslation();
  const unit = useReduxSelector(getUnit);
  const query = useReduxSelector(getQuery);
  const sizesLoaded = useReduxSelector(selectMyDesignSizesLoaded);

  const [size, setSize] = useUrlParam('size', query.size || '-1');
  const [sort, setSort] = useUrlParam('sort', SORT_DEFAULT);
  const [pageSize, setPageSize] = useUrlParam('pageSize', PAGE_SIZE_DEFAULT);

  const options = useMemo(
    () => [
      { value: '-1', label: t('common:all-sizes') },
      ...getOptions(sizes, unit),
    ],
    [sizes, unit, t],
  );

  useEffect(() => {
    if (!sizesLoaded) {
      return;
    }
    const selectedSize = options.find(
      (option: OptionsSizes) => option.value === size,
    );

    if (selectedSize?.value !== size) {
      setSize('-1');
    }
  }, [sizesLoaded, size, options, setSize]);

  return (
    <DropdownContainer>
      <DropdownWrapper width={204}>
        <Dropdown
          data-testid="template-size-filter"
          value={getSize(size, options)}
          noOutline={true}
          options={options}
          onChange={setSize}
        />
      </DropdownWrapper>

      <DropdownWrapper width={168}>
        <Dropdown
          data-testid="template-name-filter"
          value={sort || SORT_DEFAULT}
          options={optionsSort}
          onChange={setSort}
          noOutline={true}
        />
      </DropdownWrapper>

      <DropdownWrapper width={176}>
        <Dropdown
          data-testid="page-size-dropdown"
          value={pageSize || PAGE_SIZE_DEFAULT}
          options={optionsPageSize}
          onChange={setPageSize}
          noOutline={true}
        />
      </DropdownWrapper>
    </DropdownContainer>
  );
};

export default FilterDropdown;
