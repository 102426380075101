import * as React from 'react';
import { Text, Card, Flex } from 'components';
import { ThemeContext } from 'styled-components';

const TypographyView = (): any => {
  const theme = React.useContext(ThemeContext);
  return (
    <Card p={10} m={10} elevation={'two'}>
      <Flex flexDirection="row">
        <Flex flexDirection="column">
          <h2>Typography</h2>

          <Text
            fontSize={28}
            lineHeight={'36px'}
            fontWeight={'300'}
            letterSpacing={0.4}
            color={theme.neutral.darkest}
          >
            {'Heading 1'}
          </Text>
          <Text
            fontSize={24}
            lineHeight={'32px'}
            fontWeight={'300'}
            letterSpacing={0.4}
            color={theme.neutral.darkest}
          >
            {'Heading 2'}
          </Text>
          <Text
            fontSize={18}
            lineHeight={'28px'}
            fontWeight={'normal'}
            letterSpacing={0.4}
            color={theme.neutral.darkest}
          >
            {'Heading 3'}
          </Text>
          <Text
            fontSize={16}
            lineHeight={'24px'}
            fontWeight={'500'}
            letterSpacing={0.4}
            color={theme.neutral.darkest}
          >
            {'Button / Body 2'}
          </Text>

          <Text
            fontSize={16}
            lineHeight={'24px'}
            fontWeight={'normal'}
            letterSpacing={0.4}
            color={theme.neutral.darkest}
          >
            {'Body'}
          </Text>
          <Text
            display={'inline'}
            fontSize={14}
            lineHeight={'21px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.neutral.darkest}
          >
            {'Label'}
          </Text>
          <Text
            fontSize={12}
            lineHeight={'16px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.neutral.darkest}
          >
            {'Caption'}
          </Text>
          <Text
            fontSize={12}
            lineHeight={'16px'}
            fontWeight={'400'}
            textTransform={'uppercase'}
            color={theme.neutral.darkest}
          >
            {'Overline'}
          </Text>
          <Text
            fontSize={10}
            lineHeight={'14px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.neutral.darkest}
          >
            {'App Bar'}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};

export default TypographyView;
