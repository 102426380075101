import React from 'react';
import { Flex } from 'components';
import styled from 'styled-components';
import { AvatarForm } from './AvatarForm';
import { DetailsForm } from './DetailsForm';
import { ActionButtonsFooter } from './ActionButtonsFooter';
import { device } from 'utils/mediaQueries';

const PageWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;

const FormsContainer = styled(Flex)`
  @media ${device.mobile} {
    flex-direction: column;
    justify-content: flex-start;
  }

  flex-direction: row;
  justify-content: space-between;
  max-width: 780px;
`;

export const Account = () => {
  return (
    <PageWrapper>
      <FormsContainer>
        <AvatarForm />
        <DetailsForm />
      </FormsContainer>
      <ActionButtonsFooter />
    </PageWrapper>
  );
};
