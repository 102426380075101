import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import {
  AnnouncementWrap,
  Link,
  LinkWrap,
  MessageWrap,
} from './Announcement.styles';
import { BannerMessage, Preferences } from 'zsbpsdk/proto/ZsbpPortalService_pb';
import {
  getBannerMessageContentForLanguage,
  incrementExpirableBannerMessageInfo,
} from 'utils/bannerMessages';
import { getCustomer } from 'ducks/auth/selectors';
import { useReduxSelector } from 'hooks';
import { CustomerInfoType } from 'zsbpsdk/src/customer';

type AnnouncementType = {
  bannerMessages: BannerMessage[];
  language: Preferences.Language;
  onModalOpen: (bannerMessageContent: BannerMessage.Content) => void;
};

const Announcement: FC<AnnouncementType> = ({
  bannerMessages,
  language,
  onModalOpen,
}): ReactElement | null => {
  const [nextBannerMessageContent, setNextBannerMessageContent] = useState<BannerMessage.Content>();
  const [currentBannerMessageContent, setCurrentBannerMessageContent] = useState<BannerMessage.Content>();
  const [currentBannerID, setCurrentBannerID] = useState<number>();
  const [shown, setShown] = useState<boolean>(true);

  const currentCustomer: CustomerInfoType = useReduxSelector(getCustomer);

  const handleClick = useCallback(() => {
    if (currentBannerMessageContent) {
      const linkUrl = currentBannerMessageContent.getLinkurl();
      if (linkUrl.trim() !== '') {
        window.open(linkUrl);
      } else {
        onModalOpen(currentBannerMessageContent);
      }
    }
  }, [onModalOpen, currentBannerMessageContent]);

  useEffect(() => {
    const bannerMessagesLength = bannerMessages.length;
    const bannerMessage = bannerMessagesLength ? bannerMessages[0] : undefined;
    const bannerMessageContent = bannerMessage ? getBannerMessageContentForLanguage(bannerMessage, language) : undefined;
    const bannerMessageId = bannerMessage ? bannerMessage.getId() : undefined;

    setCurrentBannerID(bannerMessageId);
    setCurrentBannerMessageContent(bannerMessageContent);

    let interval: NodeJS.Timeout;
    let index = 0;
    if (bannerMessagesLength > 1) {
      interval = setInterval(() => {
        index = index >= bannerMessagesLength - 1 ? 0 : index + 1;
        const nextBannerMessage = bannerMessages[index];

        setShown(false);
        setCurrentBannerID(nextBannerMessage.getId());
        setNextBannerMessageContent(
          getBannerMessageContentForLanguage(nextBannerMessage, language),
        );
      }, 10000);
    }

    return () => clearInterval(interval);
  }, [bannerMessages, language]);

  useEffect(() => {
    incrementExpirableBannerMessageInfo(
      currentCustomer,
      currentBannerID!
    );
  }, [currentBannerID, currentCustomer]);

  const handleTransitionEnd = useCallback(() => {
    if (!shown) {
      setCurrentBannerMessageContent(nextBannerMessageContent);
      setShown(true);
    }
  }, [nextBannerMessageContent, shown]);

  return currentBannerMessageContent
    ? (
        <AnnouncementWrap shown={shown} onTransitionEnd={handleTransitionEnd}>
          <MessageWrap>{currentBannerMessageContent.getMessagetext()}</MessageWrap>
          {currentBannerMessageContent.getLinktext() && (
            <LinkWrap>
              &nbsp;&nbsp;
              <Link href="#!" onClick={handleClick}>
                {currentBannerMessageContent.getLinktext()}
              </Link>
            </LinkWrap>
          )}
        </AnnouncementWrap>
      )
    : null;
};

export default Announcement;
