import React from 'react';
import styled from 'styled-components';
import EditSectionHeader from './EditSectionHeader';
import { MembersTable, Input, Icon, Box } from 'components';
import { dateFormatter } from 'utils/dateFormatter';
import { members as data } from 'utils/demo/members';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

// TO-DO consider index file containing all common !styled! Icons
export const SearchIcon = styled(Icon).attrs(({ theme }) => ({
  size: 20,
  icon: 'search',
  color: theme.neutral.base,
}))``;

const Members = ({ workspace }: any) => {
  const { t } = useTranslation('workspaces');

  const columns = [
    { key: 'avatar' },
    { key: 'name', title: i18n.t('workspaces:column-headers.name') },

    { key: 'role', title: i18n.t('workspaces:column-headers.role') },
    {
      key: 'templates',
      title: i18n.t('workspaces:column-headers.templates'),
      align: 'center',
    },
    {
      key: 'lastSeen',
      title: i18n.t('workspaces:column-headers.last-seen'),
      formatter: dateFormatter,
      align: 'right',
    },
    { key: 'action', align: 'right' },
  ];

  return (
    <>
      <EditSectionHeader
        pb={20}
        title={t('workspaces:members')}
        subtitle={t('workspaces:workspace-members-count')}
      />
      <Box mb={40} width={467}>
        <Input
          placeholder={t('workspaces:invite-members-by-email')}
          iconStart={<SearchIcon />}
        />
      </Box>
      <MembersTable data={data} columns={columns} />
    </>
  );
};

export default Members;
