import React from 'react';
import { Flex, Text } from 'components';
import { FilesButtons } from './FilesButtons';
import styled from 'styled-components';
import { getAllFilesData } from 'ducks/dataSources/selectors';
import { useReduxSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

const StyledText = styled(Text)`
  text-align: center;
`;

export default function NoFiles() {
  const { t } = useTranslation('dataSources');

  const files = useReduxSelector(getAllFilesData);
  const theme = React.useContext(ThemeContext);

  return (
    <Flex
      data-testid="no-files"
      flexDirection={'column'}
      width={'100%'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Text
        fontSize={24}
        lineHeight={'32px'}
        fontWeight={'300'}
        letterSpacing={0.4}
        color={theme.neutral.base}
        mb={8}
      >
        {files && files.length < 1
          ? t('templates:no-data-files')
          : t('templates:no-results-files')}
      </Text>
      {files && files.length < 1 ? (
        <>
          <StyledText
            fontSize={14}
            lineHeight={'21px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.neutral.base}
            mb={24}
          >
            {t('dataSources:start-by-adding-files')}
          </StyledText>

          <FilesButtons />
        </>
      ) : (
        ''
      )}
    </Flex>
  );
}
