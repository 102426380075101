import React, { Suspense, useEffect, useState } from 'react';
import { Button } from 'components';
import { useReduxAction, useReduxSelector } from 'hooks';
import { actions as authActions } from 'ducks/auth/actions';
import styled from 'styled-components';
import { PageWrap, Flex } from 'components';
import { getCustomer, getUpdateCustomerLoading } from 'ducks/auth/selectors';
import { replace } from 'connected-react-router';
import ReactMarkdown from 'react-markdown';
import { HOME_PAGE_PATH } from 'utils/config';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  background-repeat: no-repeat;
  background-size: 100% 520px;

  & > ${PageWrap} {
    padding-left: 0;
    width: 100%;
  }

  ol {
    counter-reset: index;
    list-style-type: none;
  }

  li > p::before {
    counter-increment: index;
    content: counters(index, '.', decimal) '. ';
  }
`;

const StyledButton = styled(Button)`
  border-radius: 8px;
  width: 768px;
  max-width: calc(100% - 32px);
  margin: 8px;
  height: 48px;
  display: flex;
  justify-content: center;
`;

const EulaContainer = styled.div`
  width: 768px;
  max-width: calc(100% - 32px);
  flex-grow: 1;
  margin-bottom: 24px;
  margin-left: 16px;
  margin-right: 16px;
  height: 65vh;
  max-height: calc(100% - 120px);
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StyledFlex = styled(Flex)`
  max-height: 800px;
`;

const EulaContent = styled.div.attrs((props) => ({
  'data-testid': props['data-testid'],
}))`
  overflow-y: scroll;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 24px;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.neutral.lighter}};
  }

  scrollbar-color: ${({ theme }) => theme.neutral.lighter}};
  scrollbar-width: thin;

  * {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
`;
const Header = styled.h1`
  font-size: 28px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0.4px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const EulaPage = () => {
  const { t } = useTranslation(['other', 'common']);

  const [md, setMd] = useState('');
  const [scrollComplete, SetScrollComplete] = useState(false);

  const updateCustomer = useReduxAction(authActions.UPDATE_CUSTOMER.request);
  const updateCustomerLoading = useReduxSelector(getUpdateCustomerLoading);
  const replaceAction = useReduxAction(replace);

  const customer: any = useReduxSelector(getCustomer);

  useEffect(() => {
    fetch('./eula_markdown.md')
      .then((res) => res.text())
      .then((md) => {
        setMd(md);
      });
  }, []);

  useEffect(() => {
    if (customer?.eulaAcceptedVersion) {
      replaceAction(HOME_PAGE_PATH);
    }
  }, [customer, replaceAction]);

  if (!customer || customer?.eulaAcceptedVersion) {
    return null;
  }

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
    if (bottom < 10) {
      SetScrollComplete(true);
    }
  };

  const handleAccepted = async () => {
    try {
      customer.eulaAcceptedVersion = '1';
      updateCustomer(customer);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Wrapper>
      <PageWrap>
        <Flex
          width="100%"
          height="100vh"
          alignItems="center"
          alignContent="center"
          flexDirection="row"
          justifyContent="center"
        >
          <StyledFlex
            m="auto"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >
            <EulaContainer>
              <Header>{t('other:end-user-license-agreement')}</Header>
              <EulaContent data-testid="eula-content" onScroll={handleScroll}>
                <ReactMarkdown source={md} />
              </EulaContent>
            </EulaContainer>

            <StyledButton
              data-testid="accept-eula"
              onClick={handleAccepted}
              disabled={!scrollComplete}
              isLoading={updateCustomerLoading}
            >
              {t('common:accept')}
            </StyledButton>
          </StyledFlex>
        </Flex>
      </PageWrap>
    </Wrapper>
  );
};

export const Eula = () => (
  <Suspense fallback="">
    <EulaPage />
  </Suspense>
);
