import React from 'react';
import styled from 'styled-components';
import { Flex, TabControl } from 'components';
import { device } from 'utils/mediaQueries';
import { useTranslation } from 'react-i18next';

const TabsContainer = styled(Flex)`
  @media ${device.mobile} {
    margin-bottom: 24px;
  }

  flex-direction: row;
  align-items: center;
  margin-bottom: 44px;
`;

export const NavigationTab = () => {
  const { t } = useTranslation('apps');

  return (
    <TabsContainer>
      {/* <Box mr={42}> */}
      {/* <TabControl href={'/apps'}>{'Integrations'}</TabControl> */}
      {/* </Box> */}
      <TabControl
        data-testid="printer-driver-and-apps-tab"
        href={'/apps'}
      >
        {t('apps:printer-driver-and-apps-installer')}
      </TabControl>
    </TabsContainer>
  );
};
