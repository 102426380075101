import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { TemplateType } from 'zsbpsdk/src/templates';
import { replace } from 'connected-react-router';
import { useReduxSelector, useReduxAction } from 'hooks';
import { selectSearchCategoriesWithLabel } from 'ducks/categories/selectors';
import { getUnit } from 'ducks/preferences/selectors';
import { Box, Flex, Loader } from 'components';
import { TemplateCardType } from 'components/app/template-card/TemplateCard';
import { Text, ErrorText, HintText } from 'v2/components/common';
import { actions as templateActions } from 'ducks/templates/actions';
import { zebraFilteredTemplates as selectZebraFilteredTemplates } from 'ducks/templates/selectors';
import { useFilteredTemplates } from 'v2/hooks';

import {
  CategoriesWrap,
  TemplatesWrap,
  StyledTemplateCard,
  StyledCategoryCard,
} from './CommonDesignsPage.styles';

const SectionWrap = styled(Flex).attrs({ flexDirection: 'column' })`
  &:not(:first-child) {
    margin-top: 16px;
  }
`;

export default function SearchResult({
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  const { t } = useTranslation();

  const replaceAction = useReduxAction(replace);
  const zebraFilteredTemplatesAction = useReduxAction(
    templateActions.ZEBRA_FILTERED.request,
  );

  const unit = useReduxSelector(getUnit);
  const { searchCategories, error: searchCategoriesError } = useReduxSelector(
    selectSearchCategoriesWithLabel,
  );

  const {
    filteredTemplatesData: zebraFilteredTemplatesData,
    filteredTemplatesDataLoading: zebraFilteredTemplatesDataLoading,
    filteredTemplatesError: zebraFilteredTemplatesError,
  } = useFilteredTemplates(
    selectZebraFilteredTemplates,
    zebraFilteredTemplatesAction,
  );

  const filteredTemplates = zebraFilteredTemplatesData?.fileDataList || [];

  if (zebraFilteredTemplatesError || searchCategoriesError) {
    return (
      <ErrorText variant="h2" mb={8}>
        {t('templates:server-error')}
      </ErrorText>
    );
  }

  if (zebraFilteredTemplatesDataLoading) {
    return <Loader visible />;
  }

  return (
    <Box {...props}>
      <SectionWrap>
        <HintText
          data-testid="categories-type-length"
          lineHeight={'28px'}
          variant="h3"
        >
          {t('templates:categories-count', { count: searchCategories.length })}
        </HintText>
        {searchCategories.length === 0 && (
          <Text data-testid="categories-not-found" variant="label">
            {t('templates:categories-not-found')}
          </Text>
        )}
        <CategoriesWrap mt={4}>
          {searchCategories.map(({ category, label }) => (
            <StyledCategoryCard
              onClick={() =>
                replaceAction({ search: `?category=${category.name}` })
              }
              key={category.id}
              name={category.name}
              desc={category.desc}
              naPartNumber={label?.naPartNumber}
              emeaPartNumber={label?.emeaPartNumber}
            />
          ))}
        </CategoriesWrap>
      </SectionWrap>
      <SectionWrap>
        <HintText
          data-testid="templates-type-length"
          lineHeight={'28px'}
          variant="h3"
        >
          {t('templates:templates-count', { count: filteredTemplates.length })}
        </HintText>
        {filteredTemplates.length === 0 && (
          <Text data-testid="templates-not-found" variant="label">
            {t('templates:templates-not-found')}
          </Text>
        )}
        <TemplatesWrap mt={4}>
          {filteredTemplates.map((template: TemplateType) => (
            <StyledTemplateCard
              template={template}
              key={template.id}
              elevation={'one'}
              type={TemplateCardType.LIBRARY}
              unit={unit}
              showLastPrint={false}
            />
          ))}
        </TemplatesWrap>
      </SectionWrap>
    </Box>
  );
}
