import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { Flex, Button, Modal, Box, Search } from 'components';
import IconCategoryContainer from './iconContainer/IconCategoryContainer';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ClipArtData, GalleryIconData, IconData } from './iconContainer/IconContainer';
import { SuggestionType } from '../../../../components/lib/search/Search.styles';

const SearchBox = styled(Box)`
  flex-grow: 1;
  margin-left: 32px;
  width: 550px;
  padding-top: 12px;
`;

const ModalBody = styled.div`
  width: 635px;
  padding-left: 4px;
`;

const InnerContainer = styled.div`
  width: 635px;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.neutral.lighter};
  }

  scrollbar-color: ${({ theme }) => theme.neutral.lighter};
  scrollbar-width: thin;
`;

const GalleryModal = forwardRef(({ resolve }: any, ref: any) => {
  const { t } = useTranslation(['dataSources', 'common']);

  const [selectedClipArt, setSelectedClipArt] = useState<ClipArtData | null>(null);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [galleryIconData, setGalleryIconData] = useState<GalleryIconData[]>([]);
  const [iconsList, setIconsList] = useState<string[]>([]);
  const [categoriesList, setCategoriesList] = useState<string[]>([]);
  const [options, setOptions] = useState<Partial<SuggestionType>[]>([]);

  const getGalleryIconData = () => {
    fetch('gallery-icons/icons.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res: Response) => res.json())
      .then((json: GalleryIconData[]) => setGalleryIconData(json));
  };
  useEffect(() => {
    getGalleryIconData();
  }, []);

  //generate options for search
  useEffect(() => {
    let iconsListTemp: string[] = [];
    const categoryListTemp: string[] = galleryIconData.map((galleryIcon: GalleryIconData) => {
      iconsListTemp = galleryIcon.icons.map((icon: IconData) => icon.name);
      return galleryIcon.category;
    });

    setIconsList(iconsListTemp);
    setCategoriesList(categoryListTemp);
  }, [galleryIconData]);

  useEffect(() => {
    if (selectedClipArt !== null) {
      resolve(selectedClipArt, ref.current); //send back to printoption step
      ref.current.close();
    }
  }, [selectedClipArt, ref, resolve]);

  //options for search filter
  const handleChange = (phrase) => {
    const catFiltered = categoriesList.filter((category: string) =>
      category.toLowerCase().includes(phrase.toLowerCase()),
    );
    const iconsFiltered = iconsList.filter((icon: string) =>
      icon.toLowerCase().includes(phrase.toLowerCase()),
    );
    const optionsArr: Partial<SuggestionType>[] = [];
    if (catFiltered.length > 0) {
      const categoryOptions = catFiltered.map((category: string) => {
        return {
          name: category,
        }
      });
      optionsArr.push(
        { label: t('dataSources:categories'), type: 'section' },
        ...categoryOptions,
      );
    }
    if (iconsFiltered.length > 0) {
      const iconOptions = iconsFiltered.map((icon: string) => {
        return {
          name: icon
        }
      });
      optionsArr.push(
        { label: t('dataSources:icons'), type: 'section' },
        ...iconOptions,
      );
    }
    setOptions(optionsArr);
  };

  const handleClose = useCallback(() => {
    if (selectedClipArt == null) {
      resolve('');
    }
    setSelectedClipArt(null);
  }, [resolve, selectedClipArt]);

  return (
    <Modal
      ref={ref}
      title={t('dataSources:zebra-gallery')}
      defaultPadding={false}
      onClose={handleClose}
      header={
        <Flex justifyContent={'flex-end'}>
          <Button
            variant={'text-primary'}
            onClick={() => {
              ref.current.close();
            }}
          >
            {t('common:cancel')}
          </Button>
        </Flex>
      }
      clickOutside={true}
    >
      <ModalBody>
        <InnerContainer>
          <SearchBox>
            <Search
              placeholder={t('dataSources:search-icons')}
              data-testid="input-search"
              onChange={handleChange}
              onSelect={setSearchPhrase}
              initialSearch={searchPhrase}
              options={options}
            />
          </SearchBox>

          <IconCategoryContainer
            searchPhrase={searchPhrase}
            setClipArt={setSelectedClipArt}
            data={galleryIconData}
          />
        </InnerContainer>
      </ModalBody>
    </Modal>
  );
});

export default GalleryModal;
