import { FrontendEnvironment, getFrontendEnvironment } from '../utils/config';
import {
  createContext,
  FC,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

type Context = {
  frontendEnvironment: FrontendEnvironment;
};
const EnvironmentContext = createContext<Context | null>(null);

export const useEnvironment = (): Context => {
  const context = useContext(EnvironmentContext);

  if (context === null) {
    throw new Error('useEnvironment hook must be used within EnvironmentProvider');
  }

  return context;
};

export const EnvironmentProvider: FC<{ children: ReactNode }> = ({
                                                                   children,
                                                                 }): ReactElement => {
  const [frontendEnvironment, setFrontendEnvironment] =
    useState<FrontendEnvironment>(FrontendEnvironment.Sandbox);

  useEffect(() => {
    const env = getFrontendEnvironment();
    setFrontendEnvironment(env);
  }, []);

  return (
    <EnvironmentContext.Provider value={{ frontendEnvironment }}>
      {children}
    </EnvironmentContext.Provider>
  );
};