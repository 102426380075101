import React, { useRef } from 'react';
import { useModal } from 'hooks';
import { Button } from 'components';
import { ThemeContext } from 'styled-components';
import CloudModal from './Cloud/CloudModal';
import { useTranslation } from 'react-i18next';

const CloudFileUploadButton = () => {
  const { t } = useTranslation('dataSources');

  const cloudModalRef = useRef(null);
  const cloudModalHook = useModal(cloudModalRef);
  const theme = React.useContext(ThemeContext);
  return (
    <>
      <Button
        data-testid="link-file-button"
        icon={'left'}
        iconName={'link'}
        iconColor={theme.white}
        variant={'primary'}
        iconSize={20}
        onClick={() => {
          cloudModalHook.open();
        }}
      >
        {t('dataSources:link-file')}
      </Button>
      <CloudModal ref={cloudModalRef}></CloudModal>
    </>
  );
};

export default CloudFileUploadButton;
