import React, { forwardRef, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Box from '../../common/box/Box';
import ModalPopover from '../../common/ModalPopover';
import Typography from '../../common/typography/Typography';
import { Icon, Flex } from 'components';
import { ModalRef, useIsMounted } from 'hooks';

const CloseIcon = styled(Icon).attrs(({ theme }) => ({
  icon: 'close',
  color: theme.neutral.base,
  size: 14,
}))``;

type ModalProps = {
  /** content of the component */
  children: React.ReactChildren;
  /** title of the component */
  title: string;
  /** optional subtitle of the component */
  subTitle?: string;
  /** if true, the component will open */
  open: boolean;
  /** if true, the component will close when clicking outside of it */
  clickOutside?: boolean;
  /** if true, modal will be small */
  small?: boolean;
  /** content for footer */
  footer?: React.ReactChildren;
  /** callback fired when the component is closed */
  onClose: (preventTriggerEvent?: boolean) => {};
  defaultPadding?: boolean;
  headerPadding?: string;
  closeIconRight?: string;
  closeIconTop?: string;
  dataTestid?: string;
  /** Shows close button in top right. Defaults to true */
  hasCloseButton: boolean;
  /** Boolean for if there should be a bottom border on the header */
  hasBorder: boolean;
  //** if you want to hide manually the modal */
  hidden: boolean;
  /** Should you animate the semi-transparent backdrop, or just show it */
  isAnimatedBackdrop: boolean;

  zIndex?: number;
};

// TO-DO probably elevation will be prop...

export const ModalContent = styled(ModalPopover).attrs({
  top: '50%',
  left: '50%',
  anchor: null,
})`
  box-shadow: ${({ theme }) => theme.elevations.six};
  border-radius: ${({ theme }) => theme.space[4]}px;
  background-color: ${({ theme }) => theme.white};
  transform: scale(0.9);
  max-width: 95vw;
  max-height: 95vh;
  overflow: hidden;
  outline: none;
  ${({ small, theme }) =>
    small &&
    `
    max-width: 454px;
    box-shadow: ${theme.elevations.six};
  `}
`;

const CloseButton = styled(Box)<any>`
  position: absolute;
  right: ${({ closeIconRight }) => closeIconRight};
  top: ${({ closeIconTop }) => closeIconTop};
  cursor: pointer;
  font-size: 0;
  border: 0 none;
  background: transparent;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Header = styled(Box)<any>`
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: ${({ hasBorder }: any) =>
    hasBorder ? `1px solid ${({ theme }) => theme.neutral.lighter};` : `0px;`};

  padding: ${({ headerPadding }) => headerPadding};
`;

interface BodyProps {
  hasFooter: boolean;
}
const Body = styled.div.attrs<BodyProps>(
  ({ hasFooter }) => hasFooter,
)<BodyProps>`
  box-sizing: border-box;
  padding: 40px;
  overflow-y: auto;
  max-height: calc(95vh - ${({ hasFooter }) => (hasFooter ? 2 : 1) * 96}px);
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.neutral.lighter}};
  }
  scrollbar-color: ${({ theme }) => theme.neutral.lighter}};
  scrollbar-width: thin;
`;

const NoPaddingBody = styled.div.attrs<BodyProps>(
  ({ hasFooter }) => hasFooter,
)<BodyProps>`
  box-sizing: border-box;
  padding: 0;
  overflow-y: auto;
  max-height: calc(
    90vh - ${({ hasFooter }: any) => (hasFooter ? 2 : 1) * 96}px
  );
`;

const Footer = styled(Box).attrs({ py: 24, px: 24 })`
  border-top: ${({ hasBorder }: any) =>
    hasBorder ? `1px solid ${({ theme }) => theme.neutral.lighter};` : `0px;`};
`;

const ModalTitle = styled(Typography).attrs({
  fontWeight: 300,
  lineHeight: '28px',
  fontSize: '24px',
})`
  color: ${({ theme }) => theme.neutral.darkest};
  max-width: 300px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

const SubTitle = styled(Typography).attrs({
  fontWeight: 400,
  lineHeight: '21px',
  fontSize: '14px',
})`
  color: ${({ theme }) => theme.neutral.base};
`;

const Modal = (
  {
    children,
    title,
    subTitle,
    open: _open,
    onClose,
    clickOutside = false,
    small = false,
    footer,
    defaultPadding = true,
    hasCloseButton = true,
    hasBorder = true,
    headerPadding = '32px 40px 32px',
    closeIconRight = '16px',
    closeIconTop = '16px',
    hidden = false,
    isAnimatedBackdrop = true,
    ...props
  }: ModalProps,
  ref: any,
) => {
  const [isOpen, setOpen] = useState<any>(_open);
  const isMounted = useIsMounted();
  const open = () => setOpen(true);
  const toggle = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    const refData: ModalRef = {
      isOpen,
      isMounted,
      open,
      close: handleClose,
      toggle,
    };

    if (typeof ref !== 'function') {
      ref.current = refData;
    } else {
      ref(refData);
    }
  });

  useEffect(() => {
    setOpen(_open);
  }, [_open]);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }

    if (ref.current.isMounted()) {
      setOpen(false);
    }
  }, [onClose, ref]);

  const closeButton = (
    <CloseButton
      closeIconRight={closeIconRight}
      closeIconTop={closeIconTop}
      data-testid={'close-button'}
      as={'button'}
      onClick={handleClose}
    >
      <CloseIcon />
    </CloseButton>
  );

  return (
    <>
      {hidden ? (
        <></>
      ) : (
        <ModalContent
          small={small}
          open={isOpen}
          onClose={clickOutside ? handleClose : null}
          data-open={isOpen}
          isAnimatedBackdrop={isAnimatedBackdrop}
          {...props}
        >
          {!small ? (
            <>
              <Header headerPadding={headerPadding}>
                <Flex flexDirection="column">
                  <Flex>
                    <ModalTitle data-testid="modal-title">{title}</ModalTitle>
                    {hasCloseButton && closeButton}
                  </Flex>
                  <Flex mt={4}>
                    {subTitle && (
                      <SubTitle data-testid="modal-subtitle">
                        {subTitle}
                      </SubTitle>
                    )}
                  </Flex>
                </Flex>
              </Header>
              {defaultPadding ? (
                <Body hasFooter={!!footer}>{children}</Body>
              ) : (
                <NoPaddingBody hasFooter={!!footer}>{children}</NoPaddingBody>
              )}
              {footer && <Footer>{footer}</Footer>}
            </>
          ) : (
            <Body hasFooter={!!footer}>
              {hasCloseButton && closeButton}
              {children}
            </Body>
          )}
        </ModalContent>
      )}
    </>
  );
};

const ModalForwardRef = forwardRef<ModalProps, any>(Modal);

export default ModalForwardRef;
