import React from 'react';
import Flex from 'components/common/flex/Flex';
import styled from 'styled-components';
import { SpaceProps, WidthProps, FlexboxProps } from 'styled-system';

type CardProps = React.PropsWithChildren<{
  /** elevation level  */
  elevation?: 'one' | 'two' | 'three' | 'four' | 'five' | 'six';
  /** background of the component */
  background?: string;
  /** if true, the component will be selected */
  selected?: boolean;
  onClick?: React.MouseEventHandler;
}> &
  SpaceProps &
  WidthProps &
  FlexboxProps;

const CardWrap = styled(Flex).attrs(({ ...props }: CardProps) => ({
  p: 19,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  ...props,
}))`
  overflow: hidden;
  border-radius: ${({ theme }) => theme.px(4)};
  background-color: ${({ background, selected, theme }) =>
    background && selected ? background : theme.white};
  ${({ theme, elevation }) =>
    elevation &&
    `
    box-shadow: ${theme.elevations[elevation]};
  `}
`;

const Card: React.FC<CardProps> = ({
  children,
  elevation,
  background,
  selected,
  onClick,
  ...props
}: CardProps) => (
  <CardWrap
    {...props}
    onClick={onClick}
    elevation={elevation}
    background={background}
    selected={selected}
  >
    {children}
  </CardWrap>
);

export default Card;
