import React, { useCallback, useRef, useState, useContext } from 'react';
import { Flex, OptionsMenu, OptionsMenuItem, Icon } from 'components';
import { useModal, useReduxSelector } from 'hooks';
import DeleteFileModal from '../DeleteFileModal';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import OverwriteFileModal from '../OverwriteFileModal';
import { isOverwriteFileModalOpened } from '../../../../ducks/modalDialog/selectors';
import {
  Body,
  CellText,
  ColumnName,
  Head,
  NameWithTooltip,
  Row,
  TableWrap,
} from './FilesTable.styles';

type TableProps = {
  columns: any;
  data: any;
  onRowActionClick?: (id: number, action: string) => void;
  hover?: boolean;
  onRowSelect?: (file: any) => void;
};

type CellProps = {
  content: any;
  header?: boolean;
};

export const MAX_FILETABLE_NAME_LENGTH = 30;

const Cell = ({ content, header }: CellProps) => {
  return header ? <th>{content}</th> : <td>{content}</td>;
};

const renderHeadingRow = (column: any, cellIndex: any) => (
  <Cell
    key={`heading-${cellIndex}`}
    content={<ColumnName textAlign={column.align}>{column.title}</ColumnName>}
    header={true}
  />
);

const FilesTable = ({
  columns,
  data,
  onRowActionClick,
  hover,
  onRowSelect,
}: TableProps) => {
  const [selected, setSelected] = useState('');

  const overwriteFileModalIsOpened = useReduxSelector(
    isOverwriteFileModalOpened,
  );
  const overwriteFileModalRef = useRef(null);

  const handleAction = useCallback(
    (id, action) => {
      if (onRowActionClick) {
        onRowActionClick(id, action);
      }
    },
    [onRowActionClick],
  );

  const handleSelectRow = useCallback(
    (file) => {
      if (onRowSelect) {
        onRowSelect(file);
      }
    },
    [onRowSelect],
  );

  return (
    <TableWrap>
      <Head>
        <Row key="heading" hover={false} selected={false}>
          {columns.map(renderHeadingRow)}
        </Row>
      </Head>
      <Body>
        {data.map((row: any, rowIndex: number) => (
          <FileRow
            onClick={() => {
              setSelected(row.id);
              handleSelectRow({ id: row.id, name: row.name });
            }}
            selected={selected === row.id}
            key={row.id}
            row={row}
            columns={columns}
            handleAction={handleAction}
            hover={hover}
          />
        ))}
      </Body>
      {overwriteFileModalIsOpened && (
        <OverwriteFileModal ref={overwriteFileModalRef} />
      )}
    </TableWrap>
  );
};

const FileRow = ({ row, columns, handleAction, hover, selected, onClick }) => {
  const { t } = useTranslation('common');
  const deleteModalRef = useRef(null);
  const deleteModal = useModal(deleteModalRef);
  const optionsRef = useRef(null);
  const theme = useContext(ThemeContext);

  const getName = useCallback(
    (name: string) =>
      name.length <= MAX_FILETABLE_NAME_LENGTH
        ? name
        : name.substr(0, MAX_FILETABLE_NAME_LENGTH) + '...',
    [],
  );

  return (
    <Row
      data-file-name={row['name']}
      hover={hover}
      key={row.id}
      selected={selected}
      onClick={onClick}
    >
      {columns.map((column: any) =>
        column.key === 'mimetype' ? (
          <Cell
            key={column.key}
            content={
              <Icon
                icon={row[column.key]}
                size={'24'}
                color={theme.neutral.base}
              ></Icon>
            }
          />
        ) : column.key === 'name' ? (
          <Cell
            key={column.key}
            content={
              <Flex
                data-testid="fileName"
                flexDirection={'column'}
              >
                <NameWithTooltip tooltip={row[column.key]}>
                  {getName(row[column.key])}
                </NameWithTooltip>
              </Flex>
            }
          />
        ) : column.key === 'action' && !hover ? (
          <Cell
            key={column.key}
            content={
              <Flex justifyContent={'flex-end'}>
                <OptionsMenu
                  data-file-name={row['name']}
                  data-testid="manage-file"
                  ref={optionsRef}
                >
                  {/* <OptionsMenuItem
                    onClick={() => handleAction(row['id'], 'edit')}
                  >
                    {'Replace'}
                  </OptionsMenuItem> */}
                  <OptionsMenuItem
                    onClick={deleteModal.open}
                    danger
                    data-testid="remove-file"
                  >
                    {t('common:remove')}
                  </OptionsMenuItem>
                </OptionsMenu>
              </Flex>
            }
          />
        ) : column.key === 'provider' ? (
          <Cell
            key={column.key}
            content={
              <CellText
                data-testid="file-source"
                textAlign={column.align}
              >
                {column.formatter
                  ? column.formatter(row[column.key])
                  : row[column.key]}
              </CellText>
            }
          />
        ) : (
          <Cell
            key={column.key}
            content={
              <CellText
                data-testid="file-date-added"
                textAlign={column.align}
              >
                {column.formatter
                  ? column.formatter(row[column.key])
                  : row[column.key]}
              </CellText>
            }
          />
        ),
      )}
      <DeleteFileModal ref={deleteModalRef} file={row} />
    </Row>
  );
};

export default FilesTable;
