import * as React from 'react';
import { Input, Icon, Card, Flex, Box } from 'components';
import { ThemeContext } from 'styled-components';

const InputsView = (): any => {
  const theme = React.useContext(ThemeContext);
  return (
    <Card p={10} pb={40} m={10} elevation={'two'}>
      <Flex flexDirection="column">
        <h2>Inputs</h2>
        <Flex flexDirection="column" flexWrap="wrap">
          <Box width={280} mr={10}>
            <Input placeholder={'Placeholder'} />
          </Box>
          <Box width={280} mr={10} mt={10}>
            <Input
              label={'Some label'}
              iconStart={
                <Icon color={theme.primary.dark} size={20} icon="logo-zebra" />
              }
              onStartIconClick={() => console.log('Start icon click')}
              placeholder={'Placeholder'}
            />
          </Box>
          <Box width={280} mr={10} mt={10}>
            <Input
              iconEnd={'px'}
              onStartIconClick={() => console.log('Start icon click')}
              placeholder={'Placeholder'}
            />
          </Box>
          <Box width={280} mr={10} mt={10}>
            <Input hasError={true} value={'Error state simple with value'} />
          </Box>
          <Box width={280} mr={10} mt={10}>
            <Input
              hasError={true}
              value={'Error state end icon and value'}
              message={'Invalid value'}
              onEndIconClick={() => console.log('End icon click')}
              iconEnd={
                <Icon color={theme.error.base} size={20} icon="logo-zebra" />
              }
            />
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
};

export default InputsView;
