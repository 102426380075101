import React, { useMemo } from 'react';
import { CategoryCard, Flex, LabelText, Loader, Text } from 'components';
import { ThemeContext } from 'styled-components';
import Section from './Section';
import { useFetchedData, useReduxSelector, useUrlParam } from 'hooks';
import { getQuery } from 'ducks/router/selectors';
import { zebraFilteredTemplates } from 'ducks/templates/selectors';
import { filteredCategories } from 'ducks/categories/selectors';
import { getCategoriesSuggestions } from 'ducks/search/selectors';
import { actions as templateActions } from 'ducks/templates/actions';
import { actions as categoriesActions } from 'ducks/categories/actions';
import Unit from 'utils/unitFormatter';
import { getUnit } from 'ducks/preferences/selectors';
import { TemplateType } from 'zsbpsdk/src/templates/index';
import {
  PAGE_NUMBER_DEFAULT,
  PAGE_SIZE_DEFAULT,
  SORT_DEFAULT,
} from 'utils/defaults';
import { useTranslation } from 'react-i18next';
import { isObjectEmpty } from 'utils/dataManipulationMethods';
import { getAllLabelSizes } from 'ducks/labelSizes/selectors';
import { StyledTemplateCard, TemplatesWrap } from './Library.styles';
import { TemplateCardType } from '../../../components/app/template-card/TemplateCard';

const RenderResults = ({ isSearching, title, ...props }: any) => {
  const { t } = useTranslation('templates');

  return isSearching && props.children ? (
    <Section title={title}>{props.children}</Section>
  ) : (
    <Flex flexWrap={'wrap'}>
      {props.children?.length ? (
        props.children
      ) : (
        <LabelText
          // pl={[4]}
          ml={0}
        >
          {t('templates:not-found-filter', { term: 'templates', count: 1 })}
        </LabelText>
      )}
    </Flex>
  );
};

const getTemplatesJSX = (templates: TemplateType[], unit: Unit) =>
  templates.map((t: TemplateType, i: number) => (
    <StyledTemplateCard
      template={t}
      key={i}
      elevation={'one'}
      type={TemplateCardType.LIBRARY}
      unit={unit}
      showLastPrint={false}
    />
  ));

const getCategoriesJSX = (
  categories: any,
  setSelectedCategory: any,
  others: any[],
) =>
  others.length &&
  categories.map((c: any, i: number) => {
    const label = others.find((other) => other.id === c.id);
    return (
      <CategoryCard
        onClick={() => setSelectedCategory(c.name)}
        key={i}
        name={c.name}
        desc={c.desc}
        naPartNumber={label?.naPartNumber}
        emeaPartNumber={label?.emeaPartNumber}
      />
    );
  });

const ErrorComponent = () => {
  const theme = React.useContext(ThemeContext);
  const { t } = useTranslation('templates');

  return (
    <Flex
      flexDirection={'column'}
      width={'100%'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Text
        fontSize={24}
        lineHeight={'32px'}
        fontWeight={'300'}
        letterSpacing={0.4}
        color={theme.error.base}
        mb={8}
      >
        {t('templates:server-error')}
      </Text>
    </Flex>
  );
};

const Library = () => {
  const {
    searchLibrary = '',
    pageNumber = PAGE_NUMBER_DEFAULT,
    pageSize = PAGE_SIZE_DEFAULT,
    size = '-1',
    sort = SORT_DEFAULT,
    search = '',
  } = useReduxSelector(getQuery);

  const { t } = useTranslation('templates');

  const unit = useReduxSelector(getUnit);

  const [selectedCategory, setSelectedCategory] = useUrlParam('category', '');
  const { others = [] } = useReduxSelector(getAllLabelSizes);

  const params = useMemo(
    () => ({
      searchLibrary,
      search: search || searchLibrary,
      pageNumber,
      pageSize,
      size,
      sort,
      selectedCategory,
    }),
    [searchLibrary, selectedCategory, pageNumber, pageSize, size, sort, search],
  );

  const [
    zebraFilteredTemplatesData,
    zebraFilteredTemplatesLoading,
    zebraFilteredTemplatesError,
  ] = useFetchedData(
    zebraFilteredTemplates,
    templateActions.ZEBRA_FILTERED.request,
    params,
  );
  const zebraFilteredTemplatesFileDataList: TemplateType[] | null =
    zebraFilteredTemplatesData?.fileDataList;

  const [categories, categoriesLoading, categoriesError] = useFetchedData(
    filteredCategories,
    categoriesActions.FILTERED.request,
    '',
  );

  const searchCategories = useReduxSelector(getCategoriesSuggestions);

  const searchLibraryLoading =
    zebraFilteredTemplatesLoading || categoriesLoading;

  if (categoriesError) return <ErrorComponent />;
  return (
    <>
      {!selectedCategory && categoriesLoading ? (
        <>
          <Loader visible={true} />
        </>
      ) : (
        <>
          <Flex m={0} flexDirection={'column'}>
            {!selectedCategory && (
              <Text
                fontSize={24}
                mx={30}
                mb={24}
                lineHeight={'32px'}
                letterSpacing={0.4}
                fontWeight={300}
              >
                {t('templates:browse-categories')}
              </Text>
            )}

            <TemplatesWrap
              flexDirection={'column'}
              justifyContent={'start'}
              className="library-content"
            >
              {!selectedCategory &&
                (searchLibrary ? (
                  searchLibraryLoading ? (
                    <Loader visible={true} />
                  ) : (
                    <>
                      <RenderResults title={'Categories'} isSearching={true}>
                        {searchCategories &&
                          getCategoriesJSX(
                            searchCategories,
                            setSelectedCategory,
                            others,
                          )}
                      </RenderResults>
                      <RenderResults title={'Designs'} isSearching={true}>
                        {zebraFilteredTemplatesFileDataList &&
                          getTemplatesJSX(
                            zebraFilteredTemplatesFileDataList,
                            unit,
                          )}
                      </RenderResults>
                    </>
                  )
                ) : categoriesLoading ? (
                  <Loader visible={true} />
                ) : (
                  <RenderResults>
                    {categories &&
                      getCategoriesJSX(categories, setSelectedCategory, others)}
                  </RenderResults>
                ))}
              {selectedCategory &&
                zebraFilteredTemplatesError &&
                !isObjectEmpty(zebraFilteredTemplatesError) && (
                  <ErrorComponent />
                )}
              {selectedCategory &&
                (zebraFilteredTemplatesLoading ? (
                  <Loader visible={true} />
                ) : (
                  <>
                    <RenderResults>
                      {zebraFilteredTemplatesFileDataList &&
                        getTemplatesJSX(
                          zebraFilteredTemplatesFileDataList,
                          unit,
                        )}
                    </RenderResults>
                  </>
                ))}
            </TemplatesWrap>
          </Flex>
        </>
      )}
    </>
  );
};

export default Library;
