import React, { useState } from 'react';
import styled from 'styled-components';

const TooltipWrapper = styled.div`
  position: relative;
  margin-bottom: 0px;
  width: 64px;
  overflow: visible;
`;
const TooltipTip = styled.div`
  border-radius: 4px;
  text-align: center;
  padding: 6px;
  color: white !important;
  background: black;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
  z-index: 100;
  margin-bottom: 0px;
  opacity: 0.8;

  &:before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 6px;
    margin-left: calc(6px * -1);
  }
`;

const TooltipTipBottom = styled(TooltipTip)<{ marginLeft?: number }>`
  position: relative;
  overflow: visible;
  width: fit-content;
  margin-left: ${(props) =>
    props.marginLeft ? `${props.marginLeft}px` : '0px'};

  &:before {
    bottom: 100%;
    border-bottom-color: black;
  }
`;

const TooltipBottom = ({ enabled, direction, mt, ...props }: any) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    if (enabled) {
      timeout = setTimeout(() => {
        setActive(true);
      }, props.delay || 300);
    }
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <TooltipWrapper
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {props.children}
      {active && (
        <TooltipTipBottom marginLeft={props.marginLeft}>
          {props.content}
        </TooltipTipBottom>
      )}
    </TooltipWrapper>
  );
};

export default TooltipBottom;
