import React, { useState, useRef, ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import styled, { ThemeContext } from 'styled-components';

import { supportedImageTypes } from 'services/api';
import { useAppDispatch, useReduxAction, useReduxSelector } from 'hooks';
import { Input, Flex, Button, Box } from 'components';
import { Text, Radio, RadioGroup } from 'v2/components/common';
import { actions as workspaceActions } from 'ducks/workspaces/actions';
import { getCurrentWorkspace, isUpdating } from 'ducks/workspaces/selectors';
import { getThemes } from 'utils/themes';
import { handleUploadedImage } from 'utils/imageCompress';
import getTextAbbreviation from 'utils/getTextAbbreviation';
import { openErrorToast } from 'state/Toast';

import {
  ButtonsContainer,
  PreviewWrapper,
  Section,
  SectionTitle,
  SideText,
  StyledImg,
  ThemeExample,
  ThemePicture,
  ThemeWrapper,
  Title,
  Subtitle,
  UploadInput,
} from './EditWorkspace.styles';
import { getAssetPath } from 'utils/files';

const StyledText = styled(Text).attrs(() => ({
  letterSpacing: '0.4',
  fontWeight: '300',
}))`
  width: 80px;
  color: ${({ theme }) => theme.primary.base};
  text-align: center;
  overflow: hidden;
`;

const EditWorkspace = () => {
  const dispatch = useAppDispatch();
  const theme = useContext(ThemeContext);

  const { t } = useTranslation(['overview', 'common', 'settings']);
  const NOT_ALLOWED_CHARACTERS = '/[^%&*?+[\\]:/<>#]*';
  const InputUploadRef = useRef<any>(null);

  const workspace = useReduxSelector(getCurrentWorkspace);
  const updateLoading = useReduxSelector(isUpdating);
  const themes = getThemes();

  const [workspaceName, setWorkspaceName] = useState(workspace.name);
  const [workspaceAvatar, setWorkspaceAvatar] = useState(workspace.avatar);
  const [selectedTheme, setSelectedTheme] = useState(workspace.theme);

  const [url, setUrl] = useState<string | undefined>(
    workspace?.avatar && workspace?.avatar !== 'null'
      ? `data:image/jpeg;base64, ` + workspace?.avatar
      : undefined,
  );

  const updatedWorkspaceFunc = useReduxAction(workspaceActions.UPDATE.request);

  const handleChange = () => {
    updatedWorkspaceFunc({
      ...workspace,
      avatar: workspaceAvatar,
      name: workspaceName,
      theme: selectedTheme,
    });
  };

  const removeImage = () => {
    setUrl(undefined);
    setWorkspaceAvatar('null');
  };

  const cancel = () => {
    setWorkspaceName(workspace.name);
    setUrl(
      workspace?.avatar && workspace?.avatar !== 'null'
        ? `data:image/jpeg;base64, ` + workspace?.avatar
        : undefined,
    );
    setWorkspaceAvatar(workspace.avatar);
    setSelectedTheme(workspace.theme);
  };

  const handleOnChangeWorkspaceName = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setWorkspaceName(value);
  };

  const isChanged = () =>
    workspaceName !== workspace?.name ||
    workspaceAvatar !== workspace?.avatar ||
    selectedTheme !== workspace?.theme;

  const handleUploadInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const fileObject = e.target.files && e.target.files[0];
    const options = {
      maxWidthOrHeight: 120,
      useWebWorker: true,
    };

    if (!fileObject) return;

    try {
      const avatar = await handleUploadedImage(fileObject, options);

      setWorkspaceAvatar(avatar);
      setUrl(`data:image/jpeg;base64, ${avatar}`);
    } catch (error: any) {
      console.error(error);

      const message = error.message;

      if (!message) {
        dispatch(
          openErrorToast({
            title: t('common:errors.corrupted-file'),
            dismissTime: 0,
          }),
        );
        
      }
      else {
        dispatch(
          openErrorToast({
            title: message,
          }),
        );
      }
      
    } finally {
      InputUploadRef.current.value = null;
    }
  };

  const hasError = (): string | null => {
    if (workspaceName.length === 0) {
      return t('settings:workspace-name-length-at-least-one-character');
    } else if (workspaceName.length >= 256) {
      return t('settings:workspace-name-can-not-exceed-length');
    } else if (
      workspaceName.match(NOT_ALLOWED_CHARACTERS) ||
      workspaceName.match(/^\s+|\s+$/g)
    ) {
      return t('settings:workspace-name-characters-not-allowed');
    }

    return null;
  };

  return (
    <Flex flexDirection={'column'} justifyContent={'space-between'}>
      <Flex pb={40} width={'95%'} flexDirection={'column'}>
        <Title variant={'h2'} mb={4}>
          {t('settings:workspace-settings')}
        </Title>
        <Subtitle mb={8} mt={12}>
          {t('settings:edit-workspace')}
        </Subtitle>
        <Section>
          <SectionTitle>{t('overview:workspace.avatar')}</SectionTitle>
          <Flex width={'90%'} flexDirection={'row'}>
            <PreviewWrapper>
              {url ? (
                <StyledImg
                  data-testid="edit-workspace-avatar-image"
                  alt={t('overview:workspace.avatar')}
                  title={t('overview:workspace.avatar')}
                  src={url}
                />
              ) : (
                <StyledText
                  data-testid="edit-workspace-name-initials"
                  variant={'h1'}
                  padding={'4px'}
                >
                  {getTextAbbreviation(workspaceName ?? '')}
                </StyledText>
              )}
            </PreviewWrapper>
            <SideText data-testid="edit-workspace-side-text">
              {!url && t('overview:workspace.no-image-uploaded')}
            </SideText>
          </Flex>
          <Flex flexDirection="row" mr={8}>
            <UploadInput
              data-testid={'upload-input'}
              ref={InputUploadRef}
              onChange={handleUploadInputChange}
              accept={supportedImageTypes.toString()}
            />
            <Button
              data-testid="edit-workspace-upload-photo-button"
              variant={'outline'}
              onClick={() => InputUploadRef?.current?.click()}
            >
              {t('common:upload-photo')}
            </Button>
            {url && (
              <>
                <Box width={theme.spacing.buttonGap} />
                <Button
                  data-testid="edit-workspace-remove-image-button"
                  variant={'text-destructive'}
                  onClick={removeImage}
                >
                  {t('common:remove-image')}
                </Button>
              </>
            )}
          </Flex>
        </Section>
        <Section width={336}>
          <SectionTitle>{t('overview:workspace.name')}</SectionTitle>
          <Input
            data-testid="input-workspace-name"
            value={workspaceName}
            hasError={hasError() !== null}
            message={hasError()}
            onChange={handleOnChangeWorkspaceName}
          />
        </Section>
        <Subtitle mb={8} mt={10}>
          {t('settings:change-theme')}
        </Subtitle>
        <ThemeWrapper data-testid="change-theme-wrapper">
          <RadioGroup
            name={'themes'}
            value={selectedTheme}
            onChange={setSelectedTheme}
          >
            {themes.map(({ id, name }) => (
              <Box key={id}>
                <Radio
                  data-testid="change-theme-option-radio-button"
                  value={id}
                  label={name}
                />

                <ThemeExample>
                  <ThemePicture>
                    <source
                      srcSet={getAssetPath(`/themes/${id}_header.webp`)}
                      type="image/webp"
                    />
                    <img
                      src={getAssetPath(`/themes/${id}_header.jpg`)}
                      alt={t('overview:theme.sidebar-theme', {
                        name,
                      })}
                    />
                  </ThemePicture>
                </ThemeExample>
              </Box>
            ))}
          </RadioGroup>
        </ThemeWrapper>
      </Flex>
      <ButtonsContainer>
        <Button
          data-testid="edit-workspace-cancel-button"
          onClick={() => cancel()}
          variant={'text-primary'}
        >
          {t('common:cancel')}
        </Button>
        <Button
          data-testid="edit-workspace-save-button"
          ml={theme.spacing.buttonGap}
          disabled={updateLoading || !isChanged() || hasError() !== null}
          variant={'primary'}
          onClick={handleChange}
        >
          {updateLoading ? t('common:saving') : t('common:save')}
        </Button>
      </ButtonsContainer>
    </Flex>
  );
};

export default EditWorkspace;
