import React, { useState, useRef, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Box, Text, Icon, Flex, Button, Tooltip } from 'components';

import SalesForceChat from '../../../pages/help/components/snapin';
import { CustomerInfoType } from 'zsbpsdk/src/customer';
import { useReduxSelector, useReduxAction } from 'hooks';
import { getCustomer, getUser } from 'ducks/auth/selectors';
import { getAllPrinters } from 'ducks/printers/selectors';
import { isSidebarCollapsed } from 'ducks/sideBar/selectors';
import { actions as SideBar } from 'ducks/sideBar/actions';
import { useTranslation } from 'react-i18next';
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from 'utils/localStorageItemConverter';
import { DEFAULT_USER_COUNTRY_CODE } from 'utils/config';
import {
  ButtonContainer,
  ContractIcon,
  ExpandedContainer,
  ExpandIcon,
  IconContainer,
  InnerContainer,
  Link,
  ListWrap,
  MainSection,
  MenuItemsSection,
  TextContainer,
} from './ExpandableHelpList.styles';

const ExpandableHelpList = ({ setHelpOpen, collapsedState, showToolTip }) => {
  const { t } = useTranslation('components');

  const theme = useContext(ThemeContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const sideBarCollapsedState = useReduxSelector(isSidebarCollapsed);
  const setReduxCollapsed = useReduxAction(SideBar.COLLAPSED);

  const user = useReduxSelector(getUser);
  const customer: CustomerInfoType = useReduxSelector(getCustomer);

  const onlineButton = useRef(null);
  const offlineButton = useRef(null);
  const loadingButton = useRef(null);

  const [activatedChat, setActivatedChat] = useState(false);
  const [chatIsLoading, setChatIsloading] = useState(false);
  const printers = useReduxSelector(getAllPrinters);
  const userFirstName = user ? user.firstName : customer.firstName;
  const userLastName = user ? user.lastName : ''; //Todo when availble in user object
  const userEmail = customer.email;
  const userId = user ? user.zuid : customer.id;
  const userCountry = user ? user.country : DEFAULT_USER_COUNTRY_CODE;
  let userPrintersList = '';
  const [chatScriptsLoaded, setChatScriptsLoaded] = useState(false);

  const getPrintersList = () => {
    printers &&
      printers.forEach((printer, index) => {
        if (index === 0) {
          userPrintersList = printer.name;
        } else {
          userPrintersList += `, ${printer.name}`;
        }
      });
  };

  const userLocalStorageVariable = () => {
    return 'userOpenedChatWindow_' + customer.id;
  };

  const getUserOpenedChat = () => {
    let userOpenedChatWindow = getFromLocalStorage(userLocalStorageVariable());
    return userOpenedChatWindow !== null && userOpenedChatWindow === 'true';
  };

  const SalesForceChatSetup = () => {
    SalesForceChat.setup(
      onlineButton.current,
      offlineButton.current,
      loadingButton.current,
      scriptsLoadedCallback,
    );
    getPrintersList();
    SalesForceChat.getUserInfo(
      userFirstName,
      userEmail,
      userId,
      userLastName,
      userCountry,
      userPrintersList,
    );
    window.addEventListener('chatEvent', handleChatEvent);

    return () => {
      SalesForceChat.teardown();
      window.removeEventListener('chatStarted', handleChatEvent);
    };
  };

  const scriptsLoadedCallback = () => {
    setChatScriptsLoaded(true);
  };

  useEffect(() => {
    setHelpOpen(isExpanded);
  }, [isExpanded, setHelpOpen]);

  useEffect(() => {
    if (sideBarCollapsedState === true) {
      setIsExpanded(false);
    }
  }, [sideBarCollapsedState]);

  useEffect(() => {
    if (getUserOpenedChat()) {
      handleHelpExpand();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chatScriptsLoaded === true) {
      if (getUserOpenedChat()) handleChatRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatScriptsLoaded]);

  const handleHelpExpand = () => {
    if (isExpanded === false) {
      SalesForceChatSetup();
    }
    if (sideBarCollapsedState === true) {
      setReduxCollapsed(false);
      if (isExpanded === false) {
        SalesForceChatSetup();
      }
      setTimeout(function () {
        setIsExpanded(true);
      }, 300);
    } else if (sideBarCollapsedState === false) {
      setIsExpanded(!isExpanded);
    }
  };

  const handleChatRequest = () => {
    setChatIsloading(true);
    SalesForceChat.activateChat();

    setTimeout(function () {
      setChatIsloading(false);
    }, 3000);
  };

  const handleChatEvent = (event) => {
    setActivatedChat(event.detail.isChatActive);

    if (event.detail.isChatActive === false) {
      saveToLocalStorage(userLocalStorageVariable(), false);
    }
  };

  // TODO: improve above, currently a work aroud
  return (
    <Box mb={2}>
      <ListWrap sideBarCollapsedState={sideBarCollapsedState}>
        <MainSection
          data-testid="expand-help-button"
          sideBarCollapsedState={sideBarCollapsedState}
          onClick={handleHelpExpand}
        >
          <Tooltip
            enabled={collapsedState && showToolTip}
            content={t('components:menu.help')}
            direction="right"
            mt={'20px'}
          >
            <IconContainer sideBarCollapsedState={sideBarCollapsedState}>
              <Icon icon="support" size={'24'} color={theme.textColors.med} />
            </IconContainer>
          </Tooltip>

          {sideBarCollapsedState === false && (
            <TextContainer sideBarCollapsedState={sideBarCollapsedState}>
              <Text
                fontSize={16}
                lineHeight={'24px'}
                fontWeight={'400'}
                letterSpacing={0.4}
                color={theme.textColors.med}
              >
                {t('components:menu.help')}
              </Text>
            </TextContainer>
          )}

          {isExpanded && sideBarCollapsedState === false ? (
            <ContractIcon />
          ) : sideBarCollapsedState === false && !isExpanded ? (
            <ExpandIcon />
          ) : (
            ''
          )}
        </MainSection>
        <ExpandedContainer
          expanded={isExpanded}
          sideBarCollapsedState={sideBarCollapsedState}
        >
          <InnerContainer expanded={isExpanded}>
            <MenuItemsSection>
              <Flex justifyContent="flex-start" flexDirection="column">
                <Link
                  //TODO change to GetLinks
                  data-testid="help-support-link"
                  href={'https://zsbsupport.zebra.com/s/'}
                  target="_blank"
                  rel="noopener"
                >
                  <Button
                    variant={'text-primary'}
                    size={'sm'}
                    icon={'left'}
                    iconName={'helpMenuItem'}
                    iconColor={theme.primary.base}
                    multiLine={true}
                    style={{
                      height: 'auto',
                      paddingTop: '16px',
                      paddingBottom: '8px',
                    }}
                  >
                    <span
                      data-testid="help-support-link-text"
                    >
                      {`${t('components:menu.support')}`}
                    </span>
                  </Button>
                </Link>
                <Link
                  //TODO change to GetLinks
                  data-testid="help-faqs-link"
                  href={'https://zsbsupport.zebra.com/s/faqs'}
                  target="_blank"
                  rel="noopener"
                >
                  <Button
                    variant={'text-primary'}
                    size={'sm'}
                    icon={'left'}
                    iconName={'helpMenuItem'}
                    iconColor={theme.primary.base}
                    multiLine={true}
                    style={{
                      height: 'auto',
                      paddingTop: '8px',
                      paddingBottom: '8px',
                    }}
                  >
                    <span
                      data-testid="help-faqs-link-text"
                    >
                      {`${t('components:menu.faqs')}`}
                    </span>
                  </Button>
                </Link>
                <Link
                  //TODO change to GetLinks
                  data-testid="help-contact-us-link"
                  href={'https://zsbsupport.zebra.com/s/contactsupport'}
                  target="_blank"
                  rel="noopener"
                >
                  <Button
                    variant={'text-primary'}
                    size={'sm'}
                    icon={'left'}
                    iconName={'helpMenuItem'}
                    iconColor={theme.primary.base}
                    multiLine={true}
                    style={{
                      height: 'auto',
                      paddingTop: '8px',
                      paddingBottom: '16px',
                    }}
                  >
                    <span
                      data-testid="help-contact-us-link-text"
                    >
                      {`${t('components:menu.contact-us')}`}
                    </span>
                  </Button>
                </Link>
              </Flex>
            </MenuItemsSection>
            <ButtonContainer>
              <Button
                data-testid="live-chat-online-button"
                ref={onlineButton}
                style={{ display: 'none' }}
                variant={'primary'}
                icon={'left'}
                iconName={'person'}
                iconColor={theme.white}
                onClick={() => {
                  handleChatRequest();
                  saveToLocalStorage(userLocalStorageVariable(), true);
                }}
                disabled={activatedChat}
                width={'182px'}
                isLoading={chatIsLoading}
              >
                {`${t('components:menu.live-chat')}`}
              </Button>
              <Button
                data-testid="live-chat-loading-button"
                ref={loadingButton}
                variant={'primary'}
                icon={'left'}
                iconName={'person'}
                iconColor={theme.white}
                width={'182px'}
                isLoading={true}
              >
                {`${t('components:menu.live-chat')}`}
              </Button>
              <Button
                data-testid="live-chat-offline-button"
                ref={offlineButton}
                style={{ display: 'none' }}
                variant={'primary'}
                icon={'left'}
                iconName={'person'}
                iconColor={theme.white}
                onClick={SalesForceChat.activateChat}
                disabled={true}
                width={'182px'}
              >
                {`${t('components:menu.chat-offline')}`}
              </Button>
            </ButtonContainer>
          </InnerContainer>
        </ExpandedContainer>
      </ListWrap>
    </Box>
  );
};

export default ExpandableHelpList;
