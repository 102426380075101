import React from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';
import { Text } from 'components';

const StyledItem = styled.div`
  padding: 12px 16px;
  width: calc(100% - 32px);
  transition: background-color 0.3s;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background-color: ${({ theme }) => theme.primary.lightest};
  }
`;

const OptionsMenuItem = ({ children, onClick, danger, ...props }: any) => {
  const theme = React.useContext(ThemeContext);
  return (
    <StyledItem {...props} onClick={onClick}>
      <Text
        display={'inline'}
        fontSize={14}
        lineHeight={'21px'}
        letterSpacing={0.4}
        color={danger ? theme.error.base : theme.textColors.high}
      >
        {children}
      </Text>
    </StyledItem>
  );
};

export default OptionsMenuItem;
