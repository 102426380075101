import styled from 'styled-components';
import { Box, Icon, Flex } from 'components';
import { device } from 'utils/mediaQueries';

export const ListWrap: any = styled(Box)<any>`
  position: relative;
  border-radius: 8px;
  width: ${({ sideBarCollapsedState }) =>
    sideBarCollapsedState ? '50px' : '208px'};
  margin-bottom: ${({ theme }) => `${theme.space[11]}px`};
  background-color: ${({ theme }: any) => theme.white};
  ${({ sideBarCollapsedState }) =>
    sideBarCollapsedState === true ? '' : 'border: 1px solid #c9cdd4'};

  transition: all 0.6s ease-out !important;

  @media ${device.mobileToTablet} {
    width: 100%;
  }

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const MainSection: any = styled(Flex)<any>`
  padding: ${({ sideBarCollapsedState }) =>
    sideBarCollapsedState === true ? '6px 0px 6px' : '14px 11px 11px 14px'};
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;

  transition: all 0.6s ease 0s;
`;

export const MenuItemsSection: any = styled(Flex).attrs({
  pt: 0,
  pr: 12,
  pb: 0,
  pl: 3,
})`
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.neutral.lightest};
`;

export const ButtonContainer: any = styled(Flex).attrs({
  pt: 6,
  pr: 6,
  pb: 6,
  pl: 6,
})`
  border-top: 1px solid ${({ theme }) => theme.neutral.lightest};
  flex-direction: column;
`;

export const IconContainer: any = styled(Box)<any>`
  margin-left: ${({ sideBarCollapsedState }) =>
    sideBarCollapsedState ? '0' : '3px'};

  position: absolute;

  top: 50%;
  transform: ${({ sideBarCollapsedState }) =>
    sideBarCollapsedState ? 'translateX(-24%)' : 'translateY(-50%)'};
  border-radius: 8px;
  padding: ${({ sideBarCollapsedState }) =>
    sideBarCollapsedState ? '8px' : '0px'};

  &:hover {
    ${({ sideBarCollapsedState, theme }) =>
      sideBarCollapsedState
        ? `background-color: ${theme.primary.lightest}`
        : ''};

    svg path {
      ${({ sideBarCollapsedState, theme }) =>
        sideBarCollapsedState === true
          ? `fill: ${theme.primary.base} !important`
          : ''};
    }
  }
`;

export const ExpandIcon: any = styled(Icon).attrs(({ theme }: any) => ({
  icon: 'chevron-down',
  color: theme.neutral.base,
  size: '10px',
}))`
  margin-left: auto;
  margin-right: 9px;
`;

export const ContractIcon: any = styled(Icon).attrs(({ theme }: any) => ({
  icon: 'chevron-down',
  color: theme.primary.base,
  size: '10px',
}))`
  margin-left: auto;
  margin-right: 9px;
  transform: rotate(180deg);
`;

export const TextContainer = styled(Box)<any>`
  width: 67%;
  margin-left: 5px;
`;

export const ExpandedContainer = styled.div<any>`
  max-height: ${({ expanded }) => (expanded === true ? '300px' : '0')};
  transition: max-height 0.6s ease 0s;
  overflow: hidden;
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const InnerContainer = styled.div<any>``;
