import React, { useCallback, useRef } from 'react';
import { Box, Button, Flex } from 'components';
import styled, { ThemeContext } from 'styled-components';
import { device } from 'utils/mediaQueries';
import { useModal, useReduxAction } from 'hooks';
import { push } from 'connected-react-router';
import NewLabelModal from './NewLabelModal';
import { COMMON_DESIGNS_PATH } from '../../../utils/config';
import { useTranslation } from 'react-i18next';
import { actions as labelSizesActions } from 'ducks/labelSizes/actions';

const StyledFlex = styled(Flex)`
  flex-direction: row;
  align-items: center;
  @media ${device.mobile} {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    button {
      justify-content: center;
      width: 100%;
    }
  }
`;

const StyledBox = styled(Box)`
  @media ${device.mobile} {
    margin: 16px 0 0 0;
  }
`;

export const DesignsButtons = () => {
  const theme = React.useContext(ThemeContext);
  const pushAction = useReduxAction(push);
  const getAllLabelSizes = useReduxAction(labelSizesActions.ALL.request);
  const newLabelModalRef = useRef(null);
  const { open: openNewLabelModal } = useModal(newLabelModalRef);

  const openNewLabelModalHandler = useCallback(() => {
    getAllLabelSizes();
    openNewLabelModal();
  }, [openNewLabelModal, getAllLabelSizes]);

  const { t } = useTranslation('templates');

  return (
    <StyledFlex>
      <StyledBox mr={6}>
        <Button
          icon={'left'}
          iconName={'common-designs'}
          iconColor={theme.white}
          variant={'primary'}
          onClick={() => {
            pushAction(COMMON_DESIGNS_PATH);
          }}
          iconSize={16}
          className="workspace-button"
        >
          {t('templates:common-designs')}
        </Button>
      </StyledBox>
      <StyledBox ml={6}>
        <Button
          icon={'left'}
          iconName={'add'}
          iconColor={theme.white}
          variant={'primary'}
          onClick={openNewLabelModalHandler}
          iconSize={16}
          className="workspace-button"
        >
          {t('templates:create-new-design')}
        </Button>
      </StyledBox>
      <NewLabelModal ref={newLabelModalRef} />
    </StyledFlex>
  );
};
