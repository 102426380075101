import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { Flex } from 'components';
import { device } from 'utils/mediaQueries';

const StickyWrap = styled.div`
  position: sticky;
  top: 44px;
  background-color: ${({ theme }) => theme.primary.background};
  z-index: 5;
`;

const HeaderWrap: any = styled(Flex).attrs({
  flexDirection: 'column',
})`
  top: -1px;
  z-index: 9; // 1 beneath SideNav too show shadow
  transition: box-shadow 0.3s;
  width: 100%;
  max-width: 100%;
  ${({ isSticky, theme }: any) =>
    isSticky &&
    `
    background-color: ${theme.primary.background};

    &::before {
      position: absolute;
      top: 0;
      left: -100px;
      width: calc(100vw + 100px);
      content: '';
      height: 100%;
      background-color: ${theme.primary.background};
      transition: box-shadow 0.3s;
      box-shadow: 0px 20px 16px -16px rgba(73, 80, 94, 0.16);
    }
  `}

  @media ${device.desktop} {
    min-width: 736px;
  }
`;

const InnerWrap: any = styled(Flex).attrs({ flexDirection: 'column' })`
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;

  @media ${device.mobile} {
    padding-bottom: 10px;
  }
`;

type StickyHeaderProps = {
  children?: React.ReactNode;
};

export const StickyHeader = ({ children }: StickyHeaderProps) => {
  const [isSticky, setIsSticky] = useState(false);
  const InnerWrapRef = useRef(null);

  const handleScroll = useCallback(() => {
    const position = window.pageYOffset;
    setIsSticky(position > 300);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return function () {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StickyWrap data-testid="sticky-header">
      <HeaderWrap isSticky={isSticky}>
        <InnerWrap ref={InnerWrapRef}>{children}</InnerWrap>
      </HeaderWrap>
    </StickyWrap>
  );
};

export default StickyHeader;
