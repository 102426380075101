import styled from 'styled-components';
import { Box, Flex, Icon } from '../../../index';

export const Heading = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 1px ${({ theme }) => theme.neutral.lighter};
  width: 100%;
`;

export const HeadingTextContainer = styled.div`
  display: flex;
  max-width: 95%;
`;

export const SizeWarningBox = styled.div`
  width: calc(100% - 100px);
  color: ${({ theme }) => theme.textColors.high};
  background-color: ${({ theme }) => theme.warning.background};
  border-radius: 8px;
  margin-top: 16px;
`;

export const Footer = styled(Box)`
  border-top: solid 1px ${({ theme }) => theme.neutral.lighter};
`;

export const CloseIcon = styled(Icon).attrs(({ theme }) => ({
  icon: 'close',
  color: theme.neutral.base,
  size: 14,
}))``;

export const CloseButton = styled(Box)`
  cursor: pointer;
  font-size: 0;
  border: 0 none;
  background: transparent;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const ImageWrap = styled(Box)`
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.primary.lighter};
`;

export const ArrowButton = styled(Flex)<{
  isFirst: boolean;
  isLast: boolean;
  isDisabled: boolean;
}>`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  cursor: pointer;
  visibility: ${(props) =>
    props.isFirst || props.isLast ? 'hidden' : 'visible'};
  ${(props) => props.isDisabled && 'pointer-events: none; opacity: .5;'};

  background-color: ${(props) =>
    props.isFirst || props.isLast
      ? props.theme.neutral.background
      : props.theme.primary.lightest};
`;

export const StyledImage = styled.img`
  /* relative parent container - children will use parents 100% */
  max-width: 100%;
  max-height: 100%;
`;
export const Err = styled.div<{ fontSize?: number }>`
  max-width: 100%;
  padding: 8px;
  color: ${({ theme }) => theme.error.base};
  white-space: pre-wrap;
  text-align: center;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '14px')};
  line-height: 1.3;
  letter-spacing: 0.4px;
  overflow-wrap: break-word;
`;

export const StyledFlex = styled(Flex)`
  overflow-y: auto;
  // To avoid cutting the dropdown menu
  min-width: 320px;
`;
