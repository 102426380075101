import React, { useEffect, useState } from 'react';
import PrintDialogDataMappingStep from 'components/app/print-dialog/steps/PrintDialogDataMappingStep';
import PrintDialogPreviewStep from 'components/app/print-dialog/steps/PrintDialogPreviewStep';
import PrintDialogLabelRangeStep from 'components/app/print-dialog/steps/PrintDialogLabelRangeStep';
import { getPrintDialogStatus } from 'ducks/printDialog/selectors';
import {
  getIsLoadingSelectedData,
  getSelectedDatasource,
} from 'ducks/templates/selectors';
import {
  getSelectedFile,
  isLoadingSelectedDataSource,
} from 'ducks/dataSources/selectors';
import { actions as printDialogActions } from 'ducks/printDialog/actions';
import { actions as dataSourcesAction } from 'ducks/dataSources/actions';
import { actions as templatesAction } from 'ducks/templates/actions';
import { useReduxAction, useReduxSelector } from 'hooks';
import { Box, Button, Card, Flex, Loader, Text } from 'components/index';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PrintDialogProvider, {
  PrintDialogStep,
  usePrintDialog,
} from './PrintDialogContext';
import { useLabelRange } from './LabelRangeContext';

const BoxWrapper = styled(Flex)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(255, 255, 255, 0.3);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1000;
`;

const StyledCard = styled(Card)`
  // max-height: calc(100vh - 32px);
  max-height: calc(100vh - 14px);
  max-width: 1112px;

  @media screen and (max-width: 1200px) {
    max-width: calc(100vw - 16px);
  }
  flex-direction: column;
  align-items: stretch;
  overflow: visible;
`;

let isFirstTime = true;
let missingDatasourceFileName = '';

const getCurrentStep = (step: PrintDialogStep, onNext, onCancel, onBack) => {
  switch (step) {
    case PrintDialogStep.Error:
      return <PrintOptionsError />;
    case PrintDialogStep.DataMapping:
      return (
        <PrintDialogDataMappingStep
          onNext={onNext}
          onCancel={onCancel}
          missingDataSource={missingDatasourceFileName}
        />
      );
    case PrintDialogStep.Preview:
      return <PrintDialogPreviewStep onNext={onNext} onCancel={onCancel} />;
    case PrintDialogStep.LabelRange:
      return <PrintDialogLabelRangeStep onBack={onBack} />;
  }
};

const PrintDialogContent = () => {
  const [{ currentStep }, { reset: resetPrintDialog, setCurrentStep }] =
    usePrintDialog();
  const [, { reset: resetLabelRange }] = useLabelRange();

  const [isOriginalDataSource, setOriginalDataSource] = useState(true);
  const dialogStatus = useReduxSelector(getPrintDialogStatus);

  const templateDataLoading = useReduxSelector(getIsLoadingSelectedData);
  const templateDataSources = useReduxSelector(getSelectedDatasource);

  const clearSelectedDataSource = useReduxAction(
    dataSourcesAction.SELECTED.clear,
  );
  const dataSourceLoading = useReduxSelector(isLoadingSelectedDataSource);
  const selectedDataSource = useReduxSelector(getSelectedFile);

  const closePrintDialog = useReduxAction(printDialogActions.CLOSE);
  const clearLabelPreview = useReduxAction(templatesAction.LABEL_PREVIEW.clear);
  const clearSelectedTemplate = useReduxAction(templatesAction.SELECTED.clear);
  const clearSelectedData = useReduxAction(templatesAction.SELECTED_DATA.clear);
  const clearEnteredData = useReduxAction(templatesAction.ENTERED_DATA.clear);
  const clearTemplateUpdate = useReduxAction(templatesAction.UPDATE.clear);
  const clearJobId = useReduxAction(templatesAction.PRINT.clear_id);

  useEffect(() => {
    if (templateDataLoading === false && dataSourceLoading === false) {
      isFirstTime = false;
      if (
        templateDataSources &&
        !templateDataSources?.length &&
        currentStep !== PrintDialogStep.Preview
      )
        setCurrentStep(PrintDialogStep.Preview);
      else if (templateDataSources && templateDataSources?.length) {
        missingDatasourceFileName = templateDataSources[0].fileName;
        setCurrentStep(PrintDialogStep.DataMapping);
        if (!(selectedDataSource && isOriginalDataSource))
          setOriginalDataSource(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateDataLoading, dataSourceLoading]);

  return dialogStatus.open && dialogStatus.templateFilename ? (
    <BoxWrapper alignItems="center" justifyContent="center">
      <StyledCard
        data-testid="smb-print-options-styled-card"
        elevation="three"
        p={0}
      >
        {templateDataLoading || (dataSourceLoading && isFirstTime) ? (
          <Flex justifyContent="center" alignItems="center">
            <Box width={250} height={500} m={19}>
              <Loader visible={true} />
            </Box>
          </Flex>
        ) : (
          <>
            {getCurrentStep(
              currentStep,
              () => {
                setCurrentStep(PrintDialogStep.after(currentStep));
              },
              () => {
                closePrintDialog();
                resetPrintDialog();
                resetLabelRange();
                clearSelectedDataSource();
                clearLabelPreview();
                clearSelectedTemplate();
                clearSelectedData();
                clearEnteredData();
                clearTemplateUpdate();
                clearJobId();
                setOriginalDataSource(true);
                isFirstTime = true;
              },
              () => {
                setCurrentStep(PrintDialogStep.before(currentStep));
              },
            )}
          </>
        )}
      </StyledCard>
    </BoxWrapper>
  ) : null;
};

const PrintOptionsError = () => {
  const { t } = useTranslation(['common', 'components']);

  const closePrintDialog = useReduxAction(printDialogActions.CLOSE);

  return (
    <Box width={400} height={80} m={19}>
      <Text fontSize={24} fontWeight={300}>
        {t('components:printer.messages.not-available-for-printing')}
      </Text>
      <Flex flexDirection="row" justifyContent="flex-end">
        <Button onClick={() => closePrintDialog()} variant="text-primary">
          {t('common:close')}
        </Button>
      </Flex>
    </Box>
  );
};

const PrintDialog = () => (
  <PrintDialogProvider>
    <PrintDialogContent />
  </PrintDialogProvider>
);

export default PrintDialog;
