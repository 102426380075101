import React, { forwardRef, useRef, useEffect, useMemo } from 'react';
import { setRef } from 'hooks/usePortal';

const FocusTrap = forwardRef(({ children, open }: any, ref) => {
  const ignoreNextEnforceFocus = useRef(false);
  const sentinelStart = useRef<any>(null);
  const sentinelEnd = useRef<any>(null);
  const nodeToRestore = useRef<any>(null);
  const rootRef = useRef<HTMLElement | null>(null);

  const handleRef = (refValue: Element) => {
    setRef(rootRef, refValue);
    if (ref) {
      setRef(ref, refValue);
    }
  };

  useMemo(() => {
    if (!open || typeof window === 'undefined') {
      return;
    }

    nodeToRestore.current = document.activeElement;
  }, [open]);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (rootRef.current) {
      rootRef.current.focus();
    }

    const loopFocus = (event: KeyboardEvent) => {
      // 9 = Tab

      if (event.keyCode !== 9) {
        return;
      }

      if (document.activeElement === rootRef.current) {
        ignoreNextEnforceFocus.current = true;
        if (event.shiftKey) {
          sentinelEnd.current.focus();
        } else {
          sentinelStart.current.focus();
        }
      }
    };

    const contain = () => {
      if (ignoreNextEnforceFocus.current) {
        ignoreNextEnforceFocus.current = false;
        return;
      }

      if (
        rootRef.current &&
        !rootRef.current.contains(document.activeElement)
      ) {
        rootRef.current.focus();
      }
    };

    const interval = setInterval(() => {
      contain();
    }, 50);

    document.addEventListener('focus', contain, true);
    document.addEventListener('keydown', loopFocus, true);

    return () => {
      clearInterval(interval);

      document.removeEventListener('focus', contain, true);
      document.removeEventListener('keydown', loopFocus, true);

      // In IE 11 it is possible for document.activeElement to be null resulting
      // in nodeToRestore.current being null.
      // Not all elements in IE 11 have a focus method.
      // Once IE 11 support is dropped the focus() call can be unconditional.
      if (nodeToRestore.current && nodeToRestore.current.focus) {
        nodeToRestore.current.focus();
      }
    };
  });

  return (
    <>
      <div tabIndex={0} ref={sentinelStart} />
      {React.cloneElement(children, { ref: handleRef })}
      <div tabIndex={0} ref={sentinelEnd} />
    </>
  );
});

export default FocusTrap;
