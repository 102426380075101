import React from 'react';
import styled from 'styled-components';
import { Box, Flex, Text } from 'components';
import { device } from 'utils/mediaQueries';

const TabsContainer = styled(Flex)`
  @media ${device.mobile} {
    margin-bottom: 24px;
    overflow-x: auto;
  }
  @media ${device.tablet} {
    overflow-x: auto;
  }

  flex-direction: row;
  align-items: center;
  margin-bottom: 44px;
  width: 100%;
`;

const TabControlWrap: any = styled(Box)`
  border-bottom: 2px solid
    ${({ theme, isActive }: any) =>
      isActive ? theme.primary.base : 'transparent'};
  cursor: pointer;
  min-width: 112px;
  margin-right: 28px;
  &:first-child {
    border-bottom: 2px solid
      ${({ theme, isActive, isActiveOnLoad }: any) =>
        isActive || isActiveOnLoad ? theme.primary.base : 'transparent'};
  }
`;

const TabControlText: any = styled(Text).attrs(({ isActive, theme }: any) => ({
  fontSize: 24,
  letterSpacing: 0.4,
  lineHeight: '32px',
  fontWeight: 300,
  textAlign: 'center',
  color: isActive ? theme.primary.base : theme.textColors.med,
}))``;

const Tab = ({ action, page, name, isActive, ...props }: any) => {
  return (
    <TabControlWrap
      onClick={() => action(page)}
      isActive={isActive}
      pb={2}
      {...props}
    >
      <TabControlText
        data-testid="nav-bar-tab-text"
        isActive={isActive}
      >
        {name}
      </TabControlText>
    </TabControlWrap>
  );
};

export const NavigationTabBar = ({
  pageNames,
  setPage,
  selected,
  ...props
}) => {
  return (
    <TabsContainer {...props}>
      {pageNames.map((pageName, index) => {
        return (
          <Tab
            data-testid="nav-bar-tab"
            key={`tab-${pageName}`}
            action={setPage}
            page={index}
            name={pageName}
            isActive={selected === index}
          />
        );
      })}
    </TabsContainer>
  );
};
