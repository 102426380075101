import React, { forwardRef } from 'react';
import { Box, Button, Flex, Form, Modal, Text } from 'components';
import { ThemeContext } from 'styled-components';
import { useReduxAction, useReduxSelector } from 'hooks';
import { actions as modalDialogActions } from 'ducks/modalDialog/actions';
import { useTranslation } from 'react-i18next';
import {
  isOverwriteFileModalConfirmed,
  overwriteModalDialogData,
} from '../../../ducks/modalDialog/selectors';
import { Trans } from 'react-i18next';

const OverwriteFileModal = forwardRef((open: any, ref: any) => {
  const { t } = useTranslation(['dataSources', 'common']);
  const theme = React.useContext(ThemeContext);
  const isConfirmed = useReduxSelector(isOverwriteFileModalConfirmed);
  const file = useReduxSelector(overwriteModalDialogData);
  const closeModalAction = useReduxAction(
    modalDialogActions.OVERWRITE_FILE.close,
  );
  const confirmAction = useReduxAction(
    modalDialogActions.OVERWRITE_FILE.confirm,
  );

  return (
    <Modal
      data-testid="overwrite-file-modal"
      small
      ref={ref}
      open={open}
      onClose={closeModalAction}
    >
      <Box mb={8}>
        <Text fontSize={24} fontWeight={300} color={theme.error.base}>
          {t('dataSources:overwrite-file')}
        </Text>
      </Box>
      <Trans>
        <Text
          fontSize={16}
          fontWeight={'normal'}
          lineHeight={'24px'}
          color={theme.neutral.darkest}
        >
          {t('dataSources:overwrite-file-prompt', {
            fileName: file?.name || '',
          })}
        </Text>
      </Trans>
      <Flex mt={20} justifyContent={'flex-end'}>
        <Button
          data-testid="template-cancel-button"
          onClick={closeModalAction}
          variant={'basic'}
          mr={20}
        >
          {t('common:cancel')}
        </Button>
        <Form onSubmit={confirmAction}>
          <Button
            data-testid="file-overwrite-button"
            variant={'destructive'}
            isLoading={isConfirmed}
            type={'submit'}
          >
            {isConfirmed ? t('common:replacing') : t('common:replace')}
          </Button>
        </Form>
      </Flex>
    </Modal>
  );
});

export default OverwriteFileModal;
