import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

const useReduxAction = (actionCreator: any) => {
  const dispatch = useDispatch();
  return useMemo(
    () =>
      (...args: any) =>
        dispatch(actionCreator(...args)),
    [actionCreator, dispatch],
  );
};

export default useReduxAction;
