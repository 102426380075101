import React, { useRef, useCallback } from 'react';
import { Button } from 'components';
import { actions as dataSourcesActions } from 'ducks/dataSources/actions';
import { useReduxAction } from 'hooks';
import { ThemeContext } from 'styled-components';
import { supportedTemplatetypes } from 'services/api';

export default function ImportButton({ buttonText, ...props }) {
  const theme = React.useContext(ThemeContext);
  const fileRef = useRef<HTMLInputElement>(null);
  const addFileAction = useReduxAction(dataSourcesActions.ADD.request);

  const openFileInput = useCallback(() => {
    fileRef.current?.click();
  }, [fileRef]);

  const handleFileInput = useCallback(
    (e) => {
      if (fileRef?.current?.files) {
        addFileAction({
          file: fileRef?.current?.files?.[0],
          source: 'Label',
        });
        e.target.value = '';
      }
    },
    [addFileAction],
  );
  return (
    <>
      <input
        name="uploadFile"
        type="file"
        hidden
        accept={supportedTemplatetypes.toString()}
        ref={fileRef}
        onChange={handleFileInput}
        data-testid="localFiles-uploadInput"
      />
      <Button
        {...props}
        icon={'right'}
        iconName={'upload'}
        variant={'outline'}
        iconColor={theme.neutral.base}
        iconSize={16}
        className="workspace-button"
        onClick={openFileInput}
      >
        {buttonText}
      </Button>
    </>
  );
}
