import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import { useTranslation } from 'react-i18next';

import { ThemeContext } from 'styled-components';
import { PrinterType } from 'zsbpsdk/src/printer';

import { Box, Flex, Icon, Loader, Text } from 'components/index';
import {
  useAppDispatch,
  useLockBodyScroll,
  useReduxAction,
  useReduxSelector,
} from 'hooks';
import { openErrorToast, openToast } from 'state/Toast';
import {
  getEnteredData,
  getIsLabelsPreviewFailed,
  getIsLoadingLabelPreview,
  getIsPrintError,
  getIsPrintSuccess,
  getLabelPreview,
  getSelectedDataError,
  getSettableDatafields,
  selectedTemplate,
} from 'ducks/templates/selectors';
import { actions as templatesActions } from 'ducks/templates/actions';
import PrintDialogFooter from 'components/app/print-dialog/PrintDialogFooter';
import { MODIFIABLE_DATA_FIELDS } from 'utils/templates';
import useDebounce from 'hooks/useDebounce';
import { formatPrintError } from 'utils/errorFormatter';
import { getUnit } from 'ducks/preferences/selectors';
import { convertDotUnit, convertRawUnit, getEtch } from 'utils/unitFormatter';
import { getAllPrinters, getSelectedPrinter } from 'ducks/printers/selectors';
import { actions as printersActions } from 'ducks/printers/actions';

import { DataSourceType } from '../../../../utils/dataSourcesTypes';
import { PrintManualInput } from '../input/PrintManualInput';
import { PrintCurrencyNumberInput } from '../input/PrintCurrencyNumberInput';
import { PrintDateInput } from '../input/PrintDateInput';
import { PrintPictureInput } from '../input/PrintPictureInput';
import { PrintTextInput } from '../input/PrintTextInput';
import {
  ArrowButton,
  CloseButton,
  CloseIcon,
  Err,
  Footer,
  Heading,
  HeadingTextContainer,
  ImageWrap,
  SizeWarningBox,
  StyledFlex,
  StyledImage,
} from './PrintDialogPreviewStep.styles';
import {
  getPrintSuccessMessage,
  getPrintSuccessToastVariant,
} from '../../../../utils/printers';
import { addPropertyToArrayItemAtIndex } from '../../../../utils/dataManipulationMethods';
import { usePrintDialog } from '../PrintDialogContext';
import { useLabelRange } from '../LabelRangeContext';
import { TemplateCategory } from '../../../../utils/categories';

interface IHandleChangeProps {
  e: any;
  i: number;
  name: string;
  image?: string;
  type?: string;
}

const DEFAULT_FIELD = { id: 0, chosen: false };

function withPromptName(message: string, fields: any[]) {
  const match = fields.find((field) => {
    return new RegExp(`"${field.name}"`).test(message);
  });

  if (match) {
    return message.replace(match.name, match.promptText);
  }

  return message;
}

// Convert non-"standard" number formats to "standard"
const convertCurrencyString = (value, options) => {
  return value
    ?.replaceAll(new RegExp(`[^0-9-${options?.decimalSeparator}]`, 'g'), '')
    .replaceAll(new RegExp(`[${options?.decimalSeparator}]`, 'g'), '.');
};

const convertIfCurrency = (field) => {
  const { dataSourceType, initialValue, currencyInputFormatInfo } = field;
  return dataSourceType === DataSourceType.Currency
    ? convertCurrencyString(initialValue, currencyInputFormatInfo)
    : initialValue;
};

const convertFieldsToStandardizedJson = (fields) =>
  JSON.stringify(
    fields
      ?.map((val) => ({ ...val, chosen: null }))
      .sort((a, b) => String(a.id).localeCompare(String(b.id))),
  );

const PrintDialogPreviewStep = ({ onNext, onCancel }) => {
  const { t } = useTranslation(['components', 'common']);
  const dispatch = useAppDispatch();

  const [
    { currentLabel, copies, selectedLabels },
    { setCopies, nextLabel, previousLabel },
  ] = usePrintDialog();
  const [{ labelRange, labelRangeMapping }] = useLabelRange();
  const location = useLocation();

  const [printPreviewFields, setPrintPreviewFields] = useState<any>();
  const [fields, setFields] = useState<any>([DEFAULT_FIELD]);
  const [templateError, setTemplateError] = useState<boolean>(false);
  const [fieldError, setFieldError] = useState<string[]>();
  const [warningDisplay, setWarningDisplay] = useState<boolean>(false);
  const [imagesLoading, setImagesLoading] = useState<boolean>(false);

  const theme = useContext(ThemeContext);
  const printError = useReduxSelector(getIsPrintError);
  const printSuccess = useReduxSelector(getIsPrintSuccess);
  const templateForPrinting = useReduxSelector(selectedTemplate);
  const labelPreview = useReduxSelector(getLabelPreview);
  const isLoadingPreview = useReduxSelector(getIsLoadingLabelPreview);
  const labelPreviewError = useReduxSelector(getIsLabelsPreviewFailed);
  const enteredData = useReduxSelector(getEnteredData);
  const selectedDataFields = useReduxSelector(getSettableDatafields);
  const unit = useReduxSelector(getUnit);
  const selectedDataErr = useReduxSelector(getSelectedDataError);
  const selectedPrinter = useReduxSelector(getSelectedPrinter);
  const printers = useReduxSelector(getAllPrinters);

  const [savedFieldOrder, setSavedFieldOrder] = useState<any>(
    templateForPrinting.fieldOrder,
  );

  const updateTemplateFields = useReduxAction(templatesActions.UPDATE.request);
  const requestLabelPreview = useReduxAction(
    templatesActions.LABEL_PREVIEW.request,
  );
  const clearPrinterSelected = useReduxAction(printersActions.SELECTED.clear);
  const setEnteredData = useReduxAction(templatesActions.ENTERED_DATA.set);
  const getPrinters = useReduxAction(printersActions.ALL.request);

  const debounceFields = useDebounce(fields, 500);

  const isLoadingPreviewMemo = useMemo(
    () =>
      isLoadingPreview || (labelPreview === undefined && !labelPreviewError),
    [isLoadingPreview, labelPreview, labelPreviewError],
  );

  const isCopyRangeValid = useMemo(
    () => copies > 0 && copies <= 1000,
    [copies],
  );

  const fieldsIn = useMemo(
    () =>
      selectedDataFields?.map((field) => ({
        ...field,
        mappedFieldId: labelRangeMapping ? labelRangeMapping[field.name] : null,
        initialValue: field.initialValue,
      })),
    [selectedDataFields, labelRangeMapping],
  );

  const isPrintedCounterLabel = useMemo(
    () => !!fields.find((f) => f.dataSourceType === 'Counter'),
    [fields],
  );

  useLockBodyScroll();
  useEffect(() => {
    if (fields && templateForPrinting) {
      if (convertFieldsToStandardizedJson(fields) !== printPreviewFields) {
        callPrintPreview();
      } else {
        saveFieldOrder();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceFields]);

  useEffect(() => {
    const initializedFields = fieldsIn?.map((field) => ({
      ...field,
      id: field.name,
      initialValue:
        field.dataSourceType === DataSourceType.Picture
          ? getCurrentRowValue(field.name, 'data') ?? ''
          : getCurrentRowValue(field.name) ?? convertIfCurrency(field),
      defaultValue: convertIfCurrency(field),
      dropdownDisplayValue:
        getCurrentRowValue(field.name, 'dropdownDisplayValue') ?? '',
    }));

    if (initializedFields && initializedFields.length > 0) {
      const sortedFields: any = [];

      if (savedFieldOrder) {
        savedFieldOrder.forEach((fieldName) => {
          for (let i = initializedFields.length - 1; i >= 0; i--) {
            if (initializedFields[i].name === fieldName) {
              sortedFields.push(initializedFields[i]);
              initializedFields.splice(i, 1);
              break;
            }
          }
        });
      }

      sortedFields.push(...initializedFields);
      setFields(sortedFields);
    } else {
      if (selectedDataErr) {
        setTemplateError(true);
      } else {
        setFields([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fieldsIn,
    selectedLabels,
    labelRangeMapping,
    labelRange,
    currentLabel,
    copies,
  ]);

  useEffect(() => {
    if (printError) {
      dispatch(
        openErrorToast({
          title: 'Print Failed',
          message: formatPrintError(
            printError,
            t('common:errors.too-long-to-respond'),
          ),
        }),
      );
    }
  }, [dispatch, printError, t]);

  useEffect(() => {
    if (fields && templateForPrinting) {
      callPrintPreview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copies, currentLabel]);

  useEffect(() => {
    const { status } = selectedPrinter as PrinterType;

    if (printSuccess) {
      dispatch(getPrinters());

      dispatch(
        openToast({
          variant: getPrintSuccessToastVariant(status),
          title: getPrintSuccessMessage(status),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, printSuccess]);

  useEffect(() => {
    if (typeof labelPreviewError?.message === 'string') {
      const splitErrorMessage: string[] = labelPreviewError.message.split('"');
      if (splitErrorMessage.length > 1) {
        setFieldError([splitErrorMessage[1]]);
      }
    }
  }, [labelPreviewError]);

  useEffect(() => {
    if (labelPreview && !labelPreviewError) setFieldError(undefined);
  }, [labelPreview, labelPreviewError]);

  const saveFieldOrder = () => {
    const fieldOrder = fields.map((val) => val.id);

    if (
      !savedFieldOrder ||
      JSON.stringify(savedFieldOrder) !== JSON.stringify(fieldOrder)
    ) {
      setSavedFieldOrder(fieldOrder);
      updateTemplateFields({
        template: templateForPrinting,
        metadata: {
          key: 'fieldOrder',
          value: String(fieldOrder),
        },
        location,
      });
    }
  };

  const callPrintPreview = useCallback(() => {
    if (
      fields.length === 1 &&
      JSON.stringify(fields[0]) === JSON.stringify(DEFAULT_FIELD)
    ) {
      return;
    }

    if (fields.length > 0) {
      setPrintPreviewFields(convertFieldsToStandardizedJson(fields));
    }

    requestLabelPreview({
      template: templateForPrinting,
      fields,
      currentLabel,
    });
  }, [currentLabel, fields, requestLabelPreview, templateForPrinting]);

  // To update values after printing labels with counter data source type.
  useEffect(() => {
    if (isPrintedCounterLabel) {
      callPrintPreview();
    }
  }, [callPrintPreview, isPrintedCounterLabel]);

  // If copies == 3, [1, 2, 3] becomes [1, 1, 1, 2, 2, 2, 3, 3, 3]
  const getLabelRangeForCopies = useCallback(
    (copies: number) => {
      return selectedLabels != null
        ? selectedLabels.reduce(
            (acc, cur) => acc.concat(new Array(copies).fill(cur)),
            [] as number[],
          )
        : [];
    },
    [selectedLabels],
  );

  const getOriginalRowIndex = useCallback(() => {
    const labelRange = getLabelRangeForCopies(copies);
    return labelRange.length > 0 ? labelRange[currentLabel - 1] : 0;
  }, [getLabelRangeForCopies, copies, currentLabel]);

  const getCurrentRowValue = useCallback(
    (fieldId, propertyKey?: string) => {
      try {
        const originalRowIndex = getOriginalRowIndex();
        const columnOfFieldId = labelRangeMapping?.[fieldId];
        let entered = enteredData.data?.[originalRowIndex][fieldId];
        if (propertyKey) {
          entered = entered[propertyKey];
        }
        return entered ?? labelRange[originalRowIndex][columnOfFieldId];
      } catch (e: any) {
        return null;
      }
    },
    [enteredData.data, labelRangeMapping, labelRange, getOriginalRowIndex],
  );

  const getOriginalRowValue = useCallback(
    (fieldId) => {
      try {
        const originalRowIndex = getOriginalRowIndex();
        const columnOfFieldId = labelRangeMapping?.[fieldId];
        return labelRange[originalRowIndex][columnOfFieldId];
      } catch (e: any) {
        return null;
      }
    },
    [labelRangeMapping, labelRange, getOriginalRowIndex],
  );

  const saveData = useCallback(
    (fieldId, data) => {
      try {
        setEnteredData(
          addPropertyToArrayItemAtIndex({
            array: enteredData.data ?? [{}],
            index: getOriginalRowIndex(),
            propertiesToAdd: {
              [fieldId]: data,
            },
          }),
        );
      } catch (e: any) {
        console.error('Error:', e);
      }
    },
    [setEnteredData, enteredData.data, getOriginalRowIndex],
  );

  const handleChange = useCallback(
    ({ e, i, name, image }: IHandleChangeProps) => {
      const value: string = e.target.value;

      if (
        value === '' &&
        navigator.userAgent.toLowerCase().indexOf('firefox') > -1 &&
        e.nativeEvent.data !== null
      )
        return;

      setFields(
        addPropertyToArrayItemAtIndex({
          array: fields,
          index: i,
          propertiesToAdd: {
            initialValue: (field) =>
              field.dataSourceType === DataSourceType.Image && image
                ? image
                : value,
          },
        }),
      );
      saveData(name, value);
    },
    [fields, saveData],
  );

  const getTotalLabelCount = useCallback(
    (copies: number) => {
      return (selectedLabels?.length || 1) * copies;
    },
    [selectedLabels],
  );

  const onCopiesChangeHandler = useCallback(
    (count) => {
      setCopies(count);
    },
    [setCopies],
  );

  //For Layout Styling
  useEffect(() => {
    const templateSize = [
      convertRawUnit(templateForPrinting.labelSize.width, unit),
      convertRawUnit(templateForPrinting.labelSize.height, unit),
    ]
      .sort()
      .join('|');
    const cartridgeSize = [
      convertDotUnit(selectedPrinter.cartridgeInfo?.length, unit),
      convertDotUnit(selectedPrinter.cartridgeInfo?.width, unit),
    ]
      .sort()
      .join('|');

    setWarningDisplay(templateSize !== cartridgeSize);
  }, [printers, templateForPrinting.labelSize, unit, selectedPrinter]);

  const makeWarningBox = () => (
    <SizeWarningBox>
      <Flex p={16} flexDirection="row">
        <Flex mr={'13px'} flexDirection="column">
          <Icon size="22" color={theme.warning.base} icon="warning" />
        </Flex>
        <Flex flexDirection="column">
          <Text fontWeight={400} lineHeight={'24px'} fontSize={16}>
            {t('components:printer.label-different-size-than-cartridge')}
          </Text>
          <Text fontWeight={400} lineHeight={'21px'} fontSize={14}>
            {t('components:printer.resize-label-or-insert-different-cartridge')}
          </Text>
        </Flex>
      </Flex>
    </SizeWarningBox>
  );

  const handleImageAdd = useCallback(
    (
      index: number,
      imageData: string,
      dropdownDisplayValue: string,
      currentName: string | undefined,
    ) => {
      saveData(currentName, {
        dropdownDisplayValue,
        data: imageData,
      });

      setFields(
        addPropertyToArrayItemAtIndex({
          array: fields,
          index,
          propertiesToAdd: {
            dropdownDisplayValue,
            initialValue: imageData,
          },
        }),
      );
    },
    [fields, saveData],
  );

  const getPrintInput = useCallback(
    (card, i: number): ReactElement | null => {
      if (labelRangeMapping?.manual) {
        return <PrintManualInput template={templateForPrinting} card={card} />;
      }

      switch (card.dataSourceType) {
        case DataSourceType.Currency:
        case DataSourceType.Number:
          return (
            <PrintCurrencyNumberInput
              template={templateForPrinting}
              card={card}
              handleChange={(e) => handleChange({ e, i, name: card.name })}
              fieldError={fieldError}
            />
          );

        case DataSourceType.Date:
          return (
            <PrintDateInput
              template={templateForPrinting}
              card={card}
              handleChange={(e) => handleChange({ e, i, name: card.name })}
              fieldError={fieldError}
            />
          );

        case DataSourceType.Picture:
          return (
            <PrintPictureInput
              template={templateForPrinting}
              card={card}
              onLoadingChange={(isLoading: boolean) => {
                setImagesLoading(isLoading);
              }}
              onImageAdd={(imageData: string, dropdownDisplayValue: string) => {
                handleImageAdd(i, imageData, dropdownDisplayValue, card.name);
              }}
            />
          );

        case DataSourceType.Text:
          return (
            <PrintTextInput
              template={templateForPrinting}
              card={card}
              disabled={
                getOriginalRowValue(card.name) != null &&
                card.mappedFieldId != null
              }
              handleChange={(e) => handleChange({ e, i, name: card.name })}
              fieldError={fieldError}
            />
          );

        default:
          return null;
      }
    },
    [
      templateForPrinting,
      fieldError,
      labelRangeMapping,
      handleChange,
      getOriginalRowValue,
      handleImageAdd,
    ],
  );

  const handleConditionalRender = () => {
    if (isLoadingPreviewMemo) {
      return <Loader visible={true} />;
    } else if (!isCopyRangeValid) {
      return (
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
          px={'8px'}
          py={'4px'}
          width={'100%'}
        >
          <Err fontSize={12}>
            {t('components:printer.errors.copies-not-within-range')}
          </Err>
        </Flex>
      );
    } else if (labelPreviewError) {
      return (
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
          px={'8px'}
          py={'4px'}
          width={'100%'}
        >
          <Err data-testid="label-preview-error">
            {t('components:printer.errors.unable-to-load-design-preview')}
          </Err>
          {fieldError?.length &&
            (fieldError.includes('Barcode') ||
              fieldError[0].includes('Barcode') ||
              fieldError.includes('Text') ||
              fieldError[0].includes('Text')) &&
            typeof labelPreviewError?.message === 'string' && (
              <Err fontSize={12}>
                {withPromptName(labelPreviewError?.message, fields)}
              </Err>
            )}
        </Flex>
      );
    }

    return (
      <StyledImage
        src={`data:image/jpeg;base64, ${labelPreview}`}
        alt={'label'}
      />
    );
  };

  return templateError ? (
    <>
      <Heading height={80} pl={19} pr={19} pt={14} pb={14}>
        <Text fontSize={24} fontWeight={300}>
          {t('components:printer.errors.server-error')}
        </Text>
        <CloseButton
          data-testid={'close-button'}
          as={'button'}
          onClick={() => {
            clearPrinterSelected();
            onCancel();
          }}
        >
          <CloseIcon />
        </CloseButton>
      </Heading>
      <Flex
        max-width={1112}
        flexDirection={'row'}
        justifyContent={'center'}
        p="80px"
      >
        {t('components:printer.messages.error-while-loading')}
      </Flex>
    </>
  ) : (
    <>
      <Heading pl={19} pr={19} pt={14} pb={14}>
        <HeadingTextContainer>
          <Text
            data-testid="print-options-step-heading-text"
            fontSize={24}
            fontWeight={300}
            mr={'3'}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {templateForPrinting?.name
              ? `${templateForPrinting.name} `
              : t('components:printer.messages.unknown-template')}
          </Text>
          {templateForPrinting && templateForPrinting.name && (
            <Text
              data-testid="print-options-step-heading-text-size"
              fontSize={24}
              fontWeight={300}
              whiteSpace="nowrap"
            >
              {`- ${getEtch(
                templateForPrinting.labelSize,
                unit,
                templateForPrinting.orientation,
              )}`}
            </Text>
          )}
        </HeadingTextContainer>

        <CloseButton
          data-testid={'close-button'}
          as={'button'}
          onClick={() => {
            clearPrinterSelected();
            onCancel();
          }}
        >
          <CloseIcon />
        </CloseButton>
      </Heading>

      <Flex
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems="center"
        style={{ overflow: 'auto' }}
      >
        {warningDisplay && makeWarningBox()}
        <Flex
          data-testid="print-options-step-content"
          max-width={1112}
          flexDirection={'row'}
          justifyContent={'center'}
          style={{ minHeight: 0 }}
        >
          <Flex
            data-testid="arrow-button-prev-container"
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Box m={32}>
              <ArrowButton
                data-testid="arrow-button-prev"
                isFirst={currentLabel - 1 < 1}
                isLast={false}
                alignItems="center"
                justifyContent="center"
                isDisabled={isLoadingPreviewMemo || imagesLoading}
                onClick={previousLabel}
              >
                <Icon
                  size={40}
                  color={theme.textColors.med}
                  icon={'chevron-left'}
                />
              </ArrowButton>
            </Box>
          </Flex>

          <Flex
            pt={warningDisplay ? '16px' : 40}
            pb={40}
            flexDirection={'column'}
            justifyContent={'center'}
            flex="1 1 420px"
            alignItems="center"
          >
            <ImageWrap
              data-testid="print-options-step-image-wrap"
              max-width={404}
              max-height={469}
              backgroundColor={theme.primary.lightest}
              height={
                labelPreviewError || !isCopyRangeValid
                  ? 'auto'
                  : templateForPrinting?.labelSize?.height / 226.65
              }
              width={templateForPrinting?.labelSize?.width / 226.65}
            >
              <Flex
                alignItems={'center'}
                justifyContent={'center'}
                height={'100%'}
              >
                {handleConditionalRender()}
              </Flex>
            </ImageWrap>
            <Flex
              data-testid="current-label-count"
              alignItems={'center'}
              justifyContent={'center'}
              mt={'16px'}
            >
              {t('components:printer.label-of', {
                count: currentLabel,
                total: getTotalLabelCount(copies),
              })}
            </Flex>
          </Flex>

          {/* {get length of initial value, display text area if greater than length 20, find a way to iterate rows for print preview?} */}
          {fields.filter((field) =>
            MODIFIABLE_DATA_FIELDS.includes(field.dataSourceType),
          ).length ? (
            <StyledFlex
              pl={12}
              pr={12}
              pt={warningDisplay ? '16px' : 40}
              pb={40}
              flex="1 1 420px"
            >
              <Box>
                <ReactSortable
                  list={fields}
                  setList={setFields}
                  animation={150}
                  disabled={
                    templateForPrinting?.tenant === TemplateCategory.Internal
                  }
                  key={`sortable${currentLabel}`}
                  className={`class_${currentLabel}`}
                  handle=".dragHandle"
                  forceFallback={true}
                >
                  {fields.map((card: any, i: number) => (
                    <div key={`PrintPreview${i}`}>{getPrintInput(card, i)}</div>
                  ))}
                </ReactSortable>
              </Box>
            </StyledFlex>
          ) : (
            <></>
          )}

          <Flex
            data-testid="arrow-button-next-container"
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Box m={32}>
              <ArrowButton
                data-testid="arrow-button-next"
                alignItems="center"
                justifyContent="center"
                isFirst={false}
                isLast={currentLabel + 1 > getTotalLabelCount(copies)}
                isDisabled={isLoadingPreviewMemo || imagesLoading}
                onClick={() => {
                  nextLabel(getTotalLabelCount(copies));
                }}
              >
                <Icon
                  size={40}
                  color={theme.textColors.med}
                  icon={'chevron-right'}
                />
              </ArrowButton>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Footer px={19} py={9}>
        <PrintDialogFooter
          labelPreviewError={labelPreviewError}
          template={templateForPrinting}
          fields={fields}
          onLabelRangeSelect={() => {
            onNext();
          }}
          onCopiesChange={onCopiesChangeHandler}
          loading={isLoadingPreview}
        />
      </Footer>
    </>
  );
};

export default PrintDialogPreviewStep;
