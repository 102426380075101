import styled from 'styled-components';

interface CarouselContentProps {
  translate: any;
  transition: number;
  width: number;
}

export const CarouselHolder = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  padding: 0;
`;

export const CarouselContent = styled.div<CarouselContentProps>`
  transform: translateX(-${({ translate }) => translate}%);
  transition: transform ease-out ${({ transition }) => transition}s 0.1s;
  height: 100%;
  width: ${({ width }) => width}%;
  display: flex;
`;
