import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DRIVERS_MODAL_SHOWN_MAX_COUNT } from 'utils/config';
import { useReduxAction, useReduxSelector } from 'hooks';
import { getCustomer } from 'ducks/auth/selectors';
import { Flex, Box, Card, Icon } from 'components';
import { Text, LinkButton } from 'v2/components/common';
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from 'utils/localStorageItemConverter';
import { getModalsCountKey } from 'pages/drivers/DriversModal';
import { driverDownloadClicked } from 'state/UI';

const StyledCard = styled(Card).attrs({
  elevation: 'one',
})`
  width: 525px;
  padding: ${({ theme }) => theme.px(8, 12, 8, 8)};
`;

const StyledIcon = styled(Icon).attrs(({ theme }) => ({
  size: 56,
  color: theme.textColors.med,
}))``;

type AppCardProps = {
  os: string;
  driverInfo?: any;
};

export default function DriverCard({ os, driverInfo }: AppCardProps) {
  const { t } = useTranslation();
  const customer = useReduxSelector(getCustomer);
  const browserInfo = navigator.userAgent.toLocaleLowerCase();
  const downloadDriver = useReduxAction(driverDownloadClicked);

  const convert = (os) => {
    switch (os) {
      case 'Mac': {
        return 'OS X Monterey (Version 12 and later)';
      }
      case 'Windows': {
        return 'Windows 10 (21H2 and later)';
      }
      default: {
        return os;
      }
    }
  };

  const downloadClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    downloadDriver({ os });

    if (
      getFromLocalStorage(getModalsCountKey(customer)) !== null &&
      browserInfo.indexOf(os.toLocaleLowerCase()) !== -1
    ) {
      saveToLocalStorage(
        getModalsCountKey(customer),
        DRIVERS_MODAL_SHOWN_MAX_COUNT,
      );
    }
  };

  return (
    <StyledCard>
      <Flex>
        <Box>
          <StyledIcon icon={'download'} />
        </Box>
        <Flex ml={10} flexDirection={'column'}>
          <Text variant={'h2'} data-testid={`compatible-with-${os}`}>
            {t('apps:compatible-with', { os: convert(os) })}
          </Text>
          <Text data-testid={`${os}-apps-version`}>
            {t('apps:version', { version: driverInfo?.version || '' })}
          </Text>
          <Box mt={8}>
            <LinkButton
              target="_blank"
              href={driverInfo?.url}
              onClick={downloadClicked}
              title={t('apps:driver-for', { os })}
              rel="noopener"
              data-testid={`download-button-${os}`}
            >
              {t('apps:download')}
            </LinkButton>
          </Box>
        </Flex>
      </Flex>
    </StyledCard>
  );
}
