import React, { forwardRef, useState, useEffect, useContext } from 'react';
import { Flex, Button, Modal, Box, Input, Text, Icon } from 'components';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components';
import { useReduxAction, useReduxSelector } from 'hooks';
import {
  getEmailToken,
  getEmailTokenLoading,
  getUpdateEmailSuccess,
  getUser,
  getEmailTokenError,
  getUpdateEmailLoading,
  getUpdateEmailError,
} from 'ducks/auth/selectors';
import { actions as authActions } from 'ducks/auth/actions';
import { useTranslation } from 'react-i18next';

const ModalBody = styled.div`
  width: 335px;
  padding: 0px 40px 16px;
`;

const ErrorBox = styled(Flex)`
  width: 100%;
  color: ${({ theme }) => theme.error.base};
  background-color: ${({ theme }) => theme.error.lightest};
  border-radius: 8px;
  padding: 18px;
  margin-bottom: 18px;
`;

export const EmailModal = forwardRef(({ resolve, ...props }: any, ref: any) => {
  const { t } = useTranslation(['settings', 'common']);

  const theme = useContext(ThemeContext);
  const emailToken = useReduxSelector(getEmailToken);
  const emailTokenLoading = useReduxSelector(getEmailTokenLoading);
  const emailTokenError = useReduxSelector(getEmailTokenError);
  const userUpdated = useReduxSelector(getUpdateEmailSuccess);
  const emailLoading = useReduxSelector(getUpdateEmailLoading);
  const emailUpdateError = useReduxSelector(getUpdateEmailError);
  const user = useReduxSelector(getUser);

  const requestToken = useReduxAction(authActions.EMAIL_TOKEN.request);
  const clearAPI = useReduxAction(authActions.EMAIL_TOKEN.clear);
  const updateEmail = useReduxAction(authActions.UPDATE_EMAIL.request);
  const resetEmailUpdateError = useReduxAction(authActions.UPDATE_EMAIL.clear);

  const [newEmail, setNewEmail] = useState('');
  const [userToken, setUserToken] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);

  const checkAndSubmit = React.useCallback(() => {
    updateEmail({ newEmail, userToken });
  }, [newEmail, updateEmail, userToken]);

  useEffect(() => {
    if (userUpdated) {
      resolve(true);
      ref.current.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUpdated]);

  useEffect(() => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (newEmail) {
      setIsEmailValid(regex.test(newEmail) && newEmail !== user.emailId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newEmail]);

  useEffect(() => {
    setIsTokenValid(userToken.length > 4);
  }, [userToken]);

  const handleKeyDownEmail = (event) => {
    if (event.key === 'Enter' && isEmailValid) {
      requestToken(newEmail);
    }
  };

  return (
    <Modal
      defaultPadding={false}
      ref={ref}
      title={
        !emailToken ? t('settings:change-email') : t('settings:enter-code')
      }
      onClose={() => {
        clearAPI();
        setNewEmail('');
        setUserToken('');
        resetEmailUpdateError();
      }}
      headerPadding="32px 40px 16px"
      footer={
        !userUpdated && (
          <Flex justifyContent={'flex-end'}>
            <Box mr={theme.spacing.buttonGap}>
              <Button
                variant={'text-primary'}
                onClick={() => {
                  clearAPI();
                  setNewEmail('');
                  setUserToken('');
                  ref.current.close();
                }}
              >
                {t('common:cancel')}
              </Button>
            </Box>
            <Button
              variant={'primary'}
              disabled={!emailToken ? !isEmailValid : !isTokenValid}
              isLoading={!emailToken ? emailTokenLoading : emailLoading}
              onClick={() =>
                !emailToken ? requestToken(newEmail) : checkAndSubmit()
              }
            >
              {t('common:change')}
            </Button>
          </Flex>
        )
      }
      clickOutside={false}
    >
      <ModalBody>
        <>
          {!emailToken ? (
            <>
              {emailTokenError && (
                <ErrorBox flexDirection="row">
                  <Icon
                    icon="error-filled"
                    size="20"
                    color={theme.error.base}
                  />
                  <Box width="18px" />
                  {emailTokenError.ResponseText ??
                    t('common:errors.unable-to-connect')}
                </ErrorBox>
              )}
              <Text
                fontSize={14}
                fontWeight={400}
                color={theme.textColors.high}
                letterSpacing={0.4}
                mb={4}
              >
                {t('settings:enter-new-email')}
              </Text>
              <Input
                type="email"
                value={newEmail}
                placeholder={t('settings:enter-new-email')}
                onKeyDown={handleKeyDownEmail}
                onChange={(e) => {
                  setNewEmail(e.target.value);
                }}
              />
            </>
          ) : (
            <>
              <div />
              {emailUpdateError  && (
                <ErrorBox flexDirection="row">
                  <Icon
                    icon="error-filled"
                    size="30"
                    color={theme.error.base}
                  />
                  <Box width="18px" />
                  {t('settings:invalid-email-verification-code')}
                </ErrorBox>
              )}

              <Text
                fontSize={14}
                fontWeight={400}
                color={theme.textColors.high}
                letterSpacing={0.4}
                mb={4}
              >
                {t('settings:enter-verification-code')}
              </Text>
              <Input
                type={'text'}
                value={userToken}
                onChange={(e) => {
                  setUserToken(e.target.value);
                }}
              />
            </>
          )}
        </>
      </ModalBody>
    </Modal>
  );
});

export default EmailModal;
