import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { Box, Flex, Text } from 'components/index';
import Unit, { getEtch } from 'utils/unitFormatter';
import { useTranslation } from 'react-i18next';
import { TemplateType } from 'zsbpsdk/src/templates/index';
import { formatDate } from 'utils/dateFormatter';
import {
  CHOOSE_A_TEMPLATE,
  HOME_PAGE_PATH,
  RECENTLY_PRINTED,
  MY_LABELS,
} from 'utils/config';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { TEMPLATE_ID_QUERY_PARAM } from '../../../pages/templates/components/TemplateDialogModal';
import {
  EmptyLabelPreview,
  StyledImage,
  StyledImageBackground,
  StyledTemplatesCard,
  StyledTemplatesCardWrap,
  TemplateTextBox,
  LastPrintText,
} from './TemplateCard.styles';
import { useReduxAction, useUrlParam } from '../../../hooks';
import Checkbox from '../../lib/checkbox/Checkbox';
import { StyledCardCheckbox } from './TemplateCard.styles';

export enum TemplateCardType {
  LIBRARY = 'library',
  USER = 'user',
}

export interface ITemplateCardProps extends React.DetailedHTMLProps<any, any> {
  template: TemplateType;
  unit: Unit;
  elevation: string;
  showLastPrint?: boolean;
  dateFormat?: string;
  isBulkSelectActive?: boolean;
  isSelectable?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
  onClick?: (template: TemplateType) => void;
  onCheckboxClick?: (template: TemplateType) => void;
}

const PATHS = [CHOOSE_A_TEMPLATE, MY_LABELS, RECENTLY_PRINTED];

const TemplateCard = ({
  template,
  unit,
  showLastPrint = true,
  dateFormat = 'mm/dd/yyyy',
  isBulkSelectActive = false,
  isSelectable = false,
  isSelected = false,
  disabled = false,
  onClick,
  onCheckboxClick,
  ...props
}: ITemplateCardProps) => {
  const { t } = useTranslation(['common', 'components']);

  const theme = useContext(ThemeContext);
  const location = useLocation();

  const pushAction = useReduxAction(push);

  const setTemplateId = useUrlParam(TEMPLATE_ID_QUERY_PARAM, null)[1];
  const [isCursorHovering, setIsCursorHovering] = useState<boolean>(false);
  const [smallThumbnailUrl, setSmallThumbnailUrl] = useState('');
  const [etch, setEtch] = useState(
    getEtch(template?.labelSize, unit, template.orientation),
  );

  const handleCheckboxClick = (e: React.MouseEvent<Element>) => {
    e.stopPropagation();
    onCheckboxClick && onCheckboxClick(template);
  };

  const handleCardClick = (e: React.MouseEvent<Element>) => {
    if (isBulkSelectActive) {
      handleCheckboxClick(e);
    } else {
      openTemplateDialog();
    }
  };

  const handleMouseEnter = () => {
    setIsCursorHovering(true);
  };

  const handleMouseLeave = () => {
    setIsCursorHovering(false);
  };

  useEffect(() => {
    setEtch(getEtch(template.labelSize, unit, template.orientation));
  }, [unit, template.labelSize, template.orientation]);

  const openTemplateDialog = useCallback(() => {
    if (isBulkSelectActive) return;

    if (PATHS.includes(location.pathname)) pushAction(HOME_PAGE_PATH);
    setTemplateId(template.id);
  }, [
    setTemplateId,
    template.id,
    isBulkSelectActive,
    location.pathname,
    pushAction,
  ]);

  useEffect(() => {
    template.smallThumbnail !== '' &&
      setSmallThumbnailUrl(template.smallThumbnail);
  }, [template.smallThumbnail]);

  return (
    <StyledTemplatesCardWrap
      data-template-name={template.name}
      data-testid="template-card"
      {...props}
    >
      <div
        onClick={handleCardClick}
        onMouseOver={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-testid="template-card-clickable"
      >
        {((isSelectable && isCursorHovering) || isBulkSelectActive) && (
          <StyledCardCheckbox data-testid="template-card-checkbox">
            <Checkbox
              onClick={handleCheckboxClick}
              checked={isSelected}
              disabled={disabled}
            />
          </StyledCardCheckbox>
        )}
        <StyledTemplatesCard p={6} isSelected={isSelected}>
          <Flex>
            <Box mr={11}>
              <StyledImageBackground>
                {template && smallThumbnailUrl !== '' ? (
                  <StyledImage
                    src={`data:image/jpeg;base64, ${smallThumbnailUrl}`}
                  />
                ) : (
                  <EmptyLabelPreview
                    labelWidth={template?.labelSize?.width || 0}
                    labelHeight={template?.labelSize?.height || 0}
                  ></EmptyLabelPreview>
                )}
              </StyledImageBackground>
            </Box>
          </Flex>
          <Flex flexDirection={'column'}>
            <TemplateTextBox width={'163px'}>
              <Text
                data-testid="current-template-name"
                fontSize={16}
                lineHeight={'20px'}
                fontWeight={'500'}
                letterSpacing={0.4}
                color={theme.neutral.darkest}
                title={template.name}
              >
                {template.name}
              </Text>
            </TemplateTextBox>
            <Box mt={1} mb={1}>
              {etch && (
                <Text
                  data-testid="template-size"
                  fontSize={16}
                  lineHeight={'22px'}
                  fontWeight={'normal'}
                  letterSpacing={0.4}
                  color={theme.neutral.darkest}
                >
                  {etch}
                </Text>
              )}
            </Box>
            {template.lastPrint && showLastPrint === true && (
              <LastPrintText
                data-testid="last-print"
                display={'inline'}
                fontSize={14}
                lineHeight={'18px'}
                fontWeight={'300'}
                letterSpacing={0.4}
                color={theme.neutral.darkest}
              >
                {t('components:printer.last-print', {
                  date: formatDate(
                    'mmm dd, yyyy',
                    new Date(template.lastPrint),
                  ),
                })}
              </LastPrintText>
            )}
          </Flex>
        </StyledTemplatesCard>
      </div>
    </StyledTemplatesCardWrap>
  );
};

export default TemplateCard;
