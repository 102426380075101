import styled from 'styled-components';
import { Box, Button, Icon, Text } from 'components';

export const SectionTitle = styled(Text).attrs(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '21px',
  fontWeight: '400',
  letterSpacing: '0.4px',
  color: theme.textColors.high,
  marginBottom: '9px',
}))``;

export const StyledText = styled(Text).attrs(({ theme }) => ({
  color: theme.primary.base,
}))``;

export const StyledIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.primary.base,
  size: '14',
  icon: 'play',
}))``;

export const VideoLinkContainer = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const CircleBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: ${({ theme }) => `solid 2px ${theme.primary.base}`};
  margin-left: 8px;
`;

export const StyledButton = styled(Button)`
  min-width: 88px;
  justify-content: center;
`;
