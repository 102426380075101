import React, { forwardRef, useEffect } from 'react';
import { Button } from 'components';
import { useReduxAction, useReduxSelector } from 'hooks';
import { actions as dataActions } from 'ducks/dataSources/actions';
import styled from 'styled-components';
import { getGoogleAccount } from 'ducks/dataSources/selectors';
import { useTranslation } from 'react-i18next';
import { getAssetPath } from 'utils/files';

const StyledButton = styled(Button)`
  background: url('${getAssetPath('logo_google.svg')}') transparent 12px 50%/24px
    no-repeat;
  box-shadow: 0px 1px 1px #bbb;
  border: 1px solid rgba(0, 0, 0, 0.26);
  cursor: pointer;
  border-radius: 3px;
  padding: 12px 12px 12px 62px;
  letter-spacing: 0;
  font-weight: medium;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.54);
  transition: background-color 0.2s ease-out;
  &:active {
    background-color: #eee;
  }
  &:hover {
    color: rgba(0, 0, 0, 0.54);
  }
`;

const GoogleDrive = forwardRef((props: any, ref: any) => {
  const { t } = useTranslation('dataSources');

  const open = useReduxAction(dataActions.GOOGLE_DRIVE.request);
  const getAccount = useReduxAction(dataActions.GOOGLE_ACCOUNT.request);
  const googleAccount = useReduxSelector(getGoogleAccount);

  useEffect(() => {
    getAccount();
  }, [googleAccount, getAccount]);

  return (
    <StyledButton
      variant="text-primary"
      onClick={() => {
        open();
        ref.close();
      }}
    >
      {googleAccount
        ? t('dataSources:link-to-google-drive-file')
        : t('dataSources:sign-in-with-Google')}
    </StyledButton>
  );
});

export default GoogleDrive;
