import styled, { keyframes } from 'styled-components';

export type LoaderProps = {
  visible?: boolean;
  width?: string;
  height?: string;
  borderWidth?: string;
};

const spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
export const AnimatedLoader = styled.div<Omit<LoaderProps, 'visible'>>`
  border-top: solid ${({ theme }) => theme.primary.base};
  border-right: solid ${({ theme }) => theme.neutral.lighter};
  border-bottom: solid ${({ theme }) => theme.neutral.lighter};
  border-left: solid ${({ theme }) => theme.neutral.lighter};
  border-width: ${({ borderWidth }) => (borderWidth ? borderWidth : '5px')};
  animation: ${spinner} 0.9s linear infinite;
  border-radius: 50%;
  width: ${({ width }) => (width ? width : '30px')};
  height: ${({ height }) => (height ? height : '30px')};
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
