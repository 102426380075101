import { BannerMessage, Preferences } from 'zsbpsdk/proto/ZsbpPortalService_pb';
import { CustomerInfoType } from 'zsbpsdk/src/customer/index';
import { DEFAULT_PREFERENCES_LANGUAGE } from './i18n';
import BannerMessageType = BannerMessage.BannerMessageType;

const LOCAL_STORAGE_KEY_PREFIX = 'bannerMessageId';
const LOCAL_STORAGE_EXPIRABLE_KEY_PREFIX = 'bannerMessageId-expirable';
const EXPIRING_BANNER_MESSAGE_DISPLAY_LIMIT = 3;
const EXPIRING_BANNER_MESSAGE_TYPES = [
  BannerMessageType.NEW_FEATURES,
];

type BannerInfoType = {
  loginCounter: number;
  displayedToUser: boolean;
};

export const getBannerMessageKey = (
  bm: BannerMessage,
  customer: CustomerInfoType,
) => `${LOCAL_STORAGE_KEY_PREFIX}-${bm.getId()}-${customer.id}`;

export const getBannerMessageSettingKey = (
  type: BannerMessageType,
  customer: CustomerInfoType,
) => `bannerMessageType-${type}-${customer.id}`;

export const getBannerMessageExpirableKey = (
  bannerID: number,
  customer: CustomerInfoType,
) => `${LOCAL_STORAGE_EXPIRABLE_KEY_PREFIX}-${bannerID}-${customer.id}`;

const getExpiredBannerMessageKeys = (
  bannerMessages: BannerMessage[],
  customer: CustomerInfoType,
): string[] =>
  bannerMessages
    .filter((bm) => EXPIRING_BANNER_MESSAGE_TYPES.includes(bm.getType()))
    .map((bm) => getBannerMessageExpirableKey(bm.getId(), customer))
    .filter((key) => {
      const bannerInfoLocalStorage = localStorage.getItem(key);
      const bannerInfo: BannerInfoType = JSON.parse(bannerInfoLocalStorage || '{}');
      const displayCount = bannerInfo.loginCounter;

      return displayCount >= EXPIRING_BANNER_MESSAGE_DISPLAY_LIMIT;
   });

export const getUnexpiredBannerMessages = (
  bannerMessages: BannerMessage[],
  customer: CustomerInfoType,
): BannerMessage[] => {
  const expiredBannerMessageKeys = getExpiredBannerMessageKeys(
    bannerMessages,
    customer,
  );

  return bannerMessages.filter(
    (bm) =>
      !expiredBannerMessageKeys.includes(getBannerMessageExpirableKey(bm.getId(), customer)),
  );
};

const getEnabledBannerMessageKeys = (
  customer: CustomerInfoType,
): BannerMessageType[] => {
  const localStorageKeys = Object.keys(localStorage);

  return Object.keys(BannerMessageType)
    .filter((type) => !isNaN(Number(BannerMessageType[type])))
    .map(
      (type) =>
        BannerMessageType[
          type as keyof typeof BannerMessageType
        ],
    )
    .filter((type) =>
      type != null &&
      localStorageKeys.includes(getBannerMessageSettingKey(type, customer))
        ? localStorage.getItem(getBannerMessageSettingKey(type, customer)) ===
          'true'
        : true,
    );
};

const getEnabledBannerMessageTypes = (customer: CustomerInfoType): BannerMessageType[] => {
  return Object.entries(BannerMessageType)
    .map(([_, value]) => value as BannerMessageType)
    .filter((value) =>
      getEnabledBannerMessageKeys(customer).includes(value),
    );
};

export const getEnabledBannerMessages = (
  bannerMessages: BannerMessage[],
  customer: CustomerInfoType,
): BannerMessage[] => {
  const enabledTypes = getEnabledBannerMessageTypes(customer);
  return bannerMessages.filter((bm) => enabledTypes.includes(bm.getType()));
};

export const sortBannerMessages = (bannerMessages: BannerMessage[]) =>
  bannerMessages.sort((a, b) => {
    const createdA = a.getCreated();
    const createdB = b.getCreated();
    if (a.getType() === b.getType() && createdA != null && createdB != null) {
      return createdB.getSeconds() - createdA.getSeconds();
    } else {
      return a.getType() - b.getType();
    }
});

const initializeBannerMessages = (
  bannerMessages: BannerMessage[],
  customer: CustomerInfoType,
) => {
  bannerMessages
    .map((bm) => getBannerMessageKey(bm, customer))
    .filter((key) => localStorage.getItem(key) == null)
    .forEach((key) => localStorage.setItem(key, String(0)));

  const enabledExpiringBannerMessages = bannerMessages.filter((bm) =>
    EXPIRING_BANNER_MESSAGE_TYPES.includes(bm.getType()),
  );

  const bannerInfo: BannerInfoType = {
    loginCounter: 0,
    displayedToUser: false,
  }

  enabledExpiringBannerMessages
    .map((bm) => getBannerMessageExpirableKey(bm.getId(), customer))
    .filter((key) => localStorage.getItem(key) == null)
    .forEach((key) => localStorage.setItem(key, JSON.stringify(bannerInfo)));
};

export const updateBannerMessagesDisplayInfo = (
  bannerMessages: BannerMessage[],
  customer: CustomerInfoType,
) => {
  const enabledBannerMessages = getEnabledBannerMessages(bannerMessages, customer);
  const enabledBannerMessageLocalStorageKeys = enabledBannerMessages.map((bm) =>
    getBannerMessageKey(bm, customer)
  );

  Object.entries(localStorage)
    .filter(([bannerMessageKey]) => enabledBannerMessageLocalStorageKeys.includes(bannerMessageKey))
    .forEach(([bannerMessageKey, displayCount]) => {
      const updatedDisplayCount = parseInt(displayCount) + 1;
      localStorage.setItem(bannerMessageKey, String(updatedDisplayCount));
    });

  incrementBannerMessagesLoginCounter(enabledBannerMessages, customer);
};

export const incrementBannerMessagesLoginCounter = (
  bannerMessages: BannerMessage[],
  customer: CustomerInfoType,
) => {
  const enabledExpiringBannerMessages = bannerMessages.filter((bm) =>
    EXPIRING_BANNER_MESSAGE_TYPES.includes(bm.getType()),
  );

  const bannerMessagesWithDisplayLimitKeys = enabledExpiringBannerMessages.map(
    (bm) => getBannerMessageExpirableKey(bm.getId(), customer),
  );

  Object.entries(localStorage)
    .filter(([key]) => bannerMessagesWithDisplayLimitKeys.includes(key))
    .forEach(([key, value]) => {
      const bannerInfoLocalStorage = localStorage.getItem(key);
      const bannerInfo: BannerInfoType = JSON.parse(bannerInfoLocalStorage || '{}');

      if (bannerInfo.displayedToUser) {
        const updatedBannerInfo = {
          loginCounter: bannerInfo.loginCounter + 1,
          displayedToUser: false,
        }
        localStorage.setItem(key, JSON.stringify(updatedBannerInfo));
      }
    });
}

const removeUnusedBannerMessages = (
  bannerMessages: BannerMessage[],
  customer: CustomerInfoType,
) => {
  const bannerMessageKeys = bannerMessages.map((bm) =>
    getBannerMessageKey(bm, customer),
  );

  const bannerMessageExpirableKeys = bannerMessages.map((bm) =>
    getBannerMessageExpirableKey(bm.getId(), customer),
  );
  const allBannerKeys = bannerMessageKeys.concat(bannerMessageExpirableKeys);

  Object.keys(localStorage)
    .filter((key) => key.startsWith(LOCAL_STORAGE_KEY_PREFIX))
    .filter((key) => key.includes(customer.id))
    .filter((key) => !key.startsWith(LOCAL_STORAGE_EXPIRABLE_KEY_PREFIX))
    .filter((key) => !allBannerKeys.includes(key))
    .forEach((key) => localStorage.removeItem(key));
};

export const updateBannerMessageLocalStorage = (
  bannerMessages: BannerMessage[],
  customer: CustomerInfoType,
) => {
  initializeBannerMessages(bannerMessages, customer);
  removeUnusedBannerMessages(bannerMessages, customer);
};

export const getBannerMessageContentForLanguage = (
  bannerMessage: BannerMessage,
  language: Preferences.Language,
): BannerMessage.Content | undefined => {
  const contentList = bannerMessage.getContentList();
  const content = contentList.find((bm) => bm.getLanguage() === language);

  return content != null
    ? content
    : contentList.find(
        (bm) => bm.getLanguage() === DEFAULT_PREFERENCES_LANGUAGE,
      );
};

export const incrementExpirableBannerMessageInfo = (
  customer: CustomerInfoType,
  bannerID: number
) => {

  // This key gives us information if the user has actually seen the banner during a specific login.
  const bannerMessageExpirableKey = getBannerMessageExpirableKey(
    bannerID,
    customer,
  );

  if (bannerMessageExpirableKey) {
    const bannerInfoLocalStorage = localStorage.getItem(bannerMessageExpirableKey);
    const bannerInfo = JSON.parse(bannerInfoLocalStorage || '{}');

    if (bannerInfo.displayedToUser != null && !bannerInfo.displayedToUser) {
      bannerInfo.displayedToUser = true;
      localStorage.setItem(
        bannerMessageExpirableKey,
        JSON.stringify(bannerInfo),
      );
    }
  }
};
