import React from 'react';
import styled from 'styled-components';
import { Box, Text } from 'components';
import { push } from 'connected-react-router';
import { useReduxAction, useReduxSelector } from 'hooks';

const TabControlWrap: any = styled(Box)`
  border-bottom: 2px solid
    ${({ theme, isActive }: any) =>
      isActive ? theme.primary.base : 'transparent'};
  cursor: pointer;
  min-width: 112px;
  &:first-child {
    border-bottom: 2px solid
      ${({ theme, isActive, isActiveOnLoad }: any) =>
        isActive || isActiveOnLoad ? theme.primary.base : 'transparent'};
  }
`;

const TabControlText: any = styled(Text).attrs(({ isActive, theme }: any) => ({
  fontSize: 24,
  letterSpacing: 0.4,
  lineHeight: '32px',
  fontWeight: 300,
  textAlign: 'center',
  color: isActive ? theme.primary.base : theme.textColors.med,
}))``;

const TabControl = ({ children, disabled, href, ...props }: any) => {
  const pushAction = useReduxAction(push);
  const path = useReduxSelector((state: any) => state.router.location.pathname);
  const isActive = path === href;
  const isActiveOnLoad = path === '/templates';
  return (
    <TabControlWrap
      onClick={() => pushAction(href)}
      isActive={isActive}
      isActiveOnLoad={isActiveOnLoad}
      pb={2}
      {...props}
    >
      <TabControlText
        data-testid="tab-control-text"
        isActive={isActive}
      >
        {children}
      </TabControlText>
    </TabControlWrap>
  );
};

export default TabControl;
