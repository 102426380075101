import React from 'react';
import styled from 'styled-components';

interface MenuProps {
  collapsed?: boolean;
}

const StyledItem = styled.div<MenuProps>`
  margin: 0 ${({ theme }) => theme.px(8)};
  border-top: 1px solid ${({ theme }) => theme.primary.lightest};
  padding: ${({ theme }) => theme.px(12, 0)};
  padding-bottom: ${(collapsed) => (collapsed ? '28px' : '21px')};
  &:nth-child(1) {
    border-top: none;
    padding-top: ${({ theme }) => theme.px(7)};
  }
`;

const Menu = ({
  children,
  collapsed = false,
  ...props
}: React.PropsWithChildren<MenuProps>) => (
  <StyledItem collapsed={collapsed} {...props}>
    {children}
  </StyledItem>
);

export default Menu;
