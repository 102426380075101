import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useUrlParam, useReduxSelector, useReduxAction } from 'hooks';
import { actions as searchActions } from 'ducks/search/actions';
import { getZebraTemplatesMixedSuggestions } from 'ducks/search/selectors';
import { selectCategoriesWithLabel } from 'ducks/categories/selectors';
import { Flex, Box, Search } from 'components';
import { HintText } from 'v2/components/common';

const SearchWrap = styled(Box)`
  flex: 1;
  max-width: 512px;
  maring-right: ${({ theme }) => theme.px(4)};
`;

export default function FilterCategoriesLayout() {
  const { t } = useTranslation();

  const [searchString, setSearchString] = useUrlParam('searchLibrary', '');
  const libraryMixedOptions = useReduxSelector(
    getZebraTemplatesMixedSuggestions,
  );
  const { data: allCategories } = useReduxSelector(selectCategoriesWithLabel);
  const searchLibraryTemplates = useReduxAction(
    searchActions.ZEBRA_TEMPLATES.request,
  );
  const searchCategories = useReduxAction(searchActions.CATEGORIES.request);

  const onLibrarySearch = useCallback(
    (value: string): void => {
      searchLibraryTemplates({
        value: value,
      });
      searchCategories(value);
    },
    [searchCategories, searchLibraryTemplates],
  );

  const onSelectSearch = (value: string) => {
    searchCategories(value);
    setSearchString(value);
  };

  //This is really ugly but it's needed in order to filter the categories
  //after a refresh when a searchString is present
  //Ideally should be execute just once after a browser refresh
  React.useEffect(() => {
    if (allCategories.length && searchString) {
      searchCategories(searchString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCategories.length, onLibrarySearch]);

  return (
    <>
      <Flex flexDirection={'row'} mb={'32px'}>
        <HintText
          data-testid="access-designs"
          variant="label"
          lineHeight={'32px'}
        >
          {t('templates:access-pre-made-designs')}
        </HintText>
      </Flex>

      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <SearchWrap mb={'24px'}>
          <Search
            placeholder={t('templates:search-common-designs')}
            data-testid="input-search"
            onChange={onLibrarySearch}
            options={libraryMixedOptions}
            onSelect={onSelectSearch}
            initialSearch={searchString}
          />
        </SearchWrap>
      </Flex>
    </>
  );
}
