import * as React from 'react';
import { Card, Flex, Box, Carousel } from 'components';
import styled from 'styled-components';
import slides from 'pages/components/components/data/slider.json'; //TODO remove this and add redux layer
import PrintersList from 'components/app/printers-list/PrintersList';

const Slide = styled.div`
  height: 100%;
  width: 100%;
  padding: 24px;

  && {
    img {
      display: block;
      margin: 0 auto;
    }
  }
`;

const CarouselView = (): any => {
  return (
    <Card p={10} m={10} elevation={'two'}>
      <Flex flexDirection="column">
        <h2>Carousel / Printers List</h2>
        <Flex>
          <Box width={336} mr={40}>
            <Carousel>
              {slides.map((slide, i) => (
                <Slide key={i}>
                  <Flex>
                    <Box mt={33} mb={49} width={'100%'}>
                      <img src={slide.avatar} width="220" alt={slide.avatar} />
                    </Box>
                  </Flex>
                </Slide>
              ))}
            </Carousel>
          </Box>
          <Box>
            <PrintersList />
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
};

export default CarouselView;
