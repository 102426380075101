import React from 'react';
import { Flex, Text, Loader } from 'components';

import styled from 'styled-components';
import { useReduxSelector } from 'hooks';
import { getAllPreferencesLoading } from 'ducks/preferences/selectors';
import { WiFi } from 'pages/settings/components/WiFi';

export const Title = styled(Text).attrs(({ theme }) => ({
  color: theme.textColors.high,
}))`
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.4px;
`;

export const NetworkConnection = () => {
  const isLoadingPrefs = useReduxSelector(getAllPreferencesLoading);

  return (
    <>
      {isLoadingPrefs ? (
        <Loader visible={true} />
      ) : (
        <Flex flexDirection={'column'}>
          <WiFi />
        </Flex>
      )}
    </>
  );
};
