import React from 'react';
import styled from 'styled-components';
import { device } from 'utils/mediaQueries';

interface SidenavProps {
  children: any;
  open: boolean;

  collapsed: boolean;
}

const SidenavHolder = styled.div<SidenavProps>`
  -webkit-overflow-scrolling: touch;
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.collapsed ? '54px' : '240px')};
  transition: width 0.6s ease-out, transform 0.3s ease-out;
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${({ theme }) => theme.elevations.nav};
  height: 100vh;
  @media ${device.tablet} {
    height: calc(100vh - 71px);
    top: 71px;
  }
`;

const Sidenav = ({ children, open, collapsed, ...props }: any) => {
  return (
    <SidenavHolder collapsed={props.collapsedState} {...props} open={open}>
      {children}
    </SidenavHolder>
  );
};

export default Sidenav;
