import styled from 'styled-components';
import { Box, Card, Text } from '../../index';

type TemplateSizePreview = {
  labelWidth: number;
  labelHeight: number;
};

export const TemplateTextBox = styled(Box)`
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-wrap: break-word;
}
`;

export const LastPrintText = styled(Text)``;

export const StyledTemplatesCardWrap = styled.div`
  overflow: visible;
  position: relative;
  min-width: 315px;
  max-width: 342px;
`;

export const StyledTemplatesCard = styled(Card)<{ isSelected?: boolean }>`
  justify-content: flex-start;
  border: 1px solid ${({ theme }) => theme.neutral.lightest};
  ${({ isSelected = false, theme }) => isSelected && `background-color: ${theme.primary.lighter};`}
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  height: 126px;
  min-width: 315px;
  max-width: 336px;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 16px 32px rgba(73, 80, 94, 0.16);
    cursor: pointer;
  }
`;

export const StyledImageBackground = styled.div`
  width: 100px;
  height: 100px;
  background-color: ${({ theme }) => theme.primary.lightest};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid ${({ theme }) => theme.primary.lightest};
  overflow: hidden;
`;

export const StyledImage = styled.img`
  max-width: 100px;
  max-height: 100px;
`;

export const EmptyLabelPreview = styled(Box)<TemplateSizePreview>`
  background-color: ${({ theme }) => theme.white};
  border-radius: ${({ theme }) => theme.space[4]}px;
  width: ${({ labelWidth, labelHeight }) =>
    labelWidth > labelHeight ? 100 : (labelWidth / labelHeight) * 100}%;
  height: ${({ labelWidth, labelHeight }) =>
    labelWidth < labelHeight ? 100 : (labelHeight / labelWidth) * 100}%;
`;

export const StyledCardCheckbox = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;

  label {
    input {
      cursor: pointer;
      margin: 0;
    }
  }
`;
