import React, { useEffect, useMemo } from 'react';
import { Text, Flex, Box, Loader } from 'components';
import { useReduxSelector, useReduxAction } from 'hooks';
import {
  getRecentTemplates,
  getRecentTemplatesError,
  getRecentTemplatesLoading,
} from 'ducks/templates/selectors';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TemplateType } from 'zsbpsdk/src/templates';
import NoLabels from 'pages/templates/components/NoLabels';
import { getUnit } from 'ducks/preferences/selectors';
import { actions as templateActions } from '../../../ducks/templates/actions';
import {
  TemplatesWrap,
  StyledTemplateCard,
  StyledTitle,
} from './RecentTemplates.styles';
import { useAppDispatch } from 'hooks';

const RecentTemplates = () => {
  const theme = React.useContext(ThemeContext);
  const recentTemplates: TemplateType[] = useReduxSelector(getRecentTemplates);
  const recentTemplatesLoading = useReduxSelector(getRecentTemplatesLoading);
  const recentTemplatesError = useReduxSelector(getRecentTemplatesError);
  const unit = useReduxSelector(getUnit);
  const clearRecentTemplates = useReduxAction(templateActions.RECENT.clear);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(templateActions.RECENT.request({}));
    return () => {
      clearRecentTemplates();
    };
  }, [clearRecentTemplates, dispatch]);

  const { t } = useTranslation('overview');

  const handleConditionalRender = () => {
    if (recentTemplatesLoading) {
      return <Loader visible={true} />;
    } else if (hasRecentTemplates) {
      return (
        <TemplatesWrap mb={'128px'} flexWrap="wrap" justifyContent="start">
          {recentTemplates.map((t: TemplateType) => (
            <StyledTemplateCard
              template={t}
              key={t.id}
              elevation={'one'}
              pr={15}
              unit={unit}
            />
          ))}
        </TemplatesWrap>
      );
    }

    return (
      <Box px={24} width={'100%'}>
        <NoLabels
          labels={recentTemplates}
          noDataText={t('templates:no-data-my-designs')}
          noDataSuggestion={t('templates:no-data-my-designs-suggestion')}
        />
      </Box>
    );
  };

  const hasRecentTemplates = useMemo(
    () =>
      recentTemplates && recentTemplates !== null && recentTemplates.length > 0,
    [recentTemplates],
  );

  return (
    <>
      {!recentTemplatesLoading && recentTemplates.length !== 0 && (
        <Flex mb={'16px'}>
          <StyledTitle>
            <Text
              fontSize={24}
              lineHeight={'32px'}
              fontWeight={'300'}
              letterSpacing={0.4}
              color={theme.neutral.darkest}
            >
              {t('overview:labels.recently-printed')}
            </Text>
          </StyledTitle>
        </Flex>
      )}

      {recentTemplatesError ? (
        <Flex
          flexDirection={'column'}
          width={'100%'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Text
            fontSize={24}
            lineHeight={'32px'}
            fontWeight={'300'}
            letterSpacing={0.4}
            color={theme.error.base}
            mb={8}
          >
            {t('overview:communication-error')}
          </Text>
        </Flex>
      ) : (
        handleConditionalRender()
      )}
    </>
  );
};

export default RecentTemplates;
