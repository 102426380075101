import React, {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  RefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  MouseEventHandler,
  HTMLAttributes,
} from 'react';
import styled from 'styled-components';
import Box from '../../common/box/Box';
import Flex from 'components/common/flex/Flex';
import Typography from 'components/common/typography/Typography';
import Icon from 'components/lib/icon/Icon';
import Loader from '../loader/Loader';

import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import './dateInput.css';
import { localeMap, getCurrentLanguage, parseDate } from 'utils/dateFormatter';

type TextInputType =
  | 'text'
  | 'email'
  | 'number'
  | 'password'
  | 'date'
  | 'search'
  | 'tel';

interface MessageWrapProps {
  hasError: boolean | undefined;
}

interface TextInputProps {
  /** Id of the input */
  id?: string;
  /** type of input */
  type?: TextInputType;
  /** the short hint displayed in the input before the user enters a value */
  placeholder?: string;
  /** name attribute of the input element */
  name?: string;
  /** maximum characters allowed */
  max?: string;
  /** if true, the component will be disabled */
  disabled?: boolean;
  /** The initial value of the textarea. Primarily for use with controlled components. If this prop is specified, an onChange handler must also be specified. */
  value?: string | null;
  /** Initial value of the text area, not linked at the value throughout lifetime */
  defaultValue?: string | null;
  /** automatically focus the form control when the page is loaded */
  autoFocus?: boolean;
  /** if true, the label is displayed as required and the input element will be required */
  required?: boolean;
  /** label associated with the input element */
  label?: string;
  /** icon located at the start of the input */
  iconStart?: React.ReactNode;
  /** icon located at the end of the input */
  iconEnd?: React.ReactNode;
  /** if true, the component will be displayed in an error state */
  hasError?: boolean;
  /** indicates that the user cannot modify the value of the input */
  readonly?: boolean;
  /** message that displays upon unsuccessful validation */
  message?: string | null;
  /** if true, Text Input becomes textarea */
  multiline?: number;
  /**  property for aligning text in the input */
  textAlign?: string;
  /** callback fired when the start icon is clicked */
  onStartIconClick?: (event: SyntheticEvent) => void;
  /** callback fired when the end icon is clicked */
  onEndIconClick?: (event: SyntheticEvent) => void;
  /** callback fired when the state is changed */
  onClick?: MouseEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  /** callback fired when the component is focused */
  onFocus?: FocusEventHandler<HTMLInputElement>;
  /** callback fired when the component losses focus */
  onBlur?: FocusEventHandler<HTMLInputElement>;
  focus?: boolean;
  dataTestid?: string;
  tabIndex?: number;
  /** callback fired when key is pressed down*/
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  /** property to disable the field and show a loading spinner */
  busy?: boolean;
  format?: string;
  inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
  pattern?: string;
}

const StyledDateWrapper = styled(MuiPickersUtilsProvider)`
  padding-left: 6px;
`;

const AttentionIcon = styled(Icon).attrs(({ theme }) => ({
  size: 13,
  icon: 'error',
  color: theme.error.base,
}))``;

let lastId = 0;

const MessageWrap = styled(Flex).attrs({
  mt: 2,
})<MessageWrapProps>`
  position: absolute;
  align-items: center;
  top: ${({ theme }) => theme.space[17]}px;
  color: ${({ theme, hasError }: any) =>
    hasError ? theme.error.base : theme.neutral.base};
  font-family: ${({ theme }) => theme.defaultFont};
  font-size: ${({ theme }) => theme.fontSizes.caption}px;
`;

export const IconWrap = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  padding: 6,
})`
  ${({ disabled, onClick }: any) =>
    disabled ? `cursor: not-allowed;` : onClick && `cursor: pointer;`}
  font-size: 16px;
  letter-spacing: 0.4px;
  border: 0 none;
  color: ${({ theme }) => theme.neutral.lighter};
  background: none;
  opacity: ${({ disabled }: any) => (disabled ? 0.55 : 1)};
  outline: none;
`;

export const LoaderWrap = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  padding: 6,
})`
  ${({ theme }) =>
    `
    outline: none;
    border-radius: ${theme.px(0, 4, 4, 0)}; 
    background-color: ${theme.neutral.lightest};
    color: ${theme.neutral.lighter};
    `}
`;

const TextInputLabel = styled(Typography).attrs(({ theme }) => ({
  lineHeight: '21px',
  fontSize: theme.fontSizes.label,
  color: theme.textColors.high,
  letterSpacing: 0.4,
  mb: 2,
  display: 'block',
}))``;

export const TextInputField = styled.input.attrs(
  ({
    type,
    id,
    disabled,
    hasError,
    max,
    value,
    defaultValue,
    iconStart,
    iconEnd,
    busy,
    pattern,
    inputMode,
  }: TextInputProps) => ({
    id: id,
    type: type,
    disabled: disabled,
    hasError: hasError,
    maxlength: max,
    value: value,
    defaultValue: defaultValue,
    iconStart: iconStart,
    iconEnd: iconEnd,
    busy: busy,
    pattern: pattern,
    inputMode: inputMode,
  }),
)`
  width: 100%;
  @-moz-document url-prefix() {
    min-width: 0;
  }
  display: block;
  caret-color: ${({ theme }) => theme.primary.base};
  padding: ${({ theme }) =>
    `${theme.space[6]}px 0 ${theme.space[6]}px ${theme.space[8]}px `};
  font-family: ${({ theme }) => theme.defaultFont};
  font-size: ${({ theme }) => theme.fontSizes.body}px;
  color: ${({ theme }) => theme.textColors.high};
  border: 0 none;
  border-radius: 8px 0 0 8px;
  background: transparent;
  outline: none;
  text-align: ${({ textAlign }: any) => (!!textAlign ? textAlign : 'left')};

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.neutral.lightest};
    border-radius: 8px;
  }
  ${({ multiline }: any) => multiline && `resize: none;`};
  ::placeholder {
    color: ${({ theme }) => theme.textColors.med};
  }
  ${({ iconStart, theme }) =>
    iconStart &&
    `
      padding-left: ${theme.space[2]}px;
  `}
`;

// Required for workaround to Firefox number-type input arrow buttons not working when padding overlaps arrow buttons
const TextInputFieldEndPadding = styled.div<TextInputProps>`
  width: ${({ iconEnd, theme }) =>
    iconEnd ? theme.space[2] : theme.space[8]}px;
`;

export const InnerTextInputFieldWrap = styled(Flex)<TextInputProps>`
  position: relative;
  background: #fff;
  border-radius: ${({ theme }) => theme.space[4]}px;
  height: ${({ theme, multiline }) => !multiline && theme.space[17]}px;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.neutral.lighter};
  box-sizing: border-box;

  ${({ disabled, theme }: any) =>
    disabled &&
    `
    cursor: not-allowed;
    background-color: ${theme.neutral.lightest};
    color: ${theme.neutral.lighter};
    box-shadow: 0 0 0 1px ${theme.neutral.lighter};
    `}

  &:focus-within {
    outline: none;
    border: none;
    box-shadow: 0 0 0 2px
      ${({ hasError, theme }: any) =>
        !hasError ? theme.primary.base : theme.error.base};
    transition: all 0.2s ease-in;
  }

  ${({ hasError, theme }) =>
    hasError &&
    `
    background-color: ${theme.error.lightest};
    box-shadow: 0 0 0 1px ${theme.error.base};
  `}
`;

const TextInput = (
  {
    hasError,
    message,
    name,
    type = 'text',
    label,
    disabled,
    required,
    placeholder,
    value,
    defaultValue,
    onChange,
    onClick,
    iconStart,
    iconEnd,
    onStartIconClick,
    onEndIconClick,
    onFocus,
    onBlur,
    multiline,
    textAlign,
    readonly = false,
    tabIndex,
    onKeyDown,
    busy,
    format,
    pattern,
    inputMode,
    ...props
  }: TextInputProps,
  ref: any,
) => {
  const textInputFieldRef = useRef<HTMLInputElement>(null);

  const inputOnChangeCallback = useCallback(
    (event: any) => {
      if (onChange) {
        onChange(event);
      }
    },
    [onChange],
  );

  const inputOnClickCallback = useCallback(
    (event: any) => {
      if (onClick) {
        onClick(event);
      }
    },
    [onClick],
  );

  const focusInput = (ref: RefObject<HTMLInputElement>) => {
    ref.current?.focus();
  };

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(event);
      }
    },
    [onFocus],
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur],
  );

  const handleStartIconClick = useCallback(
    (event: SyntheticEvent) => {
      if (!disabled) {
        if (onStartIconClick) {
          onStartIconClick(event);
        } else {
          focusInput(textInputFieldRef);
        }
      }
    },
    [disabled, onStartIconClick, textInputFieldRef],
  );

  const handleEndPaddingClick = useCallback(() => {
    if (!disabled) {
      focusInput(textInputFieldRef);
    }
  }, [disabled, textInputFieldRef]);

  const handleEndIconClick = useCallback(
    (event: SyntheticEvent) => {
      if (onEndIconClick && !disabled) {
        if (onEndIconClick) {
          onEndIconClick(event);
        } else {
          focusInput(textInputFieldRef);
        }
      }
    },
    [disabled, onEndIconClick, textInputFieldRef],
  );

  /* let multilineProps = {}; */

  /* if (multiline) {
    multilineProps.rows = multiline;
    multilineProps.as = "textarea";
    multilineProps.multiline = true;
  } */

  lastId += 1;

  //new
  const [selectedDate, setSelectedDate] = React.useState<
    string | null | undefined
  >(value);
  const [prevSelectedDate, setPrevSelectedDate] = React.useState<
    string | null | undefined
  >(null);

  useEffect(() => {
    if (type === 'date') {
      setPrevSelectedDate(selectedDate);
      setSelectedDate(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleDateChange = (
    date: Date | null,
    value: string | null | undefined,
  ) => {
    setSelectedDate(value);
    if (onChange) {
      const syntheticEvent = {
        target: {
          value: value || '',
        },
      } as ChangeEvent<HTMLInputElement>;
      onChange(syntheticEvent);
    }
  };

  const getSelectedDate = useCallback(() => {
    if (
      format != null &&
      selectedDate != null &&
      selectedDate !== prevSelectedDate
    ) {
      return parseDate(format, selectedDate);
    } else {
      return '';
    }
  }, [format, selectedDate, prevSelectedDate]);

  return (
    <Box width={[1]} {...props}>
      {label && (
        <TextInputLabel as={'label'} htmlFor={`${name || 'id'}-${lastId}`}>
          {label}
        </TextInputLabel>
      )}
      <InnerTextInputFieldWrap
        data-testid="input-wrap"
        disabled={disabled}
        hasError={hasError}
      >
        {iconStart && (
          <IconWrap
            as={'button'}
            tabIndex={onStartIconClick ? 0 : -1}
            disabled={disabled}
            onClick={handleStartIconClick}
          >
            {iconStart}
          </IconWrap>
        )}

        {type === 'date' && (
          <StyledDateWrapper
            utils={DateFnsUtils}
            locale={localeMap.get(getCurrentLanguage()) || localeMap.get('en')}
          >
            <Grid container justifyContent="space-around" alignContent="center">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format={format}
                margin="normal"
                id={`${name || 'id'}-${lastId}`}
                placeholder={format ? format.toLowerCase() : ''}
                value={selectedDate ? getSelectedDate() : null}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoOk={true}
              />
            </Grid>
          </StyledDateWrapper>
        )}

        {type !== 'date' && (
          <>
            <TextInputField
              data-testid="input-text-input-field"
              ref={textInputFieldRef}
              defaultValue={defaultValue?.replace(/\n/g, ' \n')}
              id={`${name || 'id'}-${lastId}`}
              value={value?.replace(/\n/g, ' \n')}
              type={type}
              name={name}
              disabled={disabled || busy}
              placeholder={placeholder}
              onChange={inputOnChangeCallback}
              onClick={inputOnClickCallback}
              onFocus={handleFocus}
              onBlur={handleBlur}
              iconStart={!!iconStart}
              iconEnd={!!iconEnd}
              readOnly={readonly}
              textAlign={textAlign}
              tabIndex={tabIndex}
              onKeyDown={onKeyDown}
              busy={busy}
              pattern={pattern}
              inputMode={inputMode}
              /* {...multilineProps} */
            />
            <TextInputFieldEndPadding
              iconEnd={!!iconEnd}
              onClick={handleEndPaddingClick}
            />
          </>
        )}

        {iconEnd && (
          <IconWrap
            data-testid="input-close-button"
            as={'button'}
            tabIndex={onEndIconClick ? 0 : -1}
            disabled={disabled}
            onClick={handleEndIconClick}
          >
            {iconEnd}
          </IconWrap>
        )}
        {busy && (
          <LoaderWrap>
            <Loader visible={true} width="20px" height="20px" />
          </LoaderWrap>
        )}
        {message && (
          <MessageWrap hasError={hasError}>
            {hasError && (
              <Box mr={2}>
                <AttentionIcon />
              </Box>
            )}
            {message}
          </MessageWrap>
        )}
      </InnerTextInputFieldWrap>
    </Box>
  );
};

export default TextInput;
