import React, { useEffect, useState } from 'react';
import { Flex, Slider, Text } from 'components';
import { actions as preferencesActions } from 'ducks/preferences/actions';
import { useReduxAction, useReduxSelector } from 'hooks';
import { getAllPreferences } from 'ducks/preferences/selectors';
import useDebounce from 'hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import {
  Container,
  PreviewWrapper,
  PreviewInnerWrapper,
  Title,
  ContentText,
} from './Dithering.styles';
import { getAssetPath } from 'utils/files';

type DitheringProps = {
  dithering: number;
  title: string;
} & React.HTMLAttributes<HTMLDivElement>;

const DitheringComponent = ({ dithering, title, ...props }: DitheringProps) => {
  const { t } = useTranslation(['settings', 'common']);

  const theme = React.useContext(ThemeContext);
  const prefs = useReduxSelector(getAllPreferences);
  const updatePreferences = useReduxAction(preferencesActions.UPDATE.request);
  const [ditheringLevel, setDitheringLevel] = useState<number>(dithering - 1);
  const debouncedTitle = useDebounce(ditheringLevel, 1000);

  useEffect(() => {
    if (+ditheringLevel + 1 !== dithering) handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTitle]);

  useEffect(() => {
    if (prefs.dithering - 1 !== ditheringLevel) {
      setDitheringLevel(prefs.dithering - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefs]);

  //   Edit preferences / set preferences redux action
  const handleSubmit = () => {
    console.log({
      ...prefs,
      units: prefs.units.grpcUnit,
      dithering: +ditheringLevel + 1,
    });
    updatePreferences({
      data: {
        ...prefs,
        units: prefs.units.grpcUnit,
        dithering: +ditheringLevel + 1,
      },
      message: t('settings:preferences.graphic-options-update'),
    });
  };

  return (
    <>
      <Container {...props}>
        <Title>{title}</Title>
        <ContentText>{t('settings:graphic-options-text')}</ContentText>
        <Text
          fontSize={14}
          lineHeight={'21px'}
          fontWeight={'400'}
          letterSpacing={0.4}
          color={theme.neutral.darkest}
          mb={'16px'}
          mt={'16px'}
        >
          {t('settings:dithering-method')}
        </Text>
        <Flex alignItems={'flex-end'}>
          <Slider
            value={ditheringLevel}
            min={0}
            max={5}
            onChange={(v) => {
              setDitheringLevel(+v);
            }}
            showBorderLines={true}
          />
        </Flex>
        <Flex flexDirection={'column'} justifyContent={'space-between'}>
          <Text
            fontSize={14}
            lineHeight={'21px'}
            fontWeight={'400'}
            letterSpacing={0.4}
            color={theme.neutral.darkest}
            mb={'4px'}
            mt={'18px'}
          >
            {t('common:preview')}
          </Text>
          <PreviewWrapper>
            <PreviewInnerWrapper>
              <img
                data-testid="dithering-image"
                alt={`Dithering`}
                title={`Dithering Image`}
                src={getAssetPath(`/dither-${ditheringLevel + 1}.jpg`)}
                height="384"
                width="257"
              />
            </PreviewInnerWrapper>
          </PreviewWrapper>
        </Flex>
      </Container>
    </>
  );
};

export default DitheringComponent;
