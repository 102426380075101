import React, { useRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrinterType } from 'zsbpsdk/src/printer';

import { OptionsMenuItem, OptionsMenu } from 'components';
import { FrontendEnvironment } from 'utils/config';
import { actions as printerActions } from 'ducks/printers/actions';
import { useReduxAction, useModal } from 'hooks';
import useDebounceCallback from 'hooks/useDebounceCallback';

//TODO move teh modals in separate shared folder
import PrinterDetailsModal from 'pages/overview/components/PrinterDetailsModal';
import CancelPrintJobsModal from 'pages/overview/components/CancelPrintJobsModal';
import DeletePrinterModal from 'pages/overview/components/DeletePrinterModal';
import { push } from 'connected-react-router';
import { useEnvironment } from '../../../../providers/Environment';

type PrintersMenuProps = {
  printer: PrinterType;
};

export default function PrintersMenu({ printer }: PrintersMenuProps) {
  const { t } = useTranslation('components');
  const { frontendEnvironment } = useEnvironment();
  const printerDetailsModal = useRef(null);
  const cancelPrintJobsModal = useRef(null);
  const optionsMenuRef = useRef(null);
  const reprint = useReduxAction(printerActions.REPRINT.request);
  const setSelectedPrinter = useReduxAction(printerActions.SELECTED.set);
  const isProductionEnvironment = frontendEnvironment === FrontendEnvironment.Production;
  const { open: openDetails } = useModal(printerDetailsModal);
  const { open: openCancel } = useModal(cancelPrintJobsModal);
  const pushAction = useReduxAction(push);

  const closeOptionsMenu = () => {
    const menuButton: any = optionsMenuRef.current;
    menuButton.firstChild.click();
  }

  const [isPrinterDeleteModalOpen, setIsPrinterDeleteModalOpen] =
    useState(false);

  const handleReprint = () => {
    reprint(printer);
    closeOptionsMenu();
  };

  const reprintWithDebounce = useDebounceCallback(handleReprint, 1000);

  const setPrinter = useCallback(
    ({ uniqueId }: PrinterType) => {
      if (uniqueId) {
        setSelectedPrinter(uniqueId);
      }
    },
    [setSelectedPrinter],
  );

  const handleCancel = useCallback(() => {
    if (openCancel) {
      setPrinter(printer);
      openCancel();
      closeOptionsMenu();
    }
  }, [openCancel, printer, setPrinter]);

  const handleDelete = useCallback(() => {
    setPrinter(printer);
    setIsPrinterDeleteModalOpen(true);
    closeOptionsMenu();
  }, [setIsPrinterDeleteModalOpen, printer, setPrinter]);

  const handleDetails = useCallback(() => {
    if (openDetails) {
      setPrinter(printer);
      openDetails();
      closeOptionsMenu();
    }
  }, [openDetails, printer, setPrinter]);

  return (
    <>
      <OptionsMenu
        ref={optionsMenuRef}
        data-testid="printer-options"
      >
        <OptionsMenuItem
          data-testid="reprint"
          onClick={reprintWithDebounce}
        >
          {t('components:printer.options.reprint-last-label')}
        </OptionsMenuItem>
        <OptionsMenuItem
          data-testid="printer-queue"
          onClick={handleCancel}
        >
          {t('components:printer.options.clear-printer-queue')}
        </OptionsMenuItem>
        <OptionsMenuItem
          data-testid="printer-settings"
          onClick={() => pushAction(`/settings/printers/${printer.uniqueId}`)}
        >
          {t('menu:printer-settings')}
        </OptionsMenuItem>
        <OptionsMenuItem
          data-testid="delete-printer"
          danger
          onClick={handleDelete}
        >
          {t('overview:printer.delete')}
        </OptionsMenuItem>
        {!isProductionEnvironment && (
          <OptionsMenuItem
            data-testid="printer-details-option"
            onClick={handleDetails}
          >
            {t('components:printer.options.debug-details')}
          </OptionsMenuItem>
        )}
      </OptionsMenu>
      <PrinterDetailsModal printer={printer} ref={printerDetailsModal} />
      <CancelPrintJobsModal printer={printer} ref={cancelPrintJobsModal} />
      <DeletePrinterModal
        handleClose={(state: boolean) => setIsPrinterDeleteModalOpen(state)}
        printer={printer}
        open={isPrinterDeleteModalOpen}
      />
    </>
  );
}
