import { Box, Text, Flex } from 'components';
import styled from 'styled-components';
import { device } from 'utils/mediaQueries';

export const Container = styled(Flex)`
  overflow-x: hidden;
  flex-wrap: wrap;
`;

export const Section = styled(Box)`
  margin-bottom: ${({ theme }) => theme.space[11]}px;
`;

export const PreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 74px;
  border: 1px solid #c9cdd4;
  border-radius: 8px;
  margin-bottom: 24px;

  @media ${device.tablet} {
    width: calc(100% - 5px);
  }

  @media ${device.mobile} {
    width: calc(100% - 5px);
    padding-left: 1px;
  }
`;

export const ExampleText = styled('h1')<{ darkness: number }>`
  opacity: ${(props) => props.darkness};
  font-size: 48px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: 0.4;
`;

export const SectionTitle = styled(Text).attrs(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '21px',
  fontWeight: '400',
  letterSpacing: '0.4px',
  color: theme.textColors.high,
  marginBottom: '4px',
  marginTop: '24px',
}))``;

export const PreviewTitle = styled(SectionTitle).attrs(({ theme }) => ({
  color: theme.textColors.high,
}))``;

export const Title = styled(Text).attrs(({ theme }) => ({
  color: theme.textColors.high,
}))`
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.4px;
`;

export const SettingsColumn = styled(Flex)`
  flex-direction: column;
  margin-bottom: 9px;
  margin-right: 20px;

  @media ${device.desktop} {
    width: 50%;
  }
  @media ${device.tablet} {
    width: 50%;
  }
  @media ${device.mobile} {
    width: 100%;
    margin-right: 0px;
  }
`;

export const PrintDarknessColumn = styled(Flex)`
  flex-direction: column;

  @media ${device.desktop} {
    margin-right: 20px;
  }

  @media ${device.tablet} {
    width: 50%;
  }

  @media ${device.mobile} {
    width: 100%;
    margin-right: 0px;
  }
`;

export const ContentText = styled(Text).attrs(({ theme }) => ({
  color: theme.textColors.med,
}))`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.4px;
  margin-top: 16px;

  @media ${device.desktop} {
    width: 384px;
  }
  @media ${device.tablet} {
    width: calc(100% - 5px);
  }
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  margin-bottom: 4px;
  padding-left: 1px;
  padding-right: 5px;

  @media ${device.desktop} {
    width: 384px;
  }

  @media ${device.tablet} {
    width: calc(100% - 5px);
  }

  @media ${device.mobile} {
    width: calc(100% - 5px);
  }
`;
