import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Flex, Box, Form, Button, Input, Icon, Text } from 'components';
import { ThemeContext } from 'styled-components';
import { SSO_CHANGE_PASSWORD_URL, getSsoSignupUrl } from 'utils/config';
import { useReduxSelector, useAppDispatch } from 'hooks';
import { getLinkedAccountString, getCustomer } from 'ducks/auth/selectors';
import { openBasicToast } from 'state/Toast';
import { useTranslation } from 'react-i18next';
import { CustomerInfoType } from 'zsbpsdk/src/customer/index';

const ChangeButton = styled(Box).attrs(({ theme }) => ({
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 500,
  letterSpacing: 0.4,
  color: theme.primary.base,
}))`
  display: inline-block;
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  text-align: left;

  &:hover {
    color: ${({ theme }) => theme.primary.dark};
  }
`;

const MAXIMUM_EMAIL_POST_MESSAGE_ATTEMPTS = 10;

export const PasswordChange = () => {
  const { t } = useTranslation(['settings', 'common']);

  const dispatch = useAppDispatch();
  const theme = React.useContext(ThemeContext);
  const [oldPassword, setOldPassword] = useState('oldpassword');
  const [isPasswordChanging, setIsPasswordChanging] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isRepeatedPasswordVisible, setIsRepeatedPasswordVisible] =
    useState(false);
  const [isPasswordWeak, setIsPasswordWeak] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [emailPostMessageAttempts, setEmailPostMessageAttempts] =
    useState<number>(0);
  const [changePasswordInterval, setChangePasswordInterval] = useState<
    NodeJS.Timeout | undefined
  >(undefined);
  const regex = /(?:[^`!@#$%^&*\-_=+'/.,]*[`!@#$%^&*\-_=+'/.,]){2}/;

  const customer: CustomerInfoType = useReduxSelector(getCustomer);
  const LinkedAccountString = useReduxSelector(getLinkedAccountString);

  useEffect(() => {
    if (newPassword.match(regex) && newPassword.length > 7) {
      setIsPasswordWeak(false);
      if (newPassword === repeatedPassword) {
        setIsPasswordValid(true);
      } else {
        setIsPasswordValid(false);
      }
    } else {
      setIsPasswordWeak(true);
      setIsPasswordValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword, repeatedPassword]);

  const handleCancel = () => {
    setNewPassword('');
    setRepeatedPassword('');
    setIsPasswordChanging(!isPasswordChanging);
  };

  const handleSubmit = () => {
    setOldPassword(newPassword);
    setIsNewPasswordVisible(false);
    setIsRepeatedPasswordVisible(false);
    setIsPasswordChanging(false);
  };

  const clearChangePasswordInterval = useCallback(() => {
    if (changePasswordInterval) {
      clearInterval(changePasswordInterval);
    }
  }, [changePasswordInterval]);

  const handleMessage = useCallback(
    (event) => {
      if (event.origin !== getSsoSignupUrl()) return;
      if (event.data === 'Received') {
        clearChangePasswordInterval();
      }
    },
    [clearChangePasswordInterval],
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  const handleChangeZebraSsoPassword = useCallback(() => {
    const ssoPasswordPage = window.open(SSO_CHANGE_PASSWORD_URL);

    setEmailPostMessageAttempts(0);
    clearChangePasswordInterval();

    setChangePasswordInterval(
      setInterval(() => {
        ssoPasswordPage?.postMessage(customer?.email, SSO_CHANGE_PASSWORD_URL);
        setEmailPostMessageAttempts((attempts) => attempts + 1);
      }, 1000),
    );
  }, [clearChangePasswordInterval, customer]);

  useEffect(() => {
    return () => {
      clearChangePasswordInterval();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (emailPostMessageAttempts >= MAXIMUM_EMAIL_POST_MESSAGE_ATTEMPTS) {
      clearChangePasswordInterval();
    }
  }, [emailPostMessageAttempts, clearChangePasswordInterval]);

  const handleChangeLinkedAccountPassword = useCallback(() => {
    dispatch(
      openBasicToast(
        t('settings:password.linked-to-external-account', {
          LinkedAccountString,
        }),
      ),
    );
  }, [t, dispatch, LinkedAccountString]);

  return (
    <Flex flexDirection={'column'} justifyContent={'flex-start'} mb={12}>
      {isPasswordChanging ? (
        <Flex flexDirection={'column'}>
          <Form onSubmit={handleSubmit}>
            <Box mb={8}>
              <Input
                type={isNewPasswordVisible ? 'text' : 'password'}
                label={t('settings:password.label')}
                placeholder={t('settings:password.enter-new')}
                value={newPassword}
                onChange={(e: any) => setNewPassword(e.target.value)}
                iconEnd={
                  isNewPasswordVisible ? (
                    <Icon icon={'view'} color={theme.black} />
                  ) : (
                    <Icon icon={'hide'} color={theme.black} />
                  )
                }
                onEndIconClick={(e) => {
                  e.preventDefault();
                  setIsNewPasswordVisible(!isNewPasswordVisible);
                }}
              ></Input>
            </Box>
            <Box mb={11}>
              <Input
                type={isRepeatedPasswordVisible ? 'text' : 'password'}
                placeholder={t('settings:password.confirm-new')}
                value={repeatedPassword}
                onChange={(e: any) => {
                  setRepeatedPassword(e.target.value);
                }}
                iconEnd={
                  isRepeatedPasswordVisible ? (
                    <Icon icon={'view'} color={theme.black} />
                  ) : (
                    <Icon icon={'hide'} color={theme.black} />
                  )
                }
                onEndIconClick={(e) => {
                  e.preventDefault();
                  setIsRepeatedPasswordVisible(!isRepeatedPasswordVisible);
                }}
              ></Input>
            </Box>
            <Box mb={6}>
              <Text display={'inline-block'} mr={5}>
                {`${t('settings:password.strength')}:`}
              </Text>
              {isPasswordWeak ? (
                <Text display={'inline-block'} color={theme.error.base}>
                  {t('settings:password.weak')}
                </Text>
              ) : (
                <Text display={'inline-block'} color={theme.success.base}>
                  {t('settings:password.strong')}
                </Text>
              )}
            </Box>
            <Box mb={10}>
              <Text
                fontSize={14}
                lineHeight={'21px'}
                letterSpacing={0.4}
                color={theme.neutral.base}
              >
                {`• ${t('settings:password.characters.min')}`}
              </Text>
              <Text
                fontSize={14}
                lineHeight={'21px'}
                letterSpacing={0.4}
                color={theme.neutral.base}
              >
                {`• ${t('settings:password.characters-include')}`}
              </Text>
              <Text
                fontSize={14}
                lineHeight={'21px'}
                letterSpacing={0.4}
                color={theme.neutral.base}
              >
                {`• ${t('settings:password.can-contain-numbers')}`}
              </Text>
            </Box>
            <Flex>
              <Button
                variant={'primary'}
                disabled={!isPasswordValid}
                type={'submit'}
              >
                {t('common:save')}
              </Button>
              <Button variant={'text-primary'} onClick={handleCancel}>
                {t('common:cancel')}
              </Button>
            </Flex>
          </Form>
        </Flex>
      ) : (
        <Flex flexDirection={'column'}>
          <Box mb={5}>
            <Input
              type={'password'}
              label={t('settings:password.label')}
              value={oldPassword}
              disabled
            ></Input>
          </Box>
          <ChangeButton
            data-testid="change-password-button"
            onClick={
              LinkedAccountString === null
                ? handleChangeZebraSsoPassword
                : handleChangeLinkedAccountPassword
            }
            as={'button'}
          >
            <Text>{t('settings:password.change')}</Text>
          </ChangeButton>
        </Flex>
      )}
    </Flex>
  );
};
