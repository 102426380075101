import React, { useContext } from 'react';
import { Flex, Heading3Text } from 'components';
import {
  CartridgesWrap,
  LabelTitle,
  PrinterSerialNumber,
  TemplateSizePreview,
  Wrap,
} from './LabelSizeCard.styles';
import { ThemeContext } from 'styled-components';
import { getEtch } from 'utils/unitFormatter';
import { useTranslation } from 'react-i18next';
import { LabelCartridge } from '../CategoryCard/LabelCartridge';
import { MM_IN_ONE_INCH } from 'utils/config.json';

const LabelSizeCard = ({
  label,
  selected = false,
  unit,
  showSerialNumber = false,
  ...props
}: any) => {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation(['templates']);

  let SKU: any = {};
  if (label.naPartNumber !== '') {
    SKU.naPartNumber = label.naPartNumber;
  }
  if (label.emeaPartNumber !== '') {
    SKU.emeaPartNumber = label.emeaPartNumber;
  }
  const whiteMedEmphasis = theme.withOpacity(theme.white, 0.7);

  return (
    <Wrap {...props} selected={selected} title={label?.name}>
      <LabelTitle
        data-testid="label-title"
        color={selected ? whiteMedEmphasis : undefined}
        textAlign={showSerialNumber ? 'center' : ''}
      >
        {label?.printerName ? label?.printerName : label?.name}
      </LabelTitle>
      {showSerialNumber && (
        <PrinterSerialNumber
          data-testid="printer-serial-number"
          color={selected ? whiteMedEmphasis : undefined}
        >{`${t('templates:create-new-label.serial-number')}: ${
          label?.printerId
        }`}</PrinterSerialNumber>
      )}
      <Flex pt={[4]} pb={[4]} height={'100%'} alignItems={'center'}>
        <TemplateSizePreview
          selected={selected}
          labelCornerRadius={label?.internalCornerradius1000sofmm}
          labelWidth={label?.displayWidth * 1000 * MM_IN_ONE_INCH}
          labelHeight={label?.displayHeight * 1000 * MM_IN_ONE_INCH}
        />
      </Flex>

      <Heading3Text
        data-testid="label-size-card-dimensions"
        color={selected ? theme.white : undefined}
        mt={'auto'}
      >
        {getEtch(
          {
            width: label?.displayWidth * 1000 * MM_IN_ONE_INCH,
            height: label?.displayHeight * 1000 * MM_IN_ONE_INCH,
          },
          unit,
        )}
      </Heading3Text>

      <CartridgesWrap
        color={selected ? whiteMedEmphasis : undefined}
        justifyContent="center"
        flexDirection="column"
        mt="5px"
      >
        <LabelCartridge
          naPartNumber={label.naPartNumber}
          emeaPartNumber={label.emeaPartNumber}
        />
      </CartridgesWrap>
    </Wrap>
  );
};

export default LabelSizeCard;
