import React, { SyntheticEvent, useState } from 'react';
import { Icon, Card } from 'components';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';

import { useOutsideClick } from 'hooks';

interface OptionsMenuProps {
  onClick?: (event: SyntheticEvent) => void;
  children?: any;
  dataTestid?: string;
  iconLight?: boolean;
  positionFixed?: boolean;
}

const StyledMenuWrap = styled.div<any>`
  position: relative;
  top: 0;
  right: 0;
`;

const StyledIcon = styled.a`
  padding: 2px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  svg path {
    transition: fill 0.3s;
  }

  &:hover {
    background-color: ${({ theme }) => theme.primary.lightest};

    svg path {
      fill: ${({ theme }) => theme.primary.base} !important;
    }
  }
`;

const StyledList = styled(Card)<any>`
  position: ${({ positionFixed }) =>
    positionFixed === true ? 'fixed' : 'absolute'};
  top: ${({ positionFixed }) => (positionFixed === true ? '112px' : '24px')};
  right: ${({ positionFixed }) => (positionFixed === true ? '10px' : '0px')};
  z-index: 100;
  min-width: 165px;
  background-color: ${({ theme }) => theme.white};
`;

const OptionsMenu = React.forwardRef(
  (
    { children, positionFixed, onClick, ...props }: OptionsMenuProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const theme = React.useContext(ThemeContext);
    const [isVisible, setIsVisible] = useState(false);
    const showMenu = () => {
      setIsVisible(!isVisible);
    };
    useOutsideClick(ref, () => {
      setIsVisible(false);
    });

    return (
      <StyledMenuWrap positionFixed={positionFixed} ref={ref}>
        <StyledIcon {...props} onClick={showMenu}>
          <Icon
            color={props.iconLight ? 'white' : theme.neutral.base}
            size={25}
            icon="more"
          />
        </StyledIcon>
        {isVisible ? (
          <StyledList
            flexDirection="column"
            alignItems="flex-start"
            p={0}
            pt={4}
            pb={4}
            elevation="four"
            positionFixed={positionFixed}
          >
            {children}
          </StyledList>
        ) : (
          <></>
        )}
      </StyledMenuWrap>
    );
  },
);

export default OptionsMenu;
