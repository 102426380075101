import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useUrlParam, useReduxAction } from 'hooks';
import { useHistory } from 'react-router-dom';
import { Flex, Box } from 'components';
import { PageTitle } from 'v2/components/common';
import { StickyHeader } from 'v2/components/app/StickyHeader/StickyHeader';
import { push } from 'connected-react-router';
import { HOME_PAGE_PATH, MY_DESIGNS_PATH } from 'utils/config';

import CategoriesList from './CategoriesList';
import SearchResult from './SearchResult';
import FilterCategoriesLayout from './FilterCategoriesLayout';

const StyledFlex = styled(Flex).attrs({
  flexDirection: 'column',
  justifyContent: 'start',
  className: 'library-content',
})`
  width: 100%;
`;

export default function CategoriesLayout() {
  const { t } = useTranslation();

  const [searchString] = useUrlParam('searchLibrary', '');
  const history = useHistory();
  const pushAction = useReduxAction(push);

  const handleOnBackClick = (): void => {
    const state = history.location ? history.location.state as { from: string } : undefined;
    const backToMyDesigns =
      state !== undefined &&
      state.from !== undefined &&
      state.from === MY_DESIGNS_PATH;

    if (backToMyDesigns) {
      pushAction(MY_DESIGNS_PATH);
    } else {
      pushAction(HOME_PAGE_PATH);
    }
  };

  return (
    <>
      <StickyHeader>
        <Box>
          <PageTitle
            title={t('menu:explore-templates')}
            onBackClick={handleOnBackClick}
          />
          <FilterCategoriesLayout />
        </Box>
      </StickyHeader>

      <Flex justifyContent={'center'}>
        <StyledFlex>
          {searchString ? <SearchResult /> : <CategoriesList />}
        </StyledFlex>
      </Flex>
    </>
  );
}
